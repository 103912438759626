import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { openModal } from '../../store/actions';
import { AbsoluteNavigate } from '../AbsoluteNavigate';
import Spinner from '../UI/Spinner';

export function Migration(): JSX.Element | null {
  const { token } = useParams<{ token: string }>();
  const isAuthenticated = useSelector((state: any) => state.auth.token !== null);
  const [, setCookie] = useCookies(['luckydays_uid']);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!token) {
      return;
    }

    // All the modals have a default order of 1; placing order 2 here will put it on top of the stack
    dispatch(openModal('ontarioWelcome', {}, 2));
    setCookie('luckydays_uid', token, { path: '/' });
  }, [token]);

  return isAuthenticated ? (
    <AbsoluteNavigate to="/" />
  ) : (
    <div>
      <Spinner absolute height={40} width={40} dark />
    </div>
  );
}
