import { useDispatch, useSelector } from 'react-redux';
import { isRegulatedLocale } from '../shared/utility';
import { closeModal, openModal } from '../store/actions';
import { useAbsoluteNavigate } from './useAbsoluteNavigate';
import { useLocation } from 'react-router-dom';
import { isFastReg } from '../utils/utils';
import { envIsMexico } from '../constants/constants';

export function useRegistration() {
  const locale = useSelector(state => state.common.locale);
  const dispatch = useDispatch();
  const absoluteNavigate = useAbsoluteNavigate();
  const { pathname } = useLocation();

  const openRegistration = () => {
    if (envIsMexico()) {
      dispatch(closeModal());
      absoluteNavigate('/');
      window.scrollTo({ top: 0, behavior: 'smooth' });
      return;
    }

    if (isRegulatedLocale(locale)) {
      dispatch(openModal('paynplay'));
      return;
    }

    if (isFastReg(locale)) {
      // Check if the user is currently on the homepage (scroll to the FastReg form) or
      // on any other page (redirect to the homepage)
      if (pathname === `/${locale}`) {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      } else {
        absoluteNavigate('/');
      }
      // Close any opened modal - e.g. login modal
      dispatch(closeModal());

      return;
    }

    // Ontario registration
    absoluteNavigate('/register');
  };

  return { openRegistration };
}
