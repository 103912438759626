import React, { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import PageHeader from '@/components/Layout/PageHeader';
import { useIntl } from 'react-intl';
import PaymentMethodButton from '@/pages/payment-providers/PaymentMethodButton';

import { envIsMexico } from '@/constants/constants';

const PaymentMethodsSpecific = lazy(() => import('./PaymentMethodsSpecific'));

const PaymentMethods = () => {
  const intl = useIntl();
  const [isDeposit, setIsDeposit] = React.useState(true);

  return (
    <>
      <Helmet>
        <title>LuckyDays - {intl.formatMessage({ id: 'paymentMethods.header' })}</title>
      </Helmet>
      <PageHeader>
        <h1>{intl.formatMessage({ id: 'paymentMethods.header' })}</h1>
        <div className="flex justify-center py-6">
          <PaymentMethodButton
            body={intl.formatMessage({ id: 'paymentMethods.deposit' })}
            onClick={() => setIsDeposit(true)}
            isActive={isDeposit}
            isLeft
          />
          <PaymentMethodButton
            body={intl.formatMessage({ id: 'paymentMethods.withdrawal' })}
            onClick={() => setIsDeposit(false)}
            isActive={!isDeposit}
          />
        </div>
      </PageHeader>
      <Suspense fallback={<div />}>
        {envIsMexico() && <PaymentMethodsSpecific isDeposit={isDeposit} locale="mx" />}
      </Suspense>
    </>
  );
};

export default PaymentMethods;
