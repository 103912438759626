import React, { useEffect } from 'react';

import ReactCountryFlag from 'react-country-flag';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import { Form, SearchDropdownField } from '../../components/form';
import { Button } from '@/components/buttons/Button';
import {
  useRegistrationActions,
  useWorkInfoData,
  WorkRegistrationInfo,
} from '@/store/registrationStore';
import { useIndustriesStore, useOccupationsStore } from '@/store/workListsStore';
import { useCountriesStore } from '@/store/countriesStore';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';

const WorkInfoForm = ({
  stepBack,
  stepForward,
}: {
  stepBack: () => void;
  stepForward: () => void;
}) => {
  const { fetch: fetchIndustries, industries } = useIndustriesStore();
  const { fetch: fetchOccupations, occupations } = useOccupationsStore();
  const { fetch: fetchCountries, countries, error: countriesError } = useCountriesStore();
  const { citizenshipId, industryId, occupationId } = useWorkInfoData();
  const { updateField, updateFields } = useRegistrationActions();
  const intl = useIntl();

  const initialValues: WorkRegistrationInfo = {
    citizenshipId,
    industryId,
    occupationId,
  };

  const validationSchema = Yup.object().shape({
    citizenshipId: Yup.string().required(intl.formatMessage({ id: 'input.error.required' })),
    industryId: Yup.string().required(intl.formatMessage({ id: 'input.error.required' })),
    occupationId: Yup.string().required(intl.formatMessage({ id: 'input.error.required' })),
  });

  const mappedCountries = Object.entries(countries).map(([alpha2Code, { name }]) => ({
    value: alpha2Code,
    label: name,
    endIcon: <ReactCountryFlag countryCode={alpha2Code} />,
  }));

  const mappedIndustries = industries.map(industry => ({
    value: industry.IndustryId.toString(),
    label: industry.Name,
  }));

  const mappedOccupations = occupations.map(occupation => ({
    value: occupation.OccupationId.toString(),
    label: occupation.Name,
  }));

  // This is a dirty hack to display the SearchDropdownField. It is required
  // because the displayFunction on Combobox.Input does not get re-invoked
  // on component re-rerender for some reason, which happens when options prop change,
  // thus the initial citizenship value will never be displayed, because on initial
  // mount options array was empty and the displayFunction is not re-invoked
  const isCitizenshipShown = mappedCountries.length > 0 || countriesError;

  const handleOnSubmit = (values: WorkRegistrationInfo) => {
    triggerGTag(GTagEvents.ont_reg_step3_continue_cta_click);
    updateFields(values);
    stepForward();
  };

  useEffect(() => {
    fetchIndustries();
    fetchOccupations();
    fetchCountries();
  }, []);

  return (
    <div className="w-full h-full pt-5">
      <Form
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
        className="flex flex-col justify-between gap-8 h-full"
      >
        {() => (
          <>
            <div className="flex flex-col gap-7">
              {isCitizenshipShown && (
                <SearchDropdownField
                  name="citizenshipId"
                  label={intl.formatMessage({ id: 'inputs.citizenship' })}
                  options={mappedCountries}
                  onChange={newValue => updateField('citizenshipId', newValue)}
                  onValidationError={error =>
                    triggerGTag(GTagEvents.ont_reg_citizenship_error, { error })
                  }
                  onFocus={() => triggerGTag(GTagEvents.ont_reg_citizenship_click)}
                  inputClassName="!bg-transparent border-[1px] border-white/30 text-white focus-within:border-blue-blue"
                  labelClassName="text-white"
                />
              )}
              <SearchDropdownField
                name="industryId"
                label={intl.formatMessage({ id: 'inputs.industry' })}
                options={mappedIndustries}
                onChange={newValue => updateField('industryId', newValue)}
                onValidationError={error =>
                  triggerGTag(GTagEvents.ont_reg_industry_error, { error })
                }
                onFocus={() => triggerGTag(GTagEvents.ont_reg_industry_click)}
                inputClassName="!bg-transparent border-[1px] border-white/30 text-white focus-within:border-blue-blue"
                labelClassName="text-white"
              />
              <SearchDropdownField
                name="occupationId"
                label={intl.formatMessage({ id: 'inputs.occupation' })}
                options={mappedOccupations}
                onChange={newValue => updateField('occupationId', newValue)}
                onValidationError={error =>
                  triggerGTag(GTagEvents.ont_reg_occupation_error, { error })
                }
                onFocus={() => triggerGTag(GTagEvents.ont_reg_occupation_click)}
                inputClassName="!bg-transparent border-[1px] border-white/30 text-white focus-within:border-blue-blue"
                labelClassName="text-white"
              />
            </div>
            <div className="flex flex-col-reverse items-center gap-4 mt-4 sm:flex-row">
              <Button
                text={intl.formatMessage({ id: 'misc.back' })}
                onClickHandler={() => {
                  triggerGTag(GTagEvents.ont_reg_step3_back_cta_click);
                  stepBack();
                }}
                btnType="secondary"
              />
              <Button
                text={intl.formatMessage({ id: 'misc.continue' })}
                type="submit"
                btnType="primary"
              />
            </div>
          </>
        )}
      </Form>
    </div>
  );
};

export default WorkInfoForm;
