import { useState } from 'react';
import { ApiV2Error, apiV2ErrorHandler } from '../shared/errorHandler';
import { ApiServiceI } from '../shared/apiService';
import { useCancelToken } from './useCancelToken';

type ApiV2Request<T> = <M extends ApiServiceI[keyof ApiServiceI]>(
  apiMethod: M,
  payload: Parameters<M>[0],
  successCallback?: (data: T) => void,
  errorCallback?: (error: ApiV2Error, fullError: unknown) => void,
) => void;

interface Return<T> {
  loading: boolean;
  success: boolean;
  error: ApiV2Error | null;
  data: T | null;
  request: ApiV2Request<T>;
}

export function useApiV2Request<T>(): Return<T> {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<ApiV2Error | null>(null);
  const { newCancelToken } = useCancelToken();

  const request: ApiV2Request<T> = (apiMethod, payload, successCallback?, errorCallback?): void => {
    setLoading(true);
    setSuccess(false);
    setError(null);
    // @ts-ignore
    apiMethod(payload, {
      cancelToken: newCancelToken(),
    })
      .then(response => {
        setLoading(false);
        setSuccess(true);
        setData(response.data);
        if (successCallback) {
          successCallback(response.data);
        }
      })
      .catch(error => {
        setLoading(false);
        setSuccess(false);
        const handledError = apiV2ErrorHandler(error);
        setError(handledError);
        if (errorCallback) {
          errorCallback(handledError, error);
        }
      });
  };

  return { loading, success, error, data, request };
}
