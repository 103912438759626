import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { mapLocaleToLang } from '../utils/utils';
import { logException } from '../utils/logger';
import { mapLocaleToFile } from '../utils/mappers';
import { CDN_BASE, CDN_IMAGES_COMMON_PATH } from '../constants/constants';
import { ButtonWithLoader } from './Common/ButtonWithLoader';

const loadJson = (locale: string): Promise<any> => {
  return new Promise((res, rej) => {
    fetch(`${process.env.PUBLIC_URL}/translations/${mapLocaleToFile(locale)}.json`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(data => {
        const contentType = data.headers.get('content-type');
        if (!contentType || !contentType.includes('application/json')) {
          throw new TypeError('Translation file was not in JSON format!');
        }
        return data.json();
      })
      .then(jsonData => res(jsonData))
      .catch(e => rej(e));
  });
};

type TranslationMessages = Record<string, string>;
type Translations = Record<string, TranslationMessages>;

const loadErrorText = (locale: string): string => {
  switch (locale) {
    case 'at':
      return 'Beim Laden des Kasinos ist ein Fehler aufgetreten. Keine Sorge, klicke unten, um es erneut zu laden.';
    case 'fi':
      return 'Jotain meni pieleen Kasinoa ladattaessa. Ei hätää, napsauta alta ladataksesi uudelleen.';
    case 'ca-fr':
      return "Une erreur s'est produite lors du chargement du Casino. Pas de soucis, cliquez ci-dessous pour charger à nouveau";
    case 'no':
      return 'Noe gikk galt mens kasinoet lastet inn. Ingen grunn til bekymring, klikk nedenfor for å laste inn igjen.';
    case 'sv':
      return 'Någonting gick fel med att ladda Casinot, men inga problem. Klicka på knappen här under för att försöka igen:';
    case 'th':
      return 'มีข้อผิดพลาดเกิดขึ้นในการโหลดหน้าคาสิโน กรุณากดโหลดอีกครั้งข้างล่างนี้';
    default:
      return 'Something went wrong loading the Casino. No worries, click below to load again.';
  }
};

const loadErrorBtnText = (locale: string): string => {
  switch (locale) {
    case 'at':
      return 'Lade das Kasino';
    case 'fi':
      return 'Lataa Kasino';
    case 'ca-fr':
      return 'Charger le casino';
    case 'no':
      return 'Laster inn kasino';
    case 'sv':
      return 'Ladda Casinot';
    case 'th':
      return 'โหลดคาสิโน';
    default:
      return 'Load Casino';
  }
};

export function Internationalisation({
  children,
  locale,
}: {
  children: React.ReactNode;
  locale: string;
}): JSX.Element | null {
  const [translations, setTranslations] = useState<Translations | null>(null);
  const [loadError, setLoadError] = useState(false);
  const [loadInProgress, setLoadInProgress] = useState(false);

  useEffect(() => {
    // Remove loader from DOM
    const loading = document.getElementById('loading');
    if (loading !== null) {
      loading.outerHTML = '';
    }
  }, []);

  const loadJsonAsync = async (locale: string) => {
    setLoadInProgress(true);
    try {
      const messages = await loadJson(locale);
      setLoadError(false);
      setTranslations(prevState => {
        if (!prevState) {
          return {
            [locale]: messages,
          };
        }
        return {
          ...prevState,
          [locale]: messages,
        };
      });
      setLoadInProgress(false);
    } catch (e) {
      setLoadInProgress(false);
      setLoadError(true);
      logException(new Error('Load Translations Error'), { error: e, locale });
    }
  };

  useEffect(() => {
    if (!locale) {
      return;
    }

    loadJsonAsync(locale);
  }, [locale]);

  if (!locale || !translations || !translations[locale]) {
    if (loadError) {
      return (
        <div className="mx-auto flex h-screen max-w-sm flex-col items-center justify-center gap-8 px-4">
          <img
            className="ml-[16px]"
            width="160"
            style={{
              filter:
                'invert(99%) sepia(100%) saturate(0%) hue-rotate(304deg) brightness(105%) contrast(101%)',
            }}
            src={`${CDN_BASE}${CDN_IMAGES_COMMON_PATH}/logo.svg`}
            alt="LuckyDays"
          />
          <img
            width="160"
            src={`${CDN_BASE}/cdn-cgi/image/fit=contain,width=160,format=auto,dpr=2${CDN_IMAGES_COMMON_PATH}/sitting-cat.png`}
            alt="Lucky Cat Sitting"
          />
          <div className="text-center text-lg">{loadErrorText(locale)}</div>
          <ButtonWithLoader
            onClick={() => loadJsonAsync(locale)}
            loading={loadInProgress}
            message={loadErrorBtnText(locale)}
          />
        </div>
      );
    }
    return null;
  }

  return (
    <IntlProvider locale={mapLocaleToLang(locale)} messages={translations[locale]}>
      {children}
    </IntlProvider>
  );
}
