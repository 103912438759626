import React from 'react';

import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Limit, PlayerLimits, TimeOut } from './limits';
import { useSearchParams } from 'react-router-dom';
import ResponsibleGamingIntro from './ResponsibleGamingIntro';
import { AccountClosure, AccountSelfExclusion } from './account';
import { isRegulatedLocale } from '../../../shared/utility';
import { RootState } from '../../../models/root-state.model';
import { envIsCanada, envIsMexico, envIsNormal, envIsSweden } from '../../../constants/constants';

const StyledResponsibleGamingTab = styled.div`
  min-height: 60vh;
  background: ${props => props.theme.colors.offWhite};
  padding: 48px 16px;
  max-width: 720px;
  margin: 0 auto;

  @media (min-width: ${props => props.theme.breakpoints.small}) {
    padding: 64px 32px;
  }

  .take-a-break {
    margin-top: 48px;
    padding-top: 48px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);

    h2 {
      margin-top: 0;
    }

    p {
      margin-bottom: 1rem;
    }
  }

  .timeout {
    margin-top: 48px;

    .select {
      width: 100%;
    }
  }

  .buttons {
    padding: 16px 0 0;
    text-align: right;

    button,
    a {
      display: inline-block;
      margin: 16px 0 0 16px;
    }

    p {
      font-weight: bold;
      margin: 20px 0 0;
    }
  }

  h2 {
    font-size: 24px;
  }

  .current-limit {
    color: ${props => props.theme.colors.yellow70};
    display: block;
    margin-bottom: 0;
    border-radius: 8px;
    font-size: 15px;

    > span {
      margin: 0 4px;

      &:after {
        content: ',';
      }

      &:last-child:after {
        content: '';
      }
    }
  }

  .form {
    display: flex;
    flex-flow: column;
    margin: 32px 0;

    form {
      display: flex;
      flex-flow: column;
      width: 100%;
      max-width: 400px;
      margin: 0 auto;
      align-items: center;

      label {
        display: flex;
        flex-flow: column;
        width: 100%;
        color: ${props => props.theme.colors.blue2};
        font-weight: bold;
        font-size: 14px;

        input {
          margin-top: 8px;
          padding: 1rem;
          border-radius: 8px;
          border: none;
          border-bottom: 2px solid rgba(0, 0, 0, 0.1);

          &:focus {
            border-bottom: 2px solid rgba(0, 0, 0, 0.1);
            box-shadow: none;
          }
        }
      }
    }
  }

  .button-wrapper {
    display: flex;
    justify-content: center;
  }

  .player-limits {
    a {
      text-decoration: underline;
    }
  }
`;

const ResponsibleGamingTab = (): JSX.Element => {
  const locale = useSelector((state: RootState) => state.common.locale);
  const isAuthenticated = useSelector((state: RootState) => state.auth.token !== null);
  const intl = useIntl();
  let [searchParams] = useSearchParams();
  const limit = searchParams.get('limit');

  const showAccountClosure = isAuthenticated && envIsCanada();

  const showTakeABreak =
    isAuthenticated && !isRegulatedLocale(locale) && (envIsNormal() || envIsMexico());

  if (limit === 'deposit') {
    return (
      <StyledResponsibleGamingTab>
        <PlayerLimits showLimitsInitially={true} initialLimit={Limit.Deposit} />
      </StyledResponsibleGamingTab>
    );
  }

  if (limit === 'timeout') {
    return (
      <StyledResponsibleGamingTab>
        <PlayerLimits showLimitsInitially={true} initialLimit={Limit.TimeOut} />
      </StyledResponsibleGamingTab>
    );
  }

  if (limit === '24h') {
    return (
      <StyledResponsibleGamingTab>
        <PlayerLimits showLimitsInitially={true} initialLimit={Limit.Panic} />
      </StyledResponsibleGamingTab>
    );
  }

  return (
    <StyledResponsibleGamingTab>
      <ResponsibleGamingIntro locale={locale} />
      {isAuthenticated && (
        <div className="border-t pt-8">
          <PlayerLimits />
        </div>
      )}
      {envIsSweden() && isAuthenticated && (
        <div className="mt-8 border-t">
          <AccountSelfExclusion />
        </div>
      )}
      {showAccountClosure && (
        <div className="mt-8 border-t pt-8">
          <h2 className="h1">{intl.formatMessage({ id: 'accountClosure.header' })}</h2>
          <p className="intro mt-4">{intl.formatMessage({ id: 'accountClosure.text' })}</p>
          <AccountClosure />
        </div>
      )}
      {showTakeABreak && (
        <div className="take-a-break mt-8 border-t pt-8">
          <TimeOut />
        </div>
      )}
    </StyledResponsibleGamingTab>
  );
};

export default ResponsibleGamingTab;
