import { AutoLoginStep } from '../../models/enum/auto-login-step.enum';
import {
  AutoLoginActions,
  AutoLoginActionType,
  AutoLoginUpdateAction,
} from '../actions/autoLoginActions';

export interface AutoLoginState {
  step: AutoLoginStep;
}

const initialState = {
  step: AutoLoginStep.Idle,
};

const autoLogin = (state: AutoLoginState): AutoLoginState => ({
  ...state,
  step: AutoLoginStep.Checking,
});

const autoLoginUpdate = (state: AutoLoginState, action: AutoLoginUpdateAction) => ({
  ...state,
  step: action.step,
});

export const autoLoginReducer = (state = initialState, action: AutoLoginActions) => {
  switch (action.type) {
    case AutoLoginActionType.AutoLogin:
      return autoLogin(state);
    case AutoLoginActionType.AutoLoginUpdate:
      return autoLoginUpdate(state, action);
    default:
      return state;
  }
};
