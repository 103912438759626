import React, { useEffect } from 'react';

import { ChatBubbleLeftRightIcon } from '@heroicons/react/24/outline';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import PageHeader from '../../Layout/PageHeader';
import Button from '../../UI/Buttons/Button';
import FAQ from './FAQ';
import { LineChatIcon } from '../../UI/Icons/LineChatIcon';
import { openZendesk } from '../../Zendesk';
import { loadZendesk } from '@/store/actions/commonActions';
import { openModal } from '@/store/actions';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';

function Help() {
  const user = useSelector(state => state.user.user);
  const locale = useSelector(state => state.common.locale);
  const isAuthenticated = useSelector(state => state.auth.token !== null);
  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadZendesk());
  }, []);

  const handleOpenLineChat = () => {
    dispatch(openModal('lineChat'));
  };

  return (
    <>
      <Helmet>
        <title>LuckyDays - {intl.formatMessage({ id: 'help.header' })}</title>
      </Helmet>
      <PageHeader>
        <h1>{intl.formatMessage({ id: 'help.header' })}</h1>
        <p>{intl.formatMessage({ id: 'help.subHeader' })}</p>
        <div className="flex items-center justify-center gap-4 [&>button]:!mx-0">
          <Button
            light
            iconleft
            onClick={() => {
              openZendesk(user, isAuthenticated);
              triggerGTag(GTagEvents.open_support);
            }}
          >
            <ChatBubbleLeftRightIcon /> {intl.formatMessage({ id: 'help.contactButton' })}
          </Button>
          {locale === 'th' && (
            <div className="[&>button]:h-[56px] [&>button]:py-0">
              <Button light iconleft onClick={handleOpenLineChat}>
                <LineChatIcon /> {intl.formatMessage({ id: 'help.lineChatButton' })}
              </Button>
            </div>
          )}
        </div>
      </PageHeader>
      <FAQ />
    </>
  );
}

export default Help;
