import React from 'react';
import classNames from 'classnames';
import { LinearGradient } from '../gradient';

interface GradientBannerProps {
  heading: string;
  buttonOnClick: () => void;
  buttonText: string;
  imageUrl: string;
}

const GradientBanner = ({ buttonOnClick, buttonText, heading, imageUrl }: GradientBannerProps) => (
  <div className="flex flex-col md:flex-row w-full md:min-h-[350px] h-full relative">
    <div className="relative flex w-full md:w-[65%]">
      <LinearGradient
        className="hidden md:block"
        from="rgba(255, 162, 10, 0.6)"
        to="rgba(254, 118, 200)"
        direction="right"
        transitionPoint="46%"
      />
      <LinearGradient
        className="md:hidden"
        from="rgba(255, 162, 10, 0.6)"
        to="rgba(254, 118, 200)"
        direction="bottom"
        transitionPoint="46%"
      />
      <div className="w-full h-full flex flex-col space-y-[24px] py-[16px] items-start md:items-center px-[12px] justify-center relative md:p-0">
        <h3 className="m-0 max-w-[550px] text-white">{heading}</h3>
        <button
          onClick={buttonOnClick}
          className={classNames(
            'px-3 py-1 bg-black/40 rounded-lg border-[1px] border-gray-600, text-white font-bold',
            'ease-in-out transition-all duration-200 active:scale-[98%] hover:scale-[102%]',
          )}
          type="button"
        >
          {buttonText}
        </button>
      </div>
      <div className="hidden md:block absolute w-[50%] h-full -right-[49.9%]">
        <div className="bg-[rgba(254,118,200)] w-full h-full [clip-path:polygon(0_0,_0%_100%,_9%_0)]"></div>
      </div>
      <div className="md:hidden absolute w-full h-[50%] -bottom-[49.9%]">
        <div className="bg-[rgba(254,118,200)] w-full h-full [clip-path:polygon(0_0,_0%_14%,_100%_0)]"></div>
      </div>
    </div>
    <div className="flex md:w-[35%]">
      <img
        className="w-full h-auto max-h-[100px] md:max-h-full md:h-full object-cover"
        alt="banner_background"
        src={imageUrl}
      />
    </div>
  </div>
);

export default GradientBanner;
