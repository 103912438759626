import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useIsVisible } from '@/hooks/useIsVisible';
import { DesktopCTAButton, MobileCTAButton, ScrollButton } from './StreamlineControls';
import classNames from 'classnames';

interface StreamlineProps {
  autoplay?: boolean;
  buttonOnClick?: () => void;
  buttonText?: string;
  children: ReactElement[] | ReactElement;
  headerText?: string;
  loop?: boolean;
  padding?: number;
  spacing?: 0 | 16 | 32 | 48;
  stretch?: boolean;
}

const Streamline = ({
  autoplay = false,
  buttonOnClick,
  buttonText,
  children,
  headerText,
  loop = false,
  padding = 16,
  spacing = 16,
  stretch = false,
}: StreamlineProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const scrollableContainerRef = useRef<HTMLDivElement | null>(null);
  // @ts-ignore
  const isContainerVisible = useIsVisible(containerRef);
  const [showLeftScroll, setShowLeftScroll] = useState(false);
  const [showRightScroll, setShowRightScroll] = useState(true);
  const [intervalId, setIntervalId] = useState<NodeJS.Timer | undefined>(undefined);
  const [justifiement, setJustifiement] = useState('center');

  useEffect(() => {
    if (autoplay && intervalId === undefined) {
      const newIntervalId = setInterval(() => handleScrollClick('right'), 5000);
      setIntervalId(newIntervalId);
      return () => clearInterval(intervalId);
    }
  }, [autoplay, intervalId]);

  const handleMobileTouch = useCallback(
    (closingIntervalId?: NodeJS.Timer) => {
      if (autoplay && closingIntervalId !== undefined) {
        clearInterval(closingIntervalId);
        setIntervalId(undefined);
      }
    },
    [setIntervalId, autoplay],
  );

  const handleScrollClick = useCallback(
    (direction: 'left' | 'right', closingIntervalId?: NodeJS.Timer) => {
      const left = direction === 'left';
      const scrollWidth = scrollableContainerRef.current?.scrollWidth ?? 0;
      const clientWidth = containerRef.current?.clientWidth ?? 0;
      const scrollLeft = scrollableContainerRef.current?.scrollLeft ?? 0;
      const scrollDistanceBase = clientWidth * 0.92;

      if (autoplay && closingIntervalId !== undefined) {
        clearInterval(closingIntervalId);
        setIntervalId(undefined);
      }

      if (loop && left && scrollLeft <= 5) {
        scrollableContainerRef.current?.scrollBy(scrollWidth, 0);
        return;
      }
      if (loop && !left && scrollLeft >= scrollWidth - clientWidth) {
        scrollableContainerRef.current?.scrollBy(-scrollWidth, 0);
        return;
      }

      scrollableContainerRef.current?.scrollBy(left ? -scrollDistanceBase : scrollDistanceBase, 0);
    },
    [containerRef, scrollableContainerRef, loop, autoplay, setIntervalId],
  );

  const handleScroll = useCallback(() => {
    if (!scrollableContainerRef.current) return;
    const scrollLeft = scrollableContainerRef.current?.scrollLeft;
    const scrollWidth = scrollableContainerRef.current?.scrollWidth;
    const containerWidth = containerRef.current?.clientWidth ?? 0;
    const result = scrollWidth - scrollLeft - containerWidth;
    if (result <= 0) {
      if (!loop) setShowRightScroll(false);
      if (scrollWidth > containerWidth) setShowLeftScroll(true);
      return;
    }
    if (scrollWidth < containerWidth) {
      setShowRightScroll(false);
      setShowLeftScroll(false);
      setJustifiement('center');
      return;
    }

    if (scrollLeft < 20 && containerWidth !== scrollWidth) {
      if (!loop) setShowLeftScroll(false);
      setShowRightScroll(true);
      setJustifiement('start');
      return;
    }

    setShowLeftScroll(true);
    setShowRightScroll(true);
    setJustifiement('start');
  }, [
    scrollableContainerRef,
    containerRef,
    setJustifiement,
    setShowRightScroll,
    setShowLeftScroll,
  ]);

  useEffect(() => {
    handleScroll();
  }, [children]);

  useEffect(() => {
    const container = scrollableContainerRef.current;
    if (container) {
      container.addEventListener('scrollend', handleScroll);
    }
    return () => {
      container?.removeEventListener('scrollend', handleScroll);
    };
  }, [scrollableContainerRef, handleScroll]);

  return (
    <div className="relative flex justify-center md:justify-start items-center" ref={containerRef}>
      <ScrollButton
        direction="left"
        onClick={() => handleScrollClick('left', intervalId)}
        show={showLeftScroll}
        stretch={stretch}
      />
      <ScrollButton
        direction="right"
        onClick={() => handleScrollClick('right', intervalId)}
        show={showRightScroll}
        stretch={stretch}
      />
      <div className={`p-[${padding}px] w-full flex flex-col overflow-hidden`}>
        <div className="flex flex-col max-w-[2520px] md:flex-row md:items-end">
          {headerText && (
            <div
              className="border-b-2 border-blue-blue text-lg lg:text-xl font-extrabold text-black px-2 py-0 md:mb-2"
              data-cy="GAME_PAGES"
            >
              {headerText}
            </div>
          )}
          {buttonOnClick && buttonText && (
            <DesktopCTAButton
              containerVisible={isContainerVisible}
              onClick={buttonOnClick}
              text={buttonText}
            />
          )}
          {buttonOnClick && buttonText && (
            <MobileCTAButton
              containerVisible={isContainerVisible}
              onClick={buttonOnClick}
              text={buttonText}
            />
          )}
        </div>
        <div
          ref={scrollableContainerRef}
          onTouchStart={() => handleMobileTouch(intervalId)}
          className={classNames(
            `w-full md:w-auto h-full flex flex-nowrap overflow-x-scroll no-scrollbar`,
            'scroll-smooth snap-x',
            {
              'p-2': !stretch,
              'space-x-[0px]': spacing === 0,
              'space-x-[16px]': spacing === 16,
              'space-x-[32px]': spacing === 32,
              'space-x-[48px]': spacing === 48,
              'justify-start': justifiement === 'start',
              'justify-center': justifiement === 'center',
            },
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Streamline;
