import { useState } from 'react';
import { getAxiosError } from '../shared/utility';
import { axiosInstance as axios } from '../shared/axiosInstance';
import { useCancelToken } from './useCancelToken';
import { useTranslate } from './useTranslate';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../models/root-state.model';
import { closeModal, updateModalProps } from '../store/actions';
import { getLimits } from '../store/actions/limitsActions';
import { SetUserLimitModelV2 } from '@lucky7ventures/lucky-components';

interface Return {
  loading: boolean;
  success: boolean;
  error: string | null;
  setUserLimits: (limits: SetUserLimitModelV2[], isConfirmModal: boolean) => void;
}

export function useSetUserLimits(): Return {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { translateError } = useTranslate();
  const token = useSelector((state: RootState) => state.auth.token);
  const dispatch = useDispatch();

  const { newCancelToken } = useCancelToken();

  function setUserLimits(limits: SetUserLimitModelV2[], isConfirmModal: boolean) {
    setLoading(true);
    setSuccess(false);
    setError(null);

    if (isConfirmModal) {
      dispatch(updateModalProps({ loading: true }));
    }

    axios
      .post(
        '/api/responsiblegaming/limits/setuserlimits',
        { token, limits },
        {
          cancelToken: newCancelToken(),
        },
      )
      .then(() => {
        setSuccess(true);
        dispatch(getLimits());

        if (isConfirmModal) {
          dispatch(closeModal());
        }
      })
      .catch(error => {
        const err = translateError(getAxiosError(error));

        setError(err);
        if (isConfirmModal) {
          dispatch(updateModalProps({ error: err }));
        }
      })
      .finally(() => {
        setLoading(false);

        if (isConfirmModal) {
          dispatch(updateModalProps({ loading: false }));
        }
      });
  }

  return { loading, success, error, setUserLimits };
}
