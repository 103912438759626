import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { loadZendesk } from '../../store/actions/commonActions';
import { selectIsAuthenticated } from '../../store/selectors/authSelectors';
import { selectUser } from '../../store/selectors/userSelectors';
import { openZendesk } from '../Zendesk';

export function FixedDepositLimitErrorModal({
  closeModal,
}: {
  closeModal: () => void;
}): JSX.Element {
  const intl = useIntl();
  const user = useSelector(selectUser);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadZendesk());
  }, []);

  return (
    <div className="flex w-full max-w-sm flex-col overflow-hidden rounded-lg bg-white p-8 text-center">
      <h1 className="m-0 text-2xl">
        {intl.formatMessage({ id: 'depositLimit.fixed.error.header' })}
      </h1>
      <p className="m-0 mt-4">{intl.formatMessage({ id: 'depositLimit.fixed.error.text' })}</p>
      <button
        type="button"
        onClick={() => {
          openZendesk(user, isAuthenticated);
          closeModal();
        }}
        className="m-0 mt-6 flex w-full items-center justify-center rounded-full bg-cyan-500 p-3 font-bold text-white shadow-md transition hover:bg-cyan-600 hover:shadow-lg"
      >
        {intl.formatMessage({ id: 'depositLimit.fixed.error.button' })}
      </button>
      <button
        type="button"
        onClick={closeModal}
        className="mt-4 flex w-full items-center justify-center rounded-full bg-gray-200 p-3 font-bold text-gray-700 shadow-md transition hover:bg-gray-300 hover:shadow-lg"
      >
        {intl.formatMessage({ id: 'depositLimit.fixed.error.close' })}
      </button>
    </div>
  );
}
