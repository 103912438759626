import { RootState } from '@/models/root-state.model';
import { createSelector } from 'reselect';
import { selectUser, selectUserIsGameTester } from './userSelectors';
import { selectIpData, selectLocale } from './commonSelectors';
import { searchGames } from '@/shared/gameHelpers';
import { GameCardVM } from '@/models/vm/game-card-vm.model';
import { isRegulatedLocale } from '@/shared/utility';
import { shouldExcludeGame } from '@/utils/games-utils';
import {
  BffGamesCategory,
  BffGamesJackpotsResponse,
  BffGamesResponse,
} from '@lucky7ventures/bff-types';

export const selectGames = (state: RootState): BffGamesResponse['games'] => state.bffGames.games;
export const selectGamesProviders = (state: RootState): BffGamesResponse['providers'] =>
  state.bffGames.providers;
export const selectGamesCategories = (state: RootState): BffGamesResponse['categories'] =>
  state.bffGames.categories;
export const selectGamesLoading = (state: RootState): boolean => state.bffGames.loading;
export const selectGamesLastPlayedIds = (state: RootState): number[] =>
  state.bffGames.lastPlayedIds;
export const selectGamesJackpots = (state: RootState): BffGamesJackpotsResponse =>
  state.bffGames.jackpots;
export const selectGameById =
  (id: number) =>
  (state: RootState): GameCardVM => {
    return {
      ...state.bffGames.games[id],
      jackpot: state.bffGames.jackpots[id],
    };
  };

const mapGamesToVM = (games: Record<number, GameCardVM>) => (acc: GameCardVM[], id: number) => {
  const game = games[id];
  if (!game) {
    return acc;
  }
  acc.push(game);
  return acc;
};

const selectFilteredGames = createSelector(
  selectGames,
  selectGamesJackpots,
  selectUser,
  selectLocale,
  selectUserIsGameTester,
  selectIpData,
  (games, jackpots, user, locale, gameTester, ipData): Record<number, GameCardVM> => {
    return Object.keys(games).reduce((acc: Record<number, GameCardVM>, key: string) => {
      const gameId = parseInt(key);
      const game = games[gameId];
      const jackpot = jackpots[gameId];
      if (!game || shouldExcludeGame(game, user, locale, gameTester, ipData)) {
        return acc;
      }
      acc[gameId] = { ...game, jackpot };
      return acc;
    }, {});
  },
);

const selectGamesByCategory = (category: BffGamesCategory | undefined, limit = 0) =>
  createSelector(selectFilteredGames, selectGamesCategories, (games, categories): GameCardVM[] => {
    if (!category) {
      return [];
    }
    const selectedCategory = categories[category];
    if (!selectedCategory) {
      return [];
    }
    const gameIds =
      limit === 0
        ? selectedCategory
        : selectedCategory.slice(0, Math.min(limit, selectedCategory.length));
    return gameIds.reduce(mapGamesToVM(games), []);
  });

export const selectGamesByProvider = (provider: string) =>
  createSelector(
    selectFilteredGames,
    selectGamesProviders,
    (games, providers): { name: string; games: GameCardVM[] } | null => {
      const selectedProvider = providers[provider];
      if (!selectedProvider) {
        return null;
      }
      return {
        name: selectedProvider.name,
        games: selectedProvider.games.reduce(mapGamesToVM(games), []),
      };
    },
  );

export const selectAllGames = selectGamesByCategory(BffGamesCategory.AllGames);
export const selectNewGames = createSelector(
  selectGamesByCategory(BffGamesCategory.NewGames),
  selectLocale,
  (games, locale) => {
    // As a Business rule we want to limit New Games
    // 36 - for Regulated Locales
    // 54 - for non-Regulated Locales
    const limit = isRegulatedLocale(locale) ? 36 : 54;
    return games.slice(0, Math.min(limit, games.length));
  },
);
export const selectJackpotsGames = selectGamesByCategory(BffGamesCategory.Jackpots);
export const selectTableGames = selectGamesByCategory(BffGamesCategory.TableGames);
export const selectSlotsGames = selectGamesByCategory(BffGamesCategory.Slots);
export const selectWinterGames = selectGamesByCategory(BffGamesCategory.WinterGames);
export const selectHalloweenGames = selectGamesByCategory(BffGamesCategory.HalloweenGames);
export const selectLiveCasinoGames = selectGamesByCategory(BffGamesCategory.LiveCasino);
export const selectRecommendedGames = createSelector(
  selectGamesByCategory(BffGamesCategory.Recommended),
  games => {
    // As a Business Rule we want to limit Recommended games to 18
    return games.slice(0, Math.min(18, games.length));
  },
);

// This mapping is needed until last-played endpoint is included in the new API
export const selectLastPlayedGames = createSelector(
  selectFilteredGames,
  selectGamesLastPlayedIds,
  (games, lastPlayedIds): GameCardVM[] => lastPlayedIds.reduce(mapGamesToVM(games), []),
);

export const selectGamesHomeRegulated = createSelector(selectAllGames, games =>
  games.slice(0, Math.min(12, games.length)),
);

const selectGamesBySearch = (_: RootState, search: string) => search;
export const selectSearchedGames = createSelector(
  selectAllGames,
  selectGamesBySearch,
  (games, search) => searchGames(search, games),
);
