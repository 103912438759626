import { envIsMexico, envIsNormal } from '@/constants/constants';
import { useSelector } from 'react-redux';
import { selectLocale } from '@/store/selectors/commonSelectors';
import { selectUserIsFluidTester } from '@/store/selectors/userSelectors';
import { selectFluidConfig } from '@/store/selectors/combined.selectors';

const useIsFluidEnabled = () => {
  const locale = useSelector(selectLocale);
  const isFluidTester = useSelector(selectUserIsFluidTester);
  const fluidConfig = useSelector(selectFluidConfig);
  const isFluidLocale = (envIsNormal() && !['fi', 'fi-en'].includes(locale)) || envIsMexico();
  // @ts-ignore
  return !!fluidConfig && (isFluidLocale || isFluidTester) && !window.Cypress;
};

export default useIsFluidEnabled;
