import { DocumentType } from '@lucky7ventures/lucky-components';

export const BFF_API_URLS = {
  // AUTHENTICATION
  REGISTER_MEXICO: '/api/v1/register/mexico',

  // USER
  VERIFY: '/api/v1/user/verify',
  COMPLETE_PROFILE_MEXICO: '/api/v1/user/complete-profile/mexico',
  GET_LIFETIME_DEPOSITS: '/api/v1/user/lifetime-deposits',
  GET_AVAILABLE_BONUSES: '/api/v1/user/available-bonuses',
  KYC_DOCUMENTS_UPLOAD: (kycType: DocumentType) => `/api/v1/user/kyc/${kycType}/upload`,

  // GAMES
  GET_ALL_GAMES: '/api/v1/games/fetch',
  GET_GAMES_JACKPOTS: '/api/v1/games/jackpots',
};
