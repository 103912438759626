import { useField } from 'formik';
import React, { useState } from 'react';
import { CheckIcon } from '@heroicons/react/20/solid';
import PhoneInput from 'react-phone-input-2';
import { mobileInputExcludedCountries } from '../constants/mobileInputConstants';
import { IS_SWEDEN } from '../constants/constants';
import styled from 'styled-components';

export const StyledContactInfoMobile = styled.div<{ error: boolean }>`
  text-align: left;

  .react-tel-input {
    font-family: 'Karla', sans-serif;
    border-bottom: 2px solid ${props => (props.error ? 'red' : '#eee')};
    margin-top: 32px;
    height: 70px;

    .form-control,
    .flag-dropdown {
      margin-top: 16px !important;
      height: 56px;
      border: none;
      letter-spacing: 0;
      border-radius: 0;
      background: transparent;
      color: ${props => props.theme.colors.black};
      font-weight: bold;
      font-size: 16px;

      &:focus {
        border-color: #eee;
        box-shadow: none;
      }
      &:disabled {
        color: rgb(156, 163, 175);
      }
    }

    .form-control {
      font-size: 1.25rem;
    }

    li {
      margin-bottom: 0;
    }

    .form-control {
      padding: 10px 0 8px;
      z-index: 2;
      width: calc(100% - 60px);
      margin-left: 60px;

      &.invalid-number {
        background: none;
        border: none;
      }
    }

    .flag-dropdown {
      width: 100%;
      margin-top: 28px !important;
      height: 32px;
      z-index: 1;

      .selected-flag {
        border-radius: 8px;
        background: #fff;
      }

      &:hover,
      &.open {
        background: transparent;

        .selected-flag {
          border-radius: 8px;
          background: #fefefe;
        }
      }

      .country-list {
        border-radius: 8px;
        font-size: 14px;
        box-shadow:
          0 10px 15px -3px rgba(0, 0, 0, 0.1),
          0 4px 6px -2px rgba(0, 0, 0, 0.05);

        .flag {
          top: 9px;
        }

        .country {
          padding: 10px 9px 10px 44px;
          margin-top: 0;
        }
      }
    }

    .special-label {
      display: block;
      background: none;
      color: ${props => props.theme.colors.blue2};
      font-weight: bold;
      font-size: 15px;
      top: 0;
      left: 0;
      padding: 0;
    }

    .country-list {
      width: 100%;
    }
  }
`;

export function ContactInfoMobile({
  label,
  placeholder,
  name,
  completed = false,
}: {
  label: string;
  placeholder: string;
  name: string;
  completed?: boolean;
}): JSX.Element {
  const [field, meta, helpers] = useField(name);
  const { setValue, setTouched } = helpers;
  const [isFocused, setIsFocused] = useState(false);
  const excludedCountries = mobileInputExcludedCountries(IS_SWEDEN);

  function onChangeHandler(value: string, country: any): void {
    const updatedPhone = {
      number: value ? value.replace(country.dialCode, '') : '',
      prefix: `+${country.dialCode}`,
      country: country.countryCode,
      wholeNumber: value,
    };

    setValue(updatedPhone);
  }

  const presentError = (error: any): string => {
    const keys = Object.keys(error);
    return error[keys[0]];
  };

  return (
    <StyledContactInfoMobile error={meta.touched && !!meta.error && !isFocused}>
      <div className="relative mb-8 pb-2">
        <PhoneInput
          placeholder={placeholder}
          country={field.value.country.toLowerCase()}
          value={field.value.wholeNumber}
          onChange={(value, country) => {
            onChangeHandler(value, country);
          }}
          onFocus={() => setIsFocused(true)}
          onBlur={() => {
            setIsFocused(false);
            setTouched(true);
          }}
          disabled={completed}
          inputProps={{
            name: 'mobile',
          }}
          specialLabel={label}
          excludeCountries={excludedCountries}
          masks={{ se: '..........' }}
        />
        {meta.touched && !!meta.error && (
          <div className="absolute -bottom-3 text-xs text-red-500">{presentError(meta.error)}</div>
        )}
        {completed && (
          <span className="absolute right-0 bottom-4">
            <CheckIcon className="h-6 w-6 text-green-600" />
          </span>
        )}
      </div>
    </StyledContactInfoMobile>
  );
}
