import React from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { isEmpty } from '../../../shared/utility';

const StyledLink = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  margin: 0 auto;
  padding: 0;
  border: none;
  line-height: 1.6;
  background: none;
  transition: all 0.15s ease;
  cursor: pointer;
  text-decoration: underline;
  /* font-weight: bold; */
  color: ${props => props.theme.colors.blue1};

  &:hover {
    color: ${props => props.theme.colors.blue1Hover};
  }
`;

const Anchor = ({ children, to, href, ...rest }) => {
  if (!isEmpty(to)) {
    return (
      <StyledLink as={Link} to={to} {...rest}>
        {children}
      </StyledLink>
    );
  }
  if (!isEmpty(href)) {
    return (
      <StyledLink as="a" href={href} {...rest}>
        {children}
      </StyledLink>
    );
  }
  return <StyledLink {...rest}>{children}</StyledLink>;
};

Anchor.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
  href: PropTypes.string,
};

Anchor.defaultProps = {
  to: '',
  href: '',
};

export default Anchor;
