import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getManualBonuses } from '../store/actions/manualBonusesActions';
import { selectHardReloadPending, selectUserLocale } from '../store/selectors/userSelectors';
import { selectManualBonusesLoadedLocale } from '../store/selectors/manualBonusesSelectors';

// this Hook is to be used in the DataLoader only
// it handles all the logic for when the manual bonuses need to be refreshed
export function useManualBonusesLoader(): void {
  const hardReloadPending = useSelector(selectHardReloadPending);
  const userLocale = useSelector(selectUserLocale);
  const loadedLocale = useSelector(selectManualBonusesLoadedLocale);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!userLocale || userLocale === loadedLocale || hardReloadPending) {
      return;
    }

    dispatch(getManualBonuses(userLocale));
  }, [userLocale, loadedLocale, hardReloadPending]);
}
