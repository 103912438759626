import React from 'react';

import { useManualBonusesLoader } from '../hooks/useManualBonusesLoader';
import { useWordpressLoader } from '../hooks/useWordpressLoader';
import { useUserLocaleRedirect } from '../hooks/useUserLocaleRedirect';
import { useGamesLoader } from '../hooks/useGamesLoader';
import { useKycLoader } from '../hooks/useKycLoader';
import { useSentry } from '../hooks/useSentry';
import useAmlVerificationStatusLoader from '../hooks/useAmlVerificationStatusLoader';
import { useTagManager } from '../hooks/useTagManager';
import { useIpDataLoader } from '../hooks/useIpDataLoader';
import { useDetailsReverification } from '../hooks/useDetailsReverification';
import { useBonusesContentLogic } from '../hooks/useBonusesContentLogic';
import { useBtagLogic } from '../features/data-loader/hooks/useBtagLogic';

export function DataLoader({ children }: { children: React.ReactChildren }): JSX.Element {
  useTagManager();
  useUserLocaleRedirect();
  useBonusesContentLogic();
  useManualBonusesLoader();
  useWordpressLoader();
  useGamesLoader();
  useKycLoader();
  useSentry();
  useAmlVerificationStatusLoader();
  useIpDataLoader();
  useDetailsReverification();
  useBtagLogic();

  return <>{children}</>;
}
