import { Action } from 'redux';
import { TimeoutDurationModel } from '../../models/timeoutDuration.model';

export enum UserExtrasActionTypes {
  ResetUserExtras = 'RESET_USER_EXTRAS',
  GetTimeoutLegislationDurations = 'GET_TIMEOUT_LEGISLATION_DURATIONS',
  GetTimeoutLegislationDurationsSuccess = 'GET_TIMEOUT_LEGISLATION_DURATIONS_SUCCESS',
  GetTimeoutLegislationDurationsError = 'GET_TIMEOUT_LEGISLATION_DURATIONS_ERROR',
  GetTimeoutLegislationDurationsSkip = 'GET_TIMEOUT_LEGISLATION_DURATIONS_SKIP',
}

export type ResetUserExtras = Action<UserExtrasActionTypes.ResetUserExtras>;

export type GetTimeoutLegislationDurations =
  Action<UserExtrasActionTypes.GetTimeoutLegislationDurations>;

export type GetTimeoutLegislationDurationsSuccess =
  Action<UserExtrasActionTypes.GetTimeoutLegislationDurationsSuccess> & {
    legislationDurations: TimeoutDurationModel[];
  };

export type GetTimeoutLegislationDurationsError =
  Action<UserExtrasActionTypes.GetTimeoutLegislationDurationsError>;

export type GetTimeoutLegislationDurationsSkip =
  Action<UserExtrasActionTypes.GetTimeoutLegislationDurationsSkip>;

export type UserExtrasActions =
  | GetTimeoutLegislationDurations
  | GetTimeoutLegislationDurationsSuccess
  | GetTimeoutLegislationDurationsError
  | GetTimeoutLegislationDurationsSkip
  | ResetUserExtras;

export const getTimeoutLegislationDurations = (): GetTimeoutLegislationDurations => ({
  type: UserExtrasActionTypes.GetTimeoutLegislationDurations,
});

export const getTimeoutLegislationDurationsSuccess = (
  legislationDurations: TimeoutDurationModel[],
): GetTimeoutLegislationDurationsSuccess => ({
  type: UserExtrasActionTypes.GetTimeoutLegislationDurationsSuccess,
  legislationDurations,
});

export const getTimeoutLegislationDurationsError = (): GetTimeoutLegislationDurationsError => ({
  type: UserExtrasActionTypes.GetTimeoutLegislationDurationsError,
});

export const getTimeoutLegislationDurationsSkip = (): GetTimeoutLegislationDurationsSkip => ({
  type: UserExtrasActionTypes.GetTimeoutLegislationDurationsSkip,
});

export const resetUserExtras = (): ResetUserExtras => ({
  type: UserExtrasActionTypes.ResetUserExtras,
});
