import React from 'react';

export function ResponsibleGamingOptions({
  children,
  options,
}: {
  children: React.ReactNode;
  options: number;
}): JSX.Element {
  return (
    <div className={`mb-8 grid grid-cols-2 gap-y-2 sm:grid-cols-3 md:grid-cols-${options}`}>
      {children}
    </div>
  );
}
