import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearLastPlayedGames,
  getGames,
  getGamesJackpots,
  getLastPlayedGames,
} from '@/store/actions/gamesActions';
import { selectUser } from '@/store/selectors/userSelectors';
import { selectLocale } from '@/store/selectors/commonSelectors';
import { isEmpty } from '@/shared/utility';

// this Hook is to be used in the DataLoader only
// it handles all the logic for when the games need to be refreshed
export function useGamesLoader(): void {
  const user = useSelector(selectUser);
  const locale = useSelector(selectLocale);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!locale) {
      return;
    }
    // we want to trigger getGames only once when the app loads
    dispatch(getGames());
    dispatch(getGamesJackpots());
  }, [locale]);

  useEffect(() => {
    // we want to trigger getLastPlayedGames when the user changes, and clear last played games if user doesn't exist
    if (!isEmpty(user)) {
      dispatch(getLastPlayedGames());
    } else {
      dispatch(clearLastPlayedGames());
    }
  }, [user]);
}
