import React from 'react';

import { Form, Formik } from 'formik';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import LuckyDaysLogo from '../UI/Logos/LuckyDaysLogo';
import PromptMessage from '../UI/PromptMessage';

import CloseButton from '../UI/Buttons/CloseButton';
import * as actions from '../../store/actions/index';
import ApiService from '../../shared/apiService';
import { useApiV2Request } from '@/hooks/useApiV2Request';
import { BtnBlueLink } from '../buttons/BtnBlueLink';
import { ModalBody } from './ModalBody';
import { FormInput } from '../FormInput';
import { BtnOneLineBlue } from '../buttons/BtnOneLineBlue';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';

function ForgotPasswordModal({ closeModal }: { closeModal: () => void }): JSX.Element {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { request, loading, success, error } = useApiV2Request();

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .required(intl.formatMessage({ id: 'inputs.email.required' }))
      .email(intl.formatMessage({ id: 'inputs.email.invalid' })),
  });

  function submitHandler(values: { email: string }) {
    triggerGTag(GTagEvents.forgot_password_submit);
    request(ApiService.forgotPassword, { email: values.email });
  }

  return (
    <div id="e2e_forgotPasswordModal">
      <ModalBody>
        <CloseButton left onClick={closeModal} />
        <div>
          <div className="mb-8 mt-8 flex justify-center">
            <LuckyDaysLogo />
          </div>
          {!success ? (
            <>
              <p className="my-8 max-w-xs text-sm font-bold">
                {intl.formatMessage({ id: 'forgot.header' })}{' '}
                {intl.formatMessage({ id: 'forgot.subHeader' })}
              </p>
              {error && (
                <PromptMessage type="error" message={intl.formatMessage({ id: 'error.support' })} />
              )}
              <Formik
                initialValues={{
                  email: '',
                }}
                validationSchema={forgotPasswordSchema}
                onSubmit={values => submitHandler(values)}
              >
                {() => (
                  <Form>
                    <FormInput
                      name="email"
                      type="email"
                      label={intl.formatMessage({ id: 'inputs.email' })}
                      placeholder={intl.formatMessage({
                        id: 'inputs.email.placeholder',
                      })}
                    />
                    <BtnOneLineBlue
                      type="submit"
                      isLoading={loading}
                      text={intl.formatMessage({ id: 'forgot.sendButton' })}
                    />
                  </Form>
                )}
              </Formik>
            </>
          ) : (
            <>
              <p className="my-8 max-w-xs text-sm font-bold">
                {intl.formatMessage({ id: 'forgot.sent.header' })}{' '}
                {intl.formatMessage({ id: 'forgot.sent.text' })}
              </p>
              <BtnOneLineBlue
                onClickHandler={() => dispatch(actions.openModal('login'))}
                isLoading={false}
                text={intl.formatMessage({ id: 'forgot.loginButton' })}
              />
            </>
          )}
        </div>
        <p className="mt-16 text-sm font-bold">
          {intl.formatMessage({ id: 'forgot.rememberedText' })}{' '}
          <BtnBlueLink
            onClickHandler={() => dispatch(actions.openModal('login'))}
            text={intl.formatMessage({ id: 'forgot.rememberedLink' })}
          />
        </p>
      </ModalBody>
    </div>
  );
}

export default ForgotPasswordModal;
