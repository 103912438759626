import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import * as actions from '../../store/actions/index';
import CloseButton from '../UI/Buttons/CloseButton';

export function BonusTermsModal({
  closeModal,
  text,
}: {
  closeModal: () => void;
  text: JSX.Element;
}): JSX.Element {
  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    const modalContainer = document.querySelector('.ReactModal__Overlay');
    const modal = document.querySelector('.bonus-terms-modal');

    if (modalContainer) {
      modalContainer.scrollTop = 0;
    }

    if (modal) {
      modal.scrollTop = 0;
    }
  }, []);

  return (
    <div className="bonus-terms-modal prose relative flex w-full max-w-lg flex-col overflow-hidden rounded-lg bg-white px-4 pt-16 pb-4 text-left md:px-8">
      <CloseButton left onClick={closeModal} />
      <h1 className="mb-0 text-2xl">{intl.formatMessage({ id: 'bonusTerms.header' })}</h1>
      {text}
      <button
        type="button"
        onClick={() => dispatch(actions.openModal('termsAndConditions'))}
        className="mt-4 flex w-full items-center justify-center rounded-full bg-cyan-500 p-4 font-bold text-white shadow-md transition hover:bg-cyan-600 hover:shadow-lg"
      >
        {intl.formatMessage({
          id: 'bonusTerms.linkToTermsText',
        })}
      </button>
      <button
        type="button"
        className="p-4 font-bold text-black/60 transition-colors hover:text-black"
        onClick={closeModal}
      >
        {intl.formatMessage({ id: 'termsAndConditions.closeButton' })}
      </button>
    </div>
  );
}
