import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { WpPrivacyPolicy } from '../models/wp-privacy-policy.model';
import { WpTermsAndConditions } from '../models/wp-terms-and-conditions.model';
import parse from 'html-react-parser';

export function ConsentsModalTerms({
  callback,
  header,
  content,
}: {
  callback: () => void;
  header: string;
  content: WpTermsAndConditions | WpPrivacyPolicy | null;
}): JSX.Element {
  return (
    <div className="relative w-full h-full flex flex-col overflow-y-scroll scrollbar-hide px-8 max-w-[750px] max-h-[650px]">
      <div className="flex w-full bg-white sticky top-0 pt-8 pb-4">
        <div className="w-1/4 ">
          <button type="button" onClick={callback}>
            <ArrowLeftIcon className="h-5 w-5" />
          </button>
        </div>
        <h1 className="!m-0 !text-base w-1/2 text-center">{header}</h1>
        <div className="w-1/4" />
      </div>
      <div className="pb-8">{content && parse(content.content.toString())}</div>
    </div>
  );
}
