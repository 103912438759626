import React from 'react';
import classNames from 'classnames';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

export const ScrollButton = ({
  direction,
  onClick,
  show,
  stretch,
}: {
  direction: 'left' | 'right';
  onClick: () => void;
  show?: boolean;
  stretch?: boolean;
}) => {
  const isLeft = direction === 'left';
  const Icon = isLeft ? ChevronLeftIcon : ChevronRightIcon;

  if (show)
    return (
      <div
        onClick={onClick}
        className={classNames('hidden lg:block absolute z-[2]', {
          '-left-6': isLeft && !stretch,
          '-right-6': !isLeft && !stretch,
          'left-4': isLeft && stretch,
          'right-4': !isLeft && stretch,
        })}
      >
        <Icon
          className="w-[40px] h-[40px] bg-black/30 backdrop-blur-lg rounded-lg cursor-pointer"
          width={20}
          height={20}
          color="white"
        />
      </div>
    );
  return null;
};

export const DesktopCTAButton = ({
  containerVisible,
  onClick,
  text,
}: {
  containerVisible: boolean;
  onClick: () => void;
  text: string;
}) => {
  return (
    <div
      className={classNames(
        'hidden md:block relative transition-all ease-in-out duration-300',
        containerVisible ? 'opacity-100 md:translate-x-[-2px]' : 'opacity-0 md:-translate-x-6',
      )}
    >
      <button
        type="button"
        onClick={onClick}
        className={classNames(
          'pl-8 pr-2 mb-2 flex items-center justify-center text-black text-md transition-all duration-250 ease-in-out',
          'border-2 border-l-transparent border-r-transparent border-t-transparent border-b-blue-blue active:text-blue-blue',
        )}
      >
        {text}
      </button>
    </div>
  );
};

export const MobileCTAButton = ({
  containerVisible,
  onClick,
  text,
}: {
  containerVisible: boolean;
  onClick: () => void;
  text: string;
}) => {
  return (
    <div
      className={classNames(
        'md:hidden transition-all ease-in-out duration-350 overflow-hidden mb-1',
        containerVisible ? 'opacity-100 translate-x-[60%]' : 'opacity-0 translate-x-[70%]',
      )}
    >
      <button
        type="button"
        onClick={onClick}
        className={classNames(
          'px-2 bg-blue-blue text-white flex items-center rounded-b-lg justify-center text-md transition-all duration-250 ease-in-out',
          'active:bg-blue-blue/60 active:pt-2',
        )}
      >
        {text}
      </button>
    </div>
  );
};
