import React, { useEffect, useState } from 'react';

import { Field, FieldProps, Form, Formik } from 'formik';
import { CheckIcon } from '@heroicons/react/24/solid';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import 'react-phone-number-input/style.css';

import CloseButton from '../UI/Buttons/CloseButton';
import PromptMessage from '../UI/PromptMessage';
import Spinner from '../UI/Spinner';
import { MobileInput } from '../MobileInput';
import { FormInput } from '../FormInput';
import { useApiV2Request } from '@/hooks/useApiV2Request';
import { selectLocale } from '@/store/selectors/commonSelectors';
import ApiService from '../../shared/apiService';
import { logException } from '@/utils/logger';
import { getAge } from '@/lib/getAge';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';
import { ApiV2Error } from '@/shared/errorHandler';
import { getUser, getUserTags } from '@/store/actions';
import { selectAuthToken } from '@/store/selectors/authSelectors';
import { COUNTRY_PHONE_PREFIX } from '@/constants/phonePrefixes';

interface UserProfileCompletionForm {
  address: string;
  city: string;
  postCode: string;
  year: string;
  month: string;
  day: string;
  mobile: {
    number: string;
    country: string;
    prefix: string;
    wholeNumber: string;
  };
}

const UserProfileCompletionModal = ({ closeModal }: { closeModal: () => void }) => {
  const [error, setError] = useState('');
  const { request, loading, success } = useApiV2Request();
  const locale = useSelector(selectLocale);
  const token = useSelector(selectAuthToken);
  const intl = useIntl();
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    address: Yup.string().required(
      intl.formatMessage({ id: 'completeProfile.input.error.required' }),
    ),
    city: Yup.string().required(intl.formatMessage({ id: 'completeProfile.input.error.required' })),
    postCode: Yup.string().required(
      intl.formatMessage({ id: 'completeProfile.input.error.required' }),
    ),
    year: Yup.string()
      .required(intl.formatMessage({ id: 'completeProfile.input.error.required' }))
      .matches(
        /^(19[2-9]\d|2[0-9]{3})$/,
        intl.formatMessage({ id: 'completeProfile.input.error.format' }),
      ),
    month: Yup.string()
      .required(intl.formatMessage({ id: 'completeProfile.input.error.required' }))
      .matches(
        /^(0[1-9]|(1)[0-2])/,
        intl.formatMessage({ id: 'completeProfile.input.error.format' }),
      ),
    day: Yup.string()
      .required(intl.formatMessage({ id: 'completeProfile.input.error.required' }))
      .matches(
        /^(0[1-9]|[12]\d|3[01])/,
        intl.formatMessage({ id: 'completeProfile.input.error.format' }),
      ),
    mobile: Yup.object().shape({
      wholeNumber: Yup.string()
        .min(6, intl.formatMessage({ id: 'completeProfile.mobile.error' }))
        .required(intl.formatMessage({ id: 'completeProfile.input.error.required' })),
    }),
  });

  const getInitialCountry = () => {
    switch (locale) {
      case 'ae':
      case 'ca':
      case 'at':
      case 'no':
      case 'nz':
      case 'th':
      case 'za':
        return locale.toUpperCase();
      case 'ca-fr':
        return 'CA';
      default:
        return 'IE';
    }
  };

  const getInitialMobilePrefix = () => {
    switch (locale) {
      case 'ae':
      case 'ca':
      case 'at':
      case 'no':
      case 'nz':
      case 'th':
      case 'za':
        return COUNTRY_PHONE_PREFIX[locale.toUpperCase()];
      case 'ca-fr':
        return COUNTRY_PHONE_PREFIX.CA;
      default:
        return COUNTRY_PHONE_PREFIX.IE;
    }
  };

  const initialValues: UserProfileCompletionForm = {
    address: '',
    city: '',
    postCode: '',
    year: '',
    month: '',
    day: '',
    mobile: {
      number: '',
      country: getInitialCountry(),
      prefix: getInitialMobilePrefix(),
      wholeNumber: getInitialMobilePrefix(),
    },
  };

  const handleCloseModal = () => {
    triggerGTag(GTagEvents.complete_profile_modal_close);
    closeModal();
  };

  const submitHandler = async (values: UserProfileCompletionForm) => {
    const birthday = `${values.year}-${values.month}-${values.day}`;
    const age = getAge(birthday);

    if (locale === 'ca' || locale === 'ca-fr') {
      // The consent age for Canada (/ca and /ca-fr) is 19+
      if (age < 19) {
        setError(intl.formatMessage({ id: 'inputs.birthdate.invalid' }));
        return;
      }
    } else {
      // The consent age for the rest of .com is 18+
      if (age < 18) {
        setError(intl.formatMessage({ id: 'completeProfile.over18' }));
        return;
      }
    }

    const handleSuccess = () => {
      dispatch(getUser(token));
      dispatch(getUserTags(token));
      setTimeout(() => {
        handleCloseModal();
      }, 2000);
      triggerGTag(GTagEvents.complete_profile_success);
    };

    const handleError = (error: ApiV2Error) => {
      if (error.error === 'MOBILE_AND_PREFIX_COMBINATION_ALREADY_REGISTERED') {
        setError(intl.formatMessage({ id: 'completeProfile.error.duplicateMobile' }));
      } else {
        setError(
          `${intl.formatMessage({
            id: 'error.support',
          })} (${error.errorId})`,
        );
      }
      triggerGTag(GTagEvents.complete_profile_failed, {
        error: `[${error.errorId}] - ${error.error}`,
      });
      logException(error);
    };

    request(
      ApiService.completeUserProfile,
      {
        birthDate: new Date(`${values.year}-${values.month}-${values.day}`),
        mobilePrefix: values.mobile.prefix,
        mobileNumber: values.mobile.number,
        address: values.address.trim(),
        city: values.city.trim(),
        postCode: values.postCode.trim(),
      },
      handleSuccess,
      handleError,
    );
    triggerGTag(GTagEvents.complete_profile_submit_click);
  };

  useEffect(() => {
    triggerGTag(GTagEvents.complete_profile_modal_open);
  }, []);

  return (
    <div
      className="flex flex-col flex-wrap w-full max-w-[380px] overflow-hidden rounded-md bg-offWhite min-h-[600px]
      relative px-8 pt-12 pb-8 justify-center items-center"
    >
      <CloseButton left onClick={handleCloseModal} />
      <h1 className="text-base leading-[1] mb-6 opacity-60">
        {intl.formatMessage({ id: 'completeProfile.header' })}
      </h1>
      <p>{intl.formatMessage({ id: 'completeProfile.text' })}</p>
      <div className="content">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={values => submitHandler(values)}
          validateOnMount
        >
          {({ isValid }) => (
            <Form>
              <Field name="address">
                {({ field }: FieldProps) => (
                  <FormInput
                    label={intl.formatMessage({ id: 'completeProfile.address.label' })}
                    type="text"
                    autoComplete="street-address"
                    placeholder={intl.formatMessage({
                      id: 'completeProfile.address.placeholder',
                    })}
                    {...field}
                  />
                )}
              </Field>
              <Field name="city">
                {({ field }: FieldProps) => (
                  <FormInput
                    label={intl.formatMessage({ id: 'completeProfile.city.label' })}
                    type="text"
                    autoComplete="address-level2"
                    placeholder={intl.formatMessage({ id: 'completeProfile.city.placeholder' })}
                    {...field}
                  />
                )}
              </Field>
              <Field name="postCode">
                {({ field }: FieldProps) => (
                  <FormInput
                    label={intl.formatMessage({ id: 'completeProfile.postCode.label' })}
                    type="text"
                    autoComplete="postal-code"
                    placeholder={intl.formatMessage({
                      id: 'completeProfile.postCode.placeholder',
                    })}
                    {...field}
                  />
                )}
              </Field>
              <Field name="mobile">
                {({ field, form }: FieldProps) => (
                  <MobileInput field={field} form={form} {...field} />
                )}
              </Field>
              <div className="grid gap-4 grid-cols-[repeat(3,minmax(0,1fr))]">
                <Field name="year">
                  {({ field }: FieldProps) => (
                    <FormInput
                      label={intl.formatMessage({ id: 'completeProfile.year.label' })}
                      placeholder={intl.formatMessage({ id: 'completeProfile.year.placeholder' })}
                      type="tel"
                      autoComplete="bday-year"
                      onFocus={() => setError('')}
                      {...field}
                    />
                  )}
                </Field>
                <Field name="month">
                  {({ field }: FieldProps) => (
                    <FormInput
                      label={intl.formatMessage({ id: 'completeProfile.month.label' })}
                      placeholder={intl.formatMessage({
                        id: 'completeProfile.month.placeholder',
                      })}
                      type="tel"
                      autoComplete="bday-month"
                      onFocus={() => setError('')}
                      {...field}
                    />
                  )}
                </Field>
                <Field name="day">
                  {({ field }: FieldProps) => (
                    <FormInput
                      label={intl.formatMessage({ id: 'completeProfile.day.label' })}
                      placeholder={intl.formatMessage({ id: 'completeProfile.day.placeholder' })}
                      type="tel"
                      autoComplete="bday-day"
                      onFocus={() => setError('')}
                      {...field}
                    />
                  )}
                </Field>
              </div>
              {locale === 'th' && <p className="text-left text-sm mt-2 mb-0">กรุณากรอกปี ค.ศ.</p>}
              {error && (
                <div className="mt-8">
                  <PromptMessage type="error" message={error} />
                </div>
              )}
              <button
                className={`flex justify-center items-center w-full rounded-[50vh] py-4 px-6 text-[18px] mt-4 text-white
                  border-none font-bold transition ease-in-out duration-150 cursor-pointer
                  shadow-[0_10px_15px_-3px_rgba(0,0,0,0.1),0_4px_6px_-2px_rgba(0,0,0,0.05)] ${
                    success ? 'bg-cashGreen hover:bg-cashGreen' : 'bg-blue-blue'
                  }
                  hover:bg-[#0097ac] disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-blue-blue`}
                disabled={!isValid || success}
                type="submit"
              >
                {loading && <Spinner width={18} height={18} borderwidth={3} color="white" />}
                {!loading && !success && <>{intl.formatMessage({ id: 'completeProfile.save' })}</>}
                {!loading && success && (
                  <>
                    <CheckIcon className="h-6 w-6 mr-2 -ml-4" />{' '}
                    {intl.formatMessage({ id: 'completeProfile.success' })}
                  </>
                )}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default UserProfileCompletionModal;
