import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { ErrorMessage, Field, FieldProps, Form, Formik } from 'formik';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';

import Anchor from '../UI/Buttons/Anchor';
import LuckyDaysLogo from '../UI/Logos/LuckyDaysLogo';
import PromptMessage from '../UI/PromptMessage';
import Spinner from '../UI/Spinner';
import Tooltip from '../UI/Tooltip';
import ValidationMessage from '../UI/ValidationMessage';
import { isRegulatedLocale } from '../../shared/utility';
import { useForgotPasswordChange } from '../../hooks/useForgotPasswordApi';
import { CDN_BASE, CDN_IMAGES_COMMON_PATH } from '../../constants/constants';

const ResetPasswordPage = styled.section`
  background: ${props => props.theme.colors.blue2};
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px 20px;
  position: relative;

  @media (min-width: ${props => props.theme.breakpoints.small}) {
    padding: 80px 40px;
  }

  .image-wrapper {
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      object-fit: cover;
      pointer-events: none;
      object-position: 100% 100%;
    }

    &:after {
      content: ' ';
      position: absolute;
      inset: 0;
      object-fit: cover;
      pointer-events: none;
      object-position: 100% 100%;
      background: rgba(0, 0, 0, 0.5);
    }
  }
`;

const StyledResetPasswordModal = styled.div`
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  max-width: 380px;
  overflow: hidden;
  border-radius: ${props => props.theme.borderRadius};
  background: ${props => props.theme.colors.offWhite};
  min-height: 600px;
  position: relative;
  padding: 48px 32px 32px;
  justify-content: center;
  align-items: center;
  text-align: center;

  .prompt-message {
    margin-top: -16px;
  }

  .text {
    &.success {
      margin-bottom: 42px;
    }

    h2 {
      font-size: 21px;

      @media (min-width: ${props => props.theme.breakpoints.small}) {
        font-size: 32px;
        margin: 16px 0;
      }
    }

    p {
      font-size: 14px;
      padding: 0 16px;
      font-weight: bold;
      margin-bottom: 40px;
      margin-top: 16px;
    }
  }

  label {
    width: 100%;
    margin-top: 40px;
    font-weight: bold;
    color: ${props => props.theme.colors.blue2};
    font-size: 14px;
    position: relative;
    line-height: 1;
    display: block;
    text-align: left;

    &:first-child {
      margin-top: 0;
    }

    input {
      width: 100%;
      border: 0;
      font-size: 18px;
      font-weight: bold;
      color: ${props => props.theme.colors.black};
      border-bottom: 2px solid #eee;
      padding: 16px 26px 16px 0;
      line-height: 1;
      background: none;

      @media (min-width: ${props => props.theme.breakpoints.small}) {
        font-size: 20px;
      }

      &::placeholder,
      &:disabled {
        color: ${props => props.theme.colors.grey50};
      }

      &::placeholder {
        font-weight: normal;
      }

      &:focus {
        border-color: #eee;
        box-shadow: none;
      }
    }

    .validationMessage {
      margin-bottom: 0;
    }

    .forgot-password {
      position: absolute;
      top: 0;
      right: 0;
      line-height: 1;
      font-weight: bold;
    }
  }

  .showPasswordToggle {
    position: absolute;
    top: 28px;
    right: 0;
    border: none;
    background: none;
    width: 24px;
    height: 24px;
    display: flex;
    padding: 0;
    cursor: pointer;

    svg {
      margin: auto;
    }
  }

  .submit-button {
    width: 100%;
    border-radius: 50vh;
    padding: 1em 1.5em;
    font-size: 18px;
    margin-top: 48px;
    margin-bottom: 48px;
    color: #fff;
    background: ${props => props.theme.colors.blue1};
    border: none;
    font-weight: bold;
    transition: all 0.15s ease;
    -webkit-appearance: none;
    cursor: pointer;
    box-shadow:
      0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    display: inline-block;
    text-decoration: none;

    &.login {
      margin: 16px 0 0;
    }

    &:hover {
      background: ${props => props.theme.colors.blue1Hover};
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;

      &:hover {
        background: ${props => props.theme.colors.blue1};
      }
    }
  }

  .modal-switch {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 32px;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;

    button {
      font-weight: bold;
    }
  }
`;

export function ResetPassword(): JSX.Element {
  const { recoveryCode } = useParams();
  const locale = useSelector((state: any) => state.common.locale);
  const { loading, success, error, forgotPasswordChange } = useForgotPasswordChange();
  const [showPassword, setShowPassword] = useState(false);
  const intl = useIntl();
  const [errMsg, setErrMsg] = useState<string | null>(null);

  useEffect(() => {
    if (!error) {
      setErrMsg(null);
      return;
    }

    setErrMsg(intl.messages[`error.${error.id}`].toString() || error.message);
  }, [error]);

  const resetPasswordSchema = Yup.object().shape({
    password: isRegulatedLocale(locale)
      ? Yup.string()
          .min(8, intl.formatMessage({ id: 'reset.password.invalid.length' }))
          .matches(/(?=.*[A-Z])/, intl.formatMessage({ id: 'reset.password.invalid.capital' }))
          .matches(/^(?=.*[a-z])/, intl.formatMessage({ id: 'reset.password.invalid.lower' }))
          .matches(/(?=.*[0-9])/, intl.formatMessage({ id: 'reset.password.invalid.number' }))
          .required(intl.formatMessage({ id: 'inputs.password.required' }))
      : Yup.string()
          .min(6, intl.formatMessage({ id: 'reset.password.invalid' }))
          .required(intl.formatMessage({ id: 'inputs.password.required' })),
  });

  const showPasswordToggle = () => {
    showPassword ? setShowPassword(false) : setShowPassword(true);
  };

  const submitHandler = (values: { password: string }) => {
    if (!recoveryCode) {
      return;
    }

    forgotPasswordChange(recoveryCode, values.password);
  };

  return (
    <ResetPasswordPage>
      <div className="image-wrapper">
        <img
          src={`${CDN_BASE}/cdn-cgi/image/fit=cover,width=1440,height=900,gravity=0x1,format=auto,dpr=2${CDN_IMAGES_COMMON_PATH}/luckydays-beach2.jpg`}
          alt="Lucky the cat playing on the beach"
        />
      </div>
      <StyledResetPasswordModal>
        <div className="text-center">
          <LuckyDaysLogo />
        </div>
        {!success ? (
          <div className="text">
            <>
              <p className="intro-text">
                {intl.formatMessage({ id: 'reset.header' })}{' '}
                {intl.formatMessage({ id: 'reset.subHeader' })}
              </p>
              {error && <PromptMessage type="error" message={errMsg} />}
              <Formik
                initialValues={{
                  password: '',
                }}
                validationSchema={resetPasswordSchema}
                onSubmit={values => submitHandler(values)}
              >
                {({ isValid }) => (
                  <Form>
                    <Field name="password">
                      {({ field }: FieldProps) => (
                        <label htmlFor="password">
                          {intl.formatMessage({
                            id: 'inputs.password',
                          })}
                          <input
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            placeholder={intl.formatMessage({
                              id: 'reset.password.placeholder',
                            })}
                            {...field}
                          />
                          <button
                            type="button"
                            className="showPasswordToggle tooltip-container"
                            onClick={showPasswordToggle}
                          >
                            {showPassword ? (
                              <EyeSlashIcon className="h-5 w-5" />
                            ) : (
                              <EyeIcon className="h-5 w-5" />
                            )}
                            <Tooltip
                              width="160px"
                              text={
                                showPassword
                                  ? intl.formatMessage({
                                      id: 'inputs.password.hide',
                                    })
                                  : intl.formatMessage({
                                      id: 'inputs.password.show',
                                    })
                              }
                              position="top-right"
                            />
                          </button>
                          <ErrorMessage name="password">
                            {msg => <ValidationMessage message={msg} />}
                          </ErrorMessage>
                        </label>
                      )}
                    </Field>
                    <button className="submit-button" disabled={!isValid} type="submit">
                      {!loading ? (
                        intl.formatMessage({ id: 'reset.submitButton' })
                      ) : (
                        <Spinner width={18} height={18} borderwidth={3} color="white" />
                      )}
                    </button>
                  </Form>
                )}
              </Formik>
              <p className="modal-switch">
                {intl.formatMessage({ id: 'forgot.rememberedText' })}{' '}
                <Anchor to="..?modal=login" href="">
                  {intl.formatMessage({ id: 'forgot.rememberedLink' })}
                </Anchor>
              </p>
            </>
          </div>
        ) : (
          <div className="text success">
            <>
              <div className="success-text">
                <h2>
                  {intl.formatMessage({
                    id: 'reset.success.header',
                  })}
                </h2>
                <p className="intro-text">
                  {intl.formatMessage({
                    id: 'reset.success.text',
                  })}
                </p>
              </div>
              <Link className="submit-button login" type="button" to="..?modal=login">
                {intl.formatMessage({
                  id: 'reset.success.buttonText',
                })}
              </Link>
            </>
          </div>
        )}
      </StyledResetPasswordModal>
    </ResetPasswordPage>
  );
}
