import React, { ReactNode } from 'react';

const Double = ({ children, ...props }: { children: ReactNode[] }) => (
  <div
    className="flex flex-col w-[43%] md:w-full md:max-w-[240px] max-h-[600px] flex-shrink-0 space-y-3"
    {...props}
  >
    <div className="h-1/2">{children[0]}</div>
    <div className="h-1/2">{children[1]}</div>
  </div>
);

export default Double;
