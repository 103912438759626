import React, { FocusEvent, memo, useEffect, useMemo, useState } from 'react';

import { Combobox } from '@headlessui/react';
import classNames from 'classnames';
import { useField } from 'formik';

import { FieldError } from '../field';
import { ChevronUpDownIcon } from '@heroicons/react/24/solid';

export interface SearchDropdownValue {
  endIcon?: JSX.Element;
  label: string;
  value: string;
}

export interface SearchDropdownProps {
  name: string;
  label: string;
  onChange?: (newValue: string) => void;
  onFocus?: () => void;
  options: Array<SearchDropdownValue>;
  onValidationError?: (error: string) => void;
  inputClassName?: string;
  labelClassName?: string;
  optionsClassName?: string;
  selectorIconClassName?: string;
}

const SearchDropdownField = memo(
  function SearchDropdown({
    name,
    label,
    onChange,
    onFocus,
    options,
    onValidationError,
    inputClassName,
    labelClassName,
    optionsClassName,
    selectorIconClassName,
  }: SearchDropdownProps) {
    const [field, meta, helpers] = useField<string>(name);
    const [searchTerm, setSearchTerm] = useState('');
    const [focused, setFocused] = useState(false);
    const isValidationError = meta.touched && meta.error;

    const filteredOptions = useMemo(
      () =>
        searchTerm
          ? options.filter(option =>
              option.label.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()),
            )
          : options,
      [options, searchTerm],
    );

    const displayValueFunction = (value: string) =>
      options.find(element => element.value === value)?.label ?? searchTerm ?? '';

    const endIcon = useMemo(
      () =>
        options.find(
          option => option.value.toLocaleUpperCase() === field.value?.toLocaleUpperCase(),
        )?.endIcon,
      [field.value, options],
    );

    const handleOnFocus = () => {
      setFocused(true);
      onFocus?.();
    };

    const handleOnChange = (newValue: string) => {
      helpers.setValue(newValue);
      onChange?.(newValue);
      setFocused(false);
    };

    useEffect(() => {
      if (isValidationError) {
        onValidationError?.(meta.error!);
      }
    }, [isValidationError]);

    return (
      <Combobox value={field.value} onChange={handleOnChange}>
        {({ open }) => (
          <div data-cy={`e2e_${label}Select`} className="relative">
            {field.value && endIcon && <div className="absolute right-12 top-4">{endIcon}</div>}
            <label
              className={classNames(
                `absolute left-2 top-[2px] px-2 transition-transform text-base duration-150 pointer-events-none
            origin-top-left flex items-center gap-4 peer-focus:translate-y-1/3 peer-focus:scale-75 peer-focus:font-semibold`,
                field.value?.length > 0 || searchTerm.length > 0 || focused
                  ? 'translate-y-1/3 scale-75 font-semibold'
                  : 'translate-y-[60%]',
                labelClassName,
              )}
            >
              {label}
            </label>
            <FieldError error={meta.error} show={Boolean(isValidationError)} />
            {
              // Button inside Input is a temporary fix for the dropdown options menu not opening on input focus
            }
            <Combobox.Button className="w-full">
              <Combobox.Input
                {...field}
                onFocus={handleOnFocus}
                onBlur={(event: FocusEvent) => {
                  setFocused(false);
                  field.onBlur(event);
                }}
                className={classNames(
                  `w-full px-3.5 pr-6 pt-6 bg-white/20 text-base text-[15px] disabled:cursor-not-allowed disabled:text-gray-400
                rounded-md border-0 focus:outline-0 focus:ring-0 autofill:!bg-none peer`,
                  { '!border-red-500 !border-1': meta.error && meta.touched },
                  inputClassName,
                )}
                displayValue={displayValueFunction}
                onChange={change => {
                  setSearchTerm(change.target.value);
                  if (!change.target.value) helpers.setValue('');
                }}
                autoComplete="off"
              />
              <ChevronUpDownIcon
                className={classNames(
                  'absolute right-0 top-5 pr-2 z-10 h-[20px] max-w-min text-white',
                  selectorIconClassName,
                )}
              />
            </Combobox.Button>
            {open && (
              <Combobox.Options
                className={classNames(
                  'absolute bg-white overflow-auto no-scrollbar text-black w-full mt-1 rounded-md z-50 max-h-52 flex flex-col space-y-1 px-0',
                  optionsClassName,
                )}
              >
                {filteredOptions.length > 0 &&
                  filteredOptions.map((option, index) => (
                    <Combobox.Option
                      className="m-0 p-0 cursor-pointer text-left flex justify-between"
                      key={option.label + index}
                      onFocus={onFocus}
                      value={option.value}
                    >
                      {({ active }) => (
                        <div
                          className={classNames(
                            'px-3.5 py-2 m-0 rounded-md flex w-full justify-between items-center',
                            active && 'bg-slate-100',
                          )}
                        >
                          {option.label}
                          {option.endIcon}
                        </div>
                      )}
                    </Combobox.Option>
                  ))}
              </Combobox.Options>
            )}
          </div>
        )}
      </Combobox>
    );
  },
  // Current conditionals for re-rendering. Add new conditionals to list if necessary for future development
  ({ options: prevOptions }, { options: nextOptions }) => prevOptions === nextOptions,
);
export default SearchDropdownField;
