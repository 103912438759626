import React from 'react';
import { FieldProps } from '../../../form/field/Field';
import { Form, PasswordField } from '../../../form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { isRegulatedLocale } from '@/shared/utility';
import { selectLocale } from '@/store/selectors/commonSelectors';
import ApiService from '../../../../shared/apiService';
import { useApiV2Request } from '@/hooks/useApiV2Request';
import { Button } from '../../../buttons/Button';
import classNames from 'classnames';
import { ApiV2Error } from '@/shared/errorHandler';

interface PasswordChangeProps extends Omit<FieldProps, 'label' | 'name'> {}

const PasswordChangeForm = (props: PasswordChangeProps) => {
  const intl = useIntl();
  const locale = useSelector(selectLocale);
  const { request, loading, success, error } = useApiV2Request();

  function getStatusMessageString(success: boolean, error: ApiV2Error | null) {
    if (success) {
      return intl.formatMessage({ id: 'reset.success.header' });
    } else if (error?.errorId === 48) {
      return intl.formatMessage({ id: 'error.48' });
    } else if (error) {
      return `${intl.formatMessage({ id: 'error.support' })} [${error.errorId}]`;
    } else return '';
  }

  const changePasswordSchema = Yup.object().shape({
    current: Yup.string()
      .min(6)
      .required(intl.formatMessage({ id: 'input.error.required' })),
    new: isRegulatedLocale(locale)
      ? Yup.string()
          .min(8, intl.formatMessage({ id: 'inputs.password.invalid.length' }))
          .matches(/(?=.*[A-Z])/, intl.formatMessage({ id: 'inputs.password.invalid.capital' }))
          .matches(/^(?=.*[a-z])/, intl.formatMessage({ id: 'inputs.password.invalid.lower' }))
          .matches(/(?=.*[0-9])/, intl.formatMessage({ id: 'inputs.password.invalid.number' }))
          .notOneOf(
            [Yup.ref('current'), null],
            intl.formatMessage({ id: 'inputs.password.new.notSame' }),
          )
      : Yup.string()
          .min(6, intl.formatMessage({ id: 'inputs.password.invalid' }))
          .notOneOf(
            [Yup.ref('current'), null],
            intl.formatMessage({ id: 'inputs.password.new.notSame' }),
          )
          .test(
            'no-whitespace',
            intl.formatMessage({ id: 'fastReg.password.whitespaces.invalid' }),
            value => !(value && /\s/.test(value)),
          )
          .required(intl.formatMessage({ id: 'input.error.required' })),
    confirm: Yup.string()
      .oneOf([Yup.ref('new'), null], intl.formatMessage({ id: 'inputs.password.confirm.notSame' }))
      .min(6)
      .required(intl.formatMessage({ id: 'input.error.required' })),
  });

  return (
    <Form
      className="w-full flex flex-col space-y-5"
      initialValues={{ new: '', current: '', confirm: '' }}
      validationSchema={changePasswordSchema}
      validateOnChange
      validateOnMount
      validateOnBlur
      onSubmit={(values, helpers) =>
        request(ApiService.changePassword, { current: values.current, new: values.new }, () => {
          helpers.resetForm();
        })
      }
    >
      {({ isValid, setValues, values }) => {
        return (
          <>
            <PasswordField
              name="current"
              id="current_id"
              label={intl.formatMessage({ id: 'inputs.password.placeholder' })}
              {...props}
            />
            <PasswordField
              name="new"
              id="new_id"
              onBlurCapture={() => setValues({ ...values, new: values.new.trim() })}
              label={intl.formatMessage({ id: 'inputs.password.new' })}
              {...props}
            />
            <PasswordField
              name="confirm"
              id="confirm_id"
              onBlurCapture={() => setValues({ ...values, confirm: values.confirm.trim() })}
              label={intl.formatMessage({ id: 'inputs.password.confirm' })}
              {...props}
            />
            <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row justify-between mt-3 items-center">
              <div
                className={classNames('text-sm w-3/5', success ? 'text-green-500' : 'text-red-500')}
              >
                {getStatusMessageString(success, error)}
              </div>

              <div className="w-full sm:w-auto">
                <Button
                  disabled={!isValid}
                  type="submit"
                  text={intl.formatMessage({ id: 'settings.details.password.submitButton' })}
                  isLoading={loading}
                />
              </div>
            </div>
          </>
        );
      }}
    </Form>
  );
};

export default PasswordChangeForm;
