import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Limit } from './types';
import {
  DepositLimit,
  LossLimit,
  PanicTimeout,
  RealityCheck,
  SelfExclusion,
  SessionLimits,
  TimeOut,
} from './';
import { envIsCanada, envIsSweden } from '../../../../constants/constants';
import { Select, SelectItem } from '../../../../components/Select';
import { useSelector } from 'react-redux';
import { selectLocale } from '../../../../store/selectors/commonSelectors';

const availableOptions = (locale: string) => {
  const allOptions: Record<Limit, { translationId: string }> = {
    [Limit.Deposit]: {
      translationId: 'depositLimit.header',
    },
    [Limit.Session]: {
      translationId: 'sessionLimit.header',
    },
    [Limit.Loss]: {
      translationId: 'lossLimit.header',
    },
    [Limit.RealityCheck]: {
      translationId: 'realityCheck.header',
    },
    [Limit.TimeOut]: {
      translationId: 'responsibleGaming.takeABreak.header',
    },
    [Limit.Panic]: {
      translationId: 'playerLimits.panic',
    },
    [Limit.SelfExclusion]: {
      translationId: 'playerLimits.selfExclusion',
    },
  };

  const mapOptions = (options: Limit[]): SelectItem[] => {
    return options.reduce((a: SelectItem[], i: Limit) => {
      const option = allOptions[i];
      a.push({ id: i.toString(), ...option });
      return a;
    }, []);
  };

  if (envIsSweden()) {
    return mapOptions([Limit.Deposit, Limit.Session, Limit.Loss, Limit.RealityCheck]);
  }

  if (envIsCanada()) {
    return mapOptions([Limit.Deposit, Limit.Loss, Limit.Session]);
  }

  if (['fi', 'fi-en'].includes(locale)) {
    return mapOptions([
      Limit.Deposit,
      Limit.Session,
      Limit.Loss,
      Limit.RealityCheck,
      Limit.TimeOut,
    ]);
  }

  return mapOptions([Limit.Deposit, Limit.Session]);
};

const PlayerLimits = ({
  showLimitsInitially = false,
  initialLimit = Limit.Deposit,
}: {
  showLimitsInitially?: boolean;
  initialLimit?: Limit;
}): JSX.Element => {
  const [showLimits, setShowLimits] = useState(showLimitsInitially);
  const intl = useIntl();
  const locale = useSelector(selectLocale);
  const [options] = useState(availableOptions(locale));
  const [selectedOption, setSelectedOption] = useState<SelectItem>(options[0]);

  useEffect(() => {
    const findOption = options.find(o => o.id === initialLimit.toString());
    if (initialLimit && findOption) {
      setSelectedOption(findOption);
    }
  }, [initialLimit]);

  const selectedSection = (id: string): JSX.Element => {
    switch (parseInt(id) as Limit) {
      case Limit.Deposit:
        return <DepositLimit />;
      case Limit.Session:
        return <SessionLimits />;
      case Limit.Loss:
        return <LossLimit />;
      case Limit.RealityCheck:
        return <RealityCheck />;
      case Limit.TimeOut:
        return <TimeOut />;
      case Limit.SelfExclusion:
        return <SelfExclusion />;
      case Limit.Panic:
        return <PanicTimeout />;
    }
  };

  return (
    <section className="player-limits space-y-4">
      <h2 className="mb-4">{intl.formatMessage({ id: 'playerLimits.header' })}</h2>
      <p>{intl.formatMessage({ id: 'playerLimits.text' })}</p>
      {!showLimits && (
        <div className="button-wrapper">
          <button
            type="button"
            className="mx-2 mt-4 mb-4 rounded-full bg-cyan-500 py-4 px-6 font-bold leading-[22px] text-white shadow-md transition-all hover:bg-cyan-600 hover:shadow-lg"
            onClick={() => setShowLimits(true)}
          >
            {intl.formatMessage({ id: 'playerLimits.button' })}
          </button>
        </div>
      )}
      {showLimits && (
        <>
          <div className="mx-auto w-full max-w-xs pt-4">
            <Select
              items={options}
              selectedItem={selectedOption}
              onChangeHandler={setSelectedOption}
              size="md"
            />
          </div>
          <div className="pt-4">{selectedSection(selectedOption.id)}</div>
        </>
      )}
    </section>
  );
};

export default PlayerLimits;
