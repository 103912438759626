import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { LinearGradient } from '../gradient';
import { ChevronLeftIcon, ChevronRightIcon, MinusIcon } from '@heroicons/react/20/solid';

interface CarouselProps {
  children: ReactNode[];
}

function Carousel({ children }: CarouselProps) {
  const scrollableContainerRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [currentPage, setCurrentPage] = useState(0);

  const handleScroll = useCallback(() => {
    if (!scrollableContainerRef.current) return;
    const scrollLeft = scrollableContainerRef.current?.scrollLeft;
    const containerWidth = containerRef.current?.offsetWidth ?? 1;

    const currentPage = Math.floor(scrollLeft / containerWidth);
    setCurrentPage(currentPage);
  }, [scrollableContainerRef, setCurrentPage]);

  useEffect(() => {
    const container = scrollableContainerRef.current;
    if (container) {
      container.addEventListener('scrollend', handleScroll);
    }
    return () => {
      container?.removeEventListener('scrollend', handleScroll);
    };
  }, [scrollableContainerRef, handleScroll]);

  return (
    <div className="flex flex-col justify-center relative w-full h-full" ref={containerRef}>
      <div
        ref={scrollableContainerRef}
        className="relative w-full flex flex-nowrap overflow-x-scroll no-scrollbar rounded-t-xl snap-x scroll-smooth"
      >
        {children.map(child => (
          <div className="flex-shrink-0 snap-start w-full h-full">{child}</div>
        ))}
      </div>
      <div className="w-full relative rounded-b-xl overflow-hidden">
        <LinearGradient
          from="rgba(255, 162, 10, 0.6)"
          to="rgba(254, 118, 200)"
          direction="bottom right"
          transitionPoint="46%"
        />
        <div className="bg-black/40 relative w-full h-full flex justify-between py-2 rounded-b-xl">
          <div
            className="w-[20%] flex items-center justify-center border-r-[1.5px] border-white/40 transition-all active:-translate-x-1 cursor-pointer"
            onClick={() => {
              const leftScroll = scrollableContainerRef.current?.scrollLeft;
              const containerWidth = containerRef.current?.offsetWidth ?? 1;
              const scrollDistance =
                leftScroll === 0 ? containerWidth * children.length : -containerWidth;
              scrollableContainerRef.current?.scrollBy(scrollDistance, 0);
            }}
          >
            <ChevronLeftIcon width={25} height={25} color="white" />
          </div>
          <div className="flex items-center justify-center border-white/40">
            {children.map((_game, index) => (
              <MinusIcon
                key={`total-pages-${index}`}
                width={15}
                height={15}
                color={index === currentPage ? 'white' : 'gray'}
              />
            ))}
          </div>
          <div
            className="w-[20%] flex items-center justify-center border-l-[1.5px] border-white/40 transition-all active:translate-x-1 cursor-pointer"
            onClick={() => {
              const leftScroll = scrollableContainerRef.current?.scrollLeft ?? 0;
              const scrollWidth = scrollableContainerRef.current?.scrollWidth ?? 0;
              const containerWidth = containerRef.current?.offsetWidth ?? 1;
              const result = scrollWidth - leftScroll - containerWidth;
              const scrollDistance =
                result <= 0 ? -containerWidth * children.length : containerWidth;

              scrollableContainerRef.current?.scrollBy(scrollDistance, 0);
            }}
          >
            <ChevronRightIcon width={25} height={25} color="white" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Carousel;
