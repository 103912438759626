import Field, { FieldProps } from '../field/Field';
import React from 'react';

interface NumericFieldProps extends Omit<FieldProps, 'type' | 'onKeyPress' | 'inputMode'> {}

const NumericField = (props: NumericFieldProps) => (
  <Field
    type="text"
    inputMode="numeric"
    onKeyPress={event => {
      const regex = /^[0-9]+$/;

      if (!regex.test(event.key)) {
        event.preventDefault();
      }
    }}
    {...props}
  />
);

export default NumericField;
