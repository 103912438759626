import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { LD_SHOW_STATS_AND_LIMITS } from '../../constants/constants';
import { selectLastLogin } from '../../store/selectors/loginHistorySelectors';
import { selectUserPlayStatsAndLimitsPlayStats } from '../../store/selectors/userPlayStatsAndLimitsSelectors';
import { getLimitDuration } from '../../utils/utils';
import CloseButton from '../UI/Buttons/CloseButton';
import Currency from '../UI/Currency';
import { selectActiveDepositLimits } from '../../store/selectors/limitsSelectors';

export function StatsAndLimitsModal({ closeModal }: { closeModal: () => void }): JSX.Element {
  const lastLogin = useSelector(selectLastLogin);
  const limits = useSelector(selectActiveDepositLimits);
  const playStats = useSelector(selectUserPlayStatsAndLimitsPlayStats);
  const intl = useIntl();

  const closeHandler = () => {
    localStorage.removeItem(LD_SHOW_STATS_AND_LIMITS);
    closeModal();
  };

  return (
    <div className="relative w-full max-w-sm space-y-4 rounded-2xl bg-white p-8 text-left">
      <CloseButton right onClick={() => closeHandler()} />
      <p>{intl.formatMessage({ id: 'statsAndLimits.modal.summary' })}</p>
      {lastLogin && (
        <div>
          <h3 className="mt-4 text-base">
            {intl.formatMessage({ id: 'statsAndLimits.modal.lastLogin' })}
          </h3>
          <div className="mt-2">{lastLogin}</div>
        </div>
      )}
      {limits && limits.length > 0 && (
        <div>
          <h3 className="mt-4 text-base">
            <p>{intl.formatMessage({ id: 'statsAndLimits.modal.activeDepositLimits' })}</p>
          </h3>
          <div className="mt-2">
            {limits.map(limit => (
              <div key={limit.Id} className="flex max-w-[240px] justify-between">
                <span className="capitalize">{getLimitDuration(limit.Duration, intl)}: </span>
                <span className="font-bold">
                  <Currency currency="SEK" amount={limit.Amount} />
                </span>
              </div>
            ))}
          </div>
        </div>
      )}
      {playStats && (
        <div>
          <h3 className="mt-4 text-base">
            {intl.formatMessage({ id: 'statsAndLimits.modal.yourStats' })}
          </h3>
          <div className="mt-2">
            <div className="flex max-w-[240px] justify-between">
              <span>{intl.formatMessage({ id: 'statsAndLimits.modal.totalProfit' })}</span>
              <span className="font-bold">
                <Currency currency="SEK" amount={playStats.NetGain} />
              </span>
            </div>
            <div className="flex max-w-[240px] justify-between">
              <span>{intl.formatMessage({ id: 'statsAndLimits.modal.totalLoss' })}</span>
              <span className="font-bold">
                <Currency currency="SEK" amount={playStats.NetLoss} />
              </span>
            </div>
          </div>
        </div>
      )}
      <div className="flex justify-end pt-4">
        <button
          type="button"
          className="rounded-full bg-cyan-500 px-5 py-2 font-bold text-white shadow-md transition-colors hover:bg-cyan-600"
          onClick={() => closeHandler()}
        >
          {intl.formatMessage({ id: 'statsAndLimits.modal.confirmButton' })}
        </button>
      </div>
    </div>
  );
}
