import React, { lazy, Suspense } from 'react';

import { useSelector } from 'react-redux';
import { envIsMexico, IS_HALLOWEEN } from '@/constants/constants';
import {
  NewGames,
  WinterGames,
  SlotsGames,
  JackpotsGames,
  TableGames,
  AllGames,
  HalloweenGames,
  LastPlayedGames,
  RecommendedGames,
  LiveCasinoGames,
} from '@/pages/games/lobby/lobby-sections';
import { selectIsAuthenticated } from '@/store/selectors/authSelectors';
import { useXmas } from '@/hooks/useXmas';
import { selectLocale } from '@/store/selectors/commonSelectors';
import { isLiveCasinoEnabled } from '@/utils/logic.utils';

const GamesLobbyMexico = lazy(() => import('./GamesLobbyMexico'));

function GamesLobby(): JSX.Element {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isXmas = useXmas();
  const locale = useSelector(selectLocale);

  return (
    <Suspense fallback={<div className="w-full min-h-[100vh]" />}>
      {envIsMexico() && <GamesLobbyMexico />}
      {!envIsMexico() && (
        <div
          id="e2e_sections_container"
          className="py-16 flex flex-col gap-16 max-w-[1400px] mx-auto"
        >
          {isAuthenticated && <LastPlayedGames />}
          <RecommendedGames />
          {IS_HALLOWEEN && <HalloweenGames />}
          {isXmas && <WinterGames />}
          <NewGames />
          {isLiveCasinoEnabled(locale, isAuthenticated) && <LiveCasinoGames />}
          <SlotsGames />
          <JackpotsGames />
          <TableGames />
          <AllGames />
        </div>
      )}
    </Suspense>
  );
}

export default GamesLobby;
