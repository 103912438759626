import React, { useState } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import Spinner from '../UI/Spinner';
import { ConsentsModalTerms } from '../ConsentsModalTerms';
import { useAbsoluteNavigate } from '../../hooks/useAbsoluteNavigate';
import { usePrivacyPolicyLoader } from '../../hooks/usePrivacyPolicyLoader';
import { useTermsAndConditionsLoader } from '../../hooks/useTermsAndConditionsLoader';
import {
  selectIsPPConsented,
  selectIsTCConsented,
  selectMandatoryConsents,
} from '../../store/selectors/consentsSelectors';
import { ConsentView } from '../../models/enum/consent-view.enum';
import { useApiV2Request } from '../../hooks/useApiV2Request';
import ApiService from '../../shared/apiService';
import { getConsents } from '../../store/actions/consentsActions';

export function ConsentsModal({ closeModal }: { closeModal: () => void }): JSX.Element {
  const [view, setView] = useState<ConsentView>(ConsentView.Consent);
  const absoluteNavigate = useAbsoluteNavigate();
  const termsAndConditions = useTermsAndConditionsLoader();
  const privacyPolicy = usePrivacyPolicyLoader();
  const intl = useIntl();
  const { request: saveConsents, loading } = useApiV2Request();
  const isTCConsented = useSelector(selectIsTCConsented);
  const isPPConsented = useSelector(selectIsPPConsented);
  const consents = useSelector(selectMandatoryConsents);
  const dispatch = useDispatch();

  const handleSaveConsents = () => {
    const approvedConsents = consents.map(consent => {
      return {
        ConsentId: consent.ConsentId,
        Version: consent.Version,
        Consented: true,
      };
    });
    saveConsents(ApiService.saveConsents, approvedConsents, () => {
      dispatch(getConsents());
      closeModal();
    });
  };

  return (
    <div className="flex min-h-[400px] rounded-2xl bg-white text-left">
      {view === ConsentView.Consent && (
        <div className="flex flex-col justify-between space-y-4 p-8 max-w-[420px]">
          <div>
            <h1 className="mb-4 text-xl">{intl.formatMessage({ id: 'consentModal.header' })}</h1>
            <p>{intl.formatMessage({ id: 'consentModal.text' })}</p>
            <ul className="mt-4 list-inside list-disc rounded-lg bg-gray-100 p-4 text-sm">
              {!isTCConsented && (
                <li className="mb-0">
                  <button
                    type="button"
                    className="underline hover:no-underline text-left max-w-[90%]"
                    onClick={() => setView(ConsentView.Terms)}
                  >
                    {intl.formatMessage({ id: 'consentModal.termsAndConditions' })}
                  </button>
                </li>
              )}
              {!isPPConsented && (
                <li className="mb-0">
                  <button
                    type="button"
                    className="underline hover:no-underline text-left max-w-[90%]"
                    onClick={() => setView(ConsentView.Privacy)}
                  >
                    {intl.formatMessage({ id: 'consentModal.privacyAndCookieNotice' })}
                  </button>
                </li>
              )}
            </ul>
          </div>
          <div className="mt-6 space-y-4">
            <button
              className="h-12 w-full rounded-full bg-cyan-500 px-4 text-center font-bold text-white shadow-lg transition hover:bg-cyan-600 hover:shadow-xl"
              type="button"
              onClick={() => handleSaveConsents()}
            >
              {loading ? (
                <Spinner width={20} height={20} borderwidth={4} color="white" />
              ) : (
                intl.formatMessage({ id: 'consentModal.approve' })
              )}
            </button>
            <button
              className="h-12 w-full rounded-full bg-gray-200 px-4 text-center font-bold transition hover:bg-gray-300"
              type="button"
              onClick={() => {
                closeModal();
                absoluteNavigate('/logout');
              }}
            >
              {intl.formatMessage({ id: 'consentModal.logout' })}
            </button>
          </div>
        </div>
      )}

      {view === ConsentView.Terms && (
        <ConsentsModalTerms
          content={termsAndConditions}
          header={intl.formatMessage({ id: 'consentModal.termsAndConditions' })}
          callback={() => setView(ConsentView.Consent)}
        />
      )}

      {view === ConsentView.Privacy && (
        <ConsentsModalTerms
          content={privacyPolicy}
          header={intl.formatMessage({ id: 'consentModal.privacyAndCookieNotice' })}
          callback={() => setView(ConsentView.Consent)}
        />
      )}
    </div>
  );
}
