import React, {
  Children,
  cloneElement,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import classNames from 'classnames';
import { ScrollButton } from '@/components/shared/streamline/StreamlineControls';
import { isEmpty } from '@/shared/utility';

interface InfiniteScrollStreamlineProps {
  spacing?: 0 | 16 | 32 | 48;
  children: ReactElement[];
  autoplay?: boolean;
  stretch?: boolean;
}

/* SPECS:
This component is complex and has lots of moving parts. These comments will help you navigate through the storm.
What this component does is that is takes Array of children, clones it two times and puts it in front and in back of the original array.
When user scrolls/clicks through the banners, if they go outside of the original array (into the clones array),
on scroll-end it will instantly scroll back to the same place in the original array, showing original child instead of clone.
That instant switch is what gives the effect of infinite loop.
Stretch - this prop makes it so that Streamline has no paddings, so it takes full height/width of the container
Autoplay - automatically switches to the next banner every 5s
Spacing - param which will be used in the future

TODO:
- Add support for itemsPerPage > 1 - in theory it should work out of the box, without any spacing between children
- Add support for spacing between children
- Add support for children of different widths
- Improve re-rendering on having children with react navigation
 */

const InfiniteScrollStreamline = ({
  children,
  spacing = 0,
  autoplay = false,
  stretch = false,
}: InfiniteScrollStreamlineProps) => {
  // REFS
  const scrollableContainerRef = React.useRef<HTMLDivElement>(null);

  // BEHAVIOUR STATES
  const [itemsPerPage, setItemsPerPage] = useState(1);
  // This stops user from clicking on the arrows while scroll is not finished
  const [scrollClickLocked, setScrollClickLocked] = useState(false);
  // Widths of each children, indexes match the children array
  // Right now childrenWidths[0] is used across the code, but it's because all children currently have the same width
  const [childrenWidths, setChildrenWidths] = useState<number[]>([]);
  // IntervalID used to stop the interval on user interaction (stops autoplay when user interacts with component)
  const [intervalId, setIntervalId] = useState<NodeJS.Timer | undefined>(undefined);

  // MEMOIZED CLONES
  const childrenClones = useMemo(
    () =>
      Children.map(children, child =>
        cloneElement(child, { key: child.key + 'infinite-scroll-child' }),
      ),
    [children, itemsPerPage],
  );

  const childrenPreClones = useMemo(
    () =>
      Children.map(children, child =>
        cloneElement(child, { key: child.key + 'infinite-scroll-prechild' }),
      ),
    [children, itemsPerPage],
  );

  // Children + clones
  const presentableChildren = useMemo(
    () => childrenPreClones.concat(children).concat(childrenClones),
    [children, childrenClones, childrenPreClones],
  );

  // CALLBACKS
  const getChildrenWidths = useCallback(() => {
    const container = scrollableContainerRef.current;

    if (container) {
      const childNodes = Array.from(container.children) as HTMLElement[];
      const widths = childNodes.map(child => child.offsetWidth);
      setChildrenWidths(widths);
    }
  }, []);

  // This function is called when user clicks on the arrows and for autoplay loop
  const handleScrollClick = useCallback(
    (direction: 'left' | 'right', closingIntervalId?: NodeJS.Timer) => {
      // So no more clicking of the arrows while scrollend is not done
      setScrollClickLocked(true);
      const container = scrollableContainerRef.current;
      if (container && !isEmpty(childrenWidths)) {
        const left = direction === 'left';
        const scrollDistance = childrenWidths[0];

        // If there is current autoplay loop going on, stop it and only run user interaction scroll
        if (autoplay && closingIntervalId !== undefined) {
          clearInterval(closingIntervalId);
          setIntervalId(undefined);
        }

        scrollableContainerRef.current?.scrollBy({
          behavior: 'smooth',
          left: left ? -scrollDistance : scrollDistance,
        });
      }
    },
    [autoplay, childrenWidths],
  );

  /*
  This is the most important function. It handles all scrolling and calculates if user is in cloned array.
  If yser is past maximum points it will revert them to the same spot in the original array
   */
  const handleScroll = useCallback((): void => {
    // handle scroll will run before any user interaction, so we can enable user clicks again
    setScrollClickLocked(false);
    const container = scrollableContainerRef.current;
    if (!container || childrenWidths.length === 0) return;

    const scrollPos = container.scrollLeft;

    // If scrolled past original items to the right (end)
    if (scrollPos >= 2 * children.length * childrenWidths[0]) {
      container.scrollLeft = scrollPos - children.length * childrenWidths[0];
    }
    // If scrolled past original items to the left (start)
    else if (scrollPos < children.length * childrenWidths[0]) {
      container.scrollLeft = scrollPos + children.length * childrenWidths[0];
    }
  }, [childrenWidths, itemsPerPage]);

  // This method is to stop autoplay loop for mobile users. When interacting with component this will stop intervals.
  const handleMobileTouch = useCallback(
    (closingIntervalId?: NodeJS.Timer) => {
      if (autoplay && closingIntervalId !== undefined) {
        clearInterval(closingIntervalId);
        setIntervalId(undefined);
      }
    },
    [autoplay],
  );

  // EFFECTS
  // This effect starts the autoplay each time the interval is ended
  useEffect(() => {
    if (autoplay && intervalId === undefined && !isEmpty(childrenWidths)) {
      const newIntervalId = setInterval(() => handleScrollClick('right'), 5000);
      setIntervalId(newIntervalId);
      return () => clearInterval(intervalId);
    }
  }, [autoplay, intervalId, handleScrollClick, childrenWidths, scrollClickLocked]);

  // This effect calculates maximum number of fully visible items per page
  useEffect(() => {
    if (scrollableContainerRef.current && childrenWidths[0]) {
      setItemsPerPage(
        Math.round(scrollableContainerRef.current.clientWidth / (childrenWidths[0] - 30)),
      );
    }
  }, [childrenWidths[0]]);

  // This effect looks for window resizing and calculates children widths
  useEffect(() => {
    window.addEventListener('resize', getChildrenWidths);
    return () => {
      window.removeEventListener('resize', getChildrenWidths);
    };
  }, [getChildrenWidths]);

  // This effect listens to scrollend events and on them runs handleScroll
  useEffect(() => {
    const container = scrollableContainerRef.current;
    if (container) {
      container.addEventListener('scrollend', handleScroll);
    }
    return () => {
      container?.removeEventListener('scrollend', handleScroll);
    };
  }, [handleScroll]);

  // This effect updates childrenWidths field
  useEffect(() => {
    if (scrollableContainerRef.current) {
      getChildrenWidths();
    }
  }, [childrenClones, getChildrenWidths]);

  // This effect is used to always first land on the first child in original array
  useEffect(() => {
    const container = scrollableContainerRef.current;
    if (container) {
      container.scrollLeft = children.length * childrenWidths[0];
    }
  }, [childrenWidths, children]);

  // UI
  return (
    <div className="relative">
      <div className="absolute inset-0 top-[50%]">
        <ScrollButton
          direction="left"
          onClick={() => !scrollClickLocked && handleScrollClick('left', intervalId)}
          show={true}
          stretch={stretch}
        />
        <ScrollButton
          direction="right"
          onClick={() => !scrollClickLocked && handleScrollClick('right', intervalId)}
          show={true}
          stretch={stretch}
        />
      </div>
      <div
        className={classNames('flex overflow-x-scroll no-scrollbar snap-x snap-mandatory', {
          // SNAP MANDATORY - needed for this to work on mobile phones
          'p-2': !stretch,
          'space-x-[0px]': spacing === 0,
          'space-x-[16px]': spacing === 16,
          'space-x-[32px]': spacing === 32,
          'space-x-[48px]': spacing === 48,
        })}
        ref={scrollableContainerRef}
        onTouchStart={() => handleMobileTouch(intervalId)}
      >
        {presentableChildren}
      </div>
    </div>
  );
};

export default InfiniteScrollStreamline;
