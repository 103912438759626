import React from 'react';
import LinearGradient from '../../components/shared/gradient/LinearGradient';
import classNames from 'classnames';
import { AbsoluteLink } from '@/components/AbsoluteLink';
import { gameThumbImgMexico } from '@/utils/image-utils';
import { GameCardVM } from '@/models/vm/game-card-vm.model';

const GameCardMC = ({
  game,
  onCardClick,
  large = false,
  ...props
}: {
  game: GameCardVM;
  large?: boolean;
  onCardClick: (e?: React.MouseEvent<HTMLAnchorElement>) => void;
}) => {
  return (
    <AbsoluteLink
      to={`/games/${game.slug}/${game.gameId}`}
      title={game.name}
      onClick={onCardClick}
      className={classNames(
        'relative rounded-xl border-[1px] w-full h-full overflow-hidden flex flex-col snap-start',
        'hover:scale-[102%] active:scale-[98%] transition-all duration-200 ease-in-out',
      )}
      {...props}
    >
      <LinearGradient
        from="rgba(255, 162, 10, 0.6)"
        to="rgba(254, 118, 200)"
        direction="bottom right"
        transitionPoint="46%"
      />
      <div className="relative z-[1] flex flex-col justify-end h-full">
        <img
          className="w-full h-full object-cover object-center"
          alt={game.name}
          src={gameThumbImgMexico(game.slug, large)}
        />
        <div className="absolute bottom-0 right-0 left-0 bg-black/70 min-h-[40px] pt-1 pb-2 px-2 text-[14px] leading-[15px] xs:leading-[18px] truncate text-center text-white font-extrabold">
          {game.name}
          <div className="text-[11px] leading-[11px] font-light text-white/60">
            {game.shortDesc}
          </div>
        </div>
      </div>
    </AbsoluteLink>
  );
};

export default GameCardMC;
