import React, { InputHTMLAttributes, ReactNode, FocusEvent, useEffect } from 'react';
import { useField } from 'formik';
import { appendDefaultContainerClassNames } from './helpers';
import FieldLabel from './FieldLabel';
import FieldError from './FieldError';
import FieldInput from './FieldInput';
import FieldIcon from './FieldIcon';

export interface FieldEndIcon {
  endIcon?: ReactNode;
  endIconAction?: () => void;
}

export interface FieldProps extends InputHTMLAttributes<HTMLInputElement>, FieldEndIcon {
  name: string;
  label: string;
  labelClassName?: string;
  onValidationError?: (error: string) => void;
}

const Field = ({
  className,
  endIcon,
  endIconAction,
  name,
  label,
  labelClassName,
  onValidationError,
  ...props
}: FieldProps) => {
  const [field, { error, touched }, helpers] = useField(name);
  const isValidationError = touched && error;

  const handleOnBlur = (event: FocusEvent<HTMLInputElement>) => {
    field.onBlur(event);
    props.onBlur?.(event);
    helpers.setValue(event.target.value.trim());
  };

  const handleOnChange = (event: FocusEvent<HTMLInputElement>) => {
    field.onChange(event);
    props.onChange?.(event);
  };

  useEffect(() => {
    if (isValidationError) {
      onValidationError?.(error);
    }
  }, [isValidationError]);

  return (
    <div className="flex flex-col gap-1">
      <div className={appendDefaultContainerClassNames(className, error, touched)}>
        <FieldInput {...field} {...props} onBlur={handleOnBlur} onChange={handleOnChange} />
        <FieldLabel className={labelClassName} value={field.value?.length > 0}>
          {label}
        </FieldLabel>
        <FieldError error={error} show={Boolean(isValidationError)} />
        {endIcon && <FieldIcon icon={endIcon} onClick={endIconAction} />}
      </div>
    </div>
  );
};

export default Field;
