import React from 'react';
import ZendeskContainer, { ZendeskAPI } from 'react-zendesk';
import { isEmpty } from '../shared/utility';
import { useSelector } from 'react-redux';
import { selectLoadZendesk } from '../store/selectors/commonSelectors';

function getWindow(): any {
  return window;
}

export const openZendesk = (user: any, isAuthenticated: boolean): void => {
  const w = getWindow();
  if (w && w.zE) {
    if (!isEmpty(user) && isAuthenticated) {
      w.zE('webWidget', 'identify', {
        name: `${user.FirstName} ${user.LastName}`,
        email: `${user.Email}`,
      });
    }
    w.zE('webWidget', 'show');
    w.zE('webWidget', 'open');
  }
};

export const getZendeskLocale = (locale: string): string => {
  switch (locale) {
    case 'ca-fr':
      return 'fr';
    case 'th':
      return 'th';
    case 'mx':
      return 'es';
    case 'ca':
    case 'row':
    case 'nz':
    case 'za':
    case 'ae':
      return 'en';
    default:
      return locale;
  }
};

const zendeskSettings = {
  contactForm: {
    attachments: false,
  },
  helpCenter: {
    originalArticleButton: false,
  },
  contactOptions: {
    enabled: true,
  },
  navigation: {
    popoutButton: {
      enabled: false,
    },
  },
  chat: {
    departments: {
      enabled: [],
    },
  },
};

export function Zendesk({ locale }: { locale: string }): JSX.Element | null {
  const loadZendesk = useSelector(selectLoadZendesk);

  if (!process.env.REACT_APP_ZENDESK_KEY || !loadZendesk) {
    return null;
  }

  return (
    <ZendeskContainer
      zendeskKey={process.env.REACT_APP_ZENDESK_KEY}
      {...zendeskSettings}
      onLoaded={() => {
        ZendeskAPI('webWidget', 'hide');
        ZendeskAPI('webWidget', 'setLocale', getZendeskLocale(locale));
      }}
    />
  );
}
