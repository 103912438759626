import { UserRgStatsResponseDto } from '../../models/gig/user-rg-stats-response-dto.model';
import {
  GetUserPlayStatsAndLimitsSuccessAction,
  UserPlayStatsAndLimitsActions,
  UserPlayStatsAndLimitsActionType,
} from '../actions/userPlayStatsAndLimitsActions';

export interface UserPlayStatsAndLimitsStateModel {
  stats: UserRgStatsResponseDto | null;
  loading: boolean;
  error: boolean;
}

const initialState: UserPlayStatsAndLimitsStateModel = {
  stats: null,
  loading: false,
  error: false,
};

const getUserPlayStatsAndLimits = (
  state: UserPlayStatsAndLimitsStateModel,
): UserPlayStatsAndLimitsStateModel => ({
  ...state,
  loading: true,
  error: false,
});

const getUserPlayStatsAndLimitsSuccess = (
  state: UserPlayStatsAndLimitsStateModel,
  action: GetUserPlayStatsAndLimitsSuccessAction,
): UserPlayStatsAndLimitsStateModel => ({
  ...state,
  stats: action.stats,
  loading: false,
});

const getUserPlayStatsAndLimitsError = (
  state: UserPlayStatsAndLimitsStateModel,
): UserPlayStatsAndLimitsStateModel => ({
  ...state,
  loading: false,
  error: true,
});

export const userPlayStatsAndLimitsReducer = (
  state = initialState,
  action: UserPlayStatsAndLimitsActions,
): UserPlayStatsAndLimitsStateModel => {
  switch (action.type) {
    case UserPlayStatsAndLimitsActionType.GetUserPlayStatsAndLimits:
      return getUserPlayStatsAndLimits(state);
    case UserPlayStatsAndLimitsActionType.GetUserPlayStatsAndLimitsSuccess:
      return getUserPlayStatsAndLimitsSuccess(state, action);
    case UserPlayStatsAndLimitsActionType.GetUserPlayStatsAndLimitsError:
      return getUserPlayStatsAndLimitsError(state);
    case UserPlayStatsAndLimitsActionType.Reset:
      return initialState;
    default:
      return state;
  }
};
