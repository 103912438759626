import { mapLocaleToIetfBcp47Format } from '../shared/utility';

export const formatCurrency = (
  amount: number,
  locale: string,
  currency: string,
  fractionDigits = 2,
): string => {
  return new Intl.NumberFormat(mapLocaleToIetfBcp47Format(locale), {
    style: 'currency',
    currency,
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(amount);
};
