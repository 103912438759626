import { selectIpData, selectLocale } from '../store/selectors/commonSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { envIsNormal } from '../constants/constants';
import { logException } from '../utils/logger';
import { fetchIpData } from '../utils/ip-data.utils';
import { setIpData } from '../store/actions/commonActions';

export function useIpDataLoader(): void {
  const locale = useSelector(selectLocale);
  const ipData = useSelector(selectIpData);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!envIsNormal()) {
      return;
    }

    // we don't want to refetch the IP Data if country is already set
    if (ipData.country) {
      return;
    }

    // This if statement is needed because locale is initially an empty
    // string which leads to double ipdata request
    if (locale) {
      fetchIpData()
        .then(data => dispatch(setIpData(data)))
        .catch(e => {
          logException(new Error('Ip Data Loader Error'), { error: e });
        });
    }
  }, [locale, ipData]);
}
