import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RestOfWorld } from '../assets/flags/RestOfWorld';
import { CountrySelectorDropdown } from './CountrySelectorDropdown';
import { selectLocale } from '../store/selectors/commonSelectors';
import { BUILD_MODE } from '../constants/constants';
import ReactCountryFlag from 'react-country-flag';

const regions =
  BUILD_MODE === 'canada'
    ? [
        {
          locale: 'ca',
          href: '/',
          flag: <ReactCountryFlag countryCode="CA" />,
          text: 'English (Canada)',
        },
        {
          locale: 'ca-fr',
          href: '/fr',
          flag: <ReactCountryFlag countryCode="CA" />,
          text: 'Français (Canada)',
        },
      ]
    : [
        {
          locale: 'at',
          href: '/at',
          flag: <ReactCountryFlag countryCode="AT" />,
          text: 'Österreich',
        },
        {
          locale: 'ca',
          href: '/ca',
          flag: <ReactCountryFlag countryCode="CA" />,
          text: 'Canada',
        },
        {
          locale: 'ca-fr',
          href: '/ca-fr',
          flag: <ReactCountryFlag countryCode="CA" />,
          text: 'Canada (Français)',
        },
        {
          locale: 'no',
          href: '/no',
          flag: <ReactCountryFlag countryCode="NO" />,
          text: 'Norge',
        },
        {
          locale: 'row',
          href: '/row',
          flag: <RestOfWorld />,
          text: 'Rest of World',
        },
        {
          locale: 'nz',
          href: '/nz',
          flag: <ReactCountryFlag countryCode="NZ" />,
          text: 'New Zealand',
        },
        {
          locale: 'fi',
          href: '/fi',
          flag: <ReactCountryFlag countryCode="FI" />,
          text: 'Suomi',
        },
        {
          locale: 'fi-en',
          href: '/fi-en',
          flag: <ReactCountryFlag countryCode="FI" />,
          text: 'Finland (English)',
        },
        {
          locale: 'za',
          href: '/za',
          flag: <ReactCountryFlag countryCode="ZA" />,
          text: 'South Africa',
        },
        {
          locale: 'th',
          href: '/th',
          flag: <ReactCountryFlag countryCode="TH" />,
          text: 'ประเทศไทย',
        },
        {
          locale: 'ae',
          href: '/ae',
          flag: <ReactCountryFlag countryCode="AE" />,
          text: 'United Arab Emirates',
        },
      ];

export function CountrySelector() {
  const locale = useSelector(selectLocale);
  const [currentCountry, setCurrentCountry] = useState(regions[0]);

  useEffect(() => {
    const currentCountry = regions.find(country => country.locale === locale);
    setCurrentCountry(currentCountry);
  }, [locale]);

  return (
    <div className="w-full md:max-w-[300px]">
      <CountrySelectorDropdown
        header={currentCountry.text}
        headerFlag={currentCountry.flag}
        items={regions}
      />
    </div>
  );
}
