import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce } from 'react-use';
import { closeModal, openModal, updateModalProps } from '../../../../store/actions';
import axios from 'axios';
import { ResponsibleGamingOptions } from '../../../../components/ResponsibleGamingOptions';
import { RadioButton } from '../../../../components/RadioButton';
import Button from '../../../../components/UI/Buttons/Button';

const RealityCheck = () => {
  const intl = useIntl();
  const token = useSelector(state => state.auth.token);
  const [interval, setInterval] = useState({
    value: 999999,
    text: `30 ${intl.formatMessage({ id: 'misc.none' })}`,
  });
  const [currentInterval, setCurrentInterval] = useState(null);
  const dispatch = useDispatch();

  function getRealityCheck() {
    dispatch(updateModalProps({ loading: true }));
    axios
      .post('/api/responsiblegaming/realitycheck/getuserrealitycheck', {
        token,
      })
      .then(response => {
        if (response.data.success) {
          if (response.data.data) {
            setCurrentInterval(response.data.data.Interval);
          }
        }
      })
      .catch(error => {
        console.log(error.message);
      });
  }

  function setRealityCheck() {
    dispatch(updateModalProps({ loading: true }));
    axios
      .post('/api/responsiblegaming/realitycheck/saveuserrealitycheck', {
        token,
        interval: interval.value,
      })
      .then(response => {
        if (response.data.error) {
          dispatch(updateModalProps({ error: response.data.error[0].Message }));
        }
        if (response.data.success) {
          getRealityCheck();
          dispatch(closeModal());
        }
        dispatch(updateModalProps({ loading: false }));
      })
      .catch(error => {
        console.log(error.message);
        dispatch(updateModalProps({ loading: false }));
      });
  }

  function submitHandler() {
    dispatch(
      openModal('confirm', {
        header: intl.formatMessage({ id: 'realityCheck.confirm.header' }),
        text:
          interval.value === 999999
            ? intl.formatMessage({ id: 'realityCheck.confirm.none' })
            : intl.formatMessage({ id: 'realityCheck.confirm.text' }, { timeRange: interval.text }),
        buttonText: intl.formatMessage({ id: 'realityCheck.button' }),
        onSubmit: () => setRealityCheck(),
        loading: false,
        error: false,
      }),
    );
  }

  useEffectOnce(() => {
    getRealityCheck();
  });

  return (
    <div>
      <h2 className="mb-4">{intl.formatMessage({ id: 'realityCheck.header' })}</h2>
      <p>{intl.formatMessage({ id: 'realityCheck.text' })}</p>
      {currentInterval && currentInterval !== 999999 && (
        <p className="mt-4 text-sm">
          <strong>{intl.formatMessage({ id: 'realityCheck.currentLimits' })}: </strong>{' '}
          <span>
            {currentInterval / 60} {intl.formatMessage({ id: 'misc.minutes' })}
          </span>
        </p>
      )}
      <div className="form">
        <ResponsibleGamingOptions options={4}>
          <RadioButton htmlFor="none">
            {intl.formatMessage({ id: 'misc.none' })}
            <input
              type="radio"
              id="none"
              value={999999}
              checked={interval.value === 999999}
              onChange={e =>
                setInterval({
                  value: parseInt(e.target.value),
                  text: intl.formatMessage({ id: 'misc.none' }),
                })
              }
            />
            <span className="checkmark" />
          </RadioButton>
          <RadioButton htmlFor="15min">
            15 {intl.formatMessage({ id: 'misc.minutes' })}
            <input
              type="radio"
              id="15min"
              value={900}
              checked={interval.value === 900}
              onChange={e =>
                setInterval({
                  value: parseInt(e.target.value),
                  text: `15 ${intl.formatMessage({ id: 'misc.minutes' })}`,
                })
              }
            />
            <span className="checkmark" />
          </RadioButton>
          <RadioButton htmlFor="30min">
            30 {intl.formatMessage({ id: 'misc.minutes' })}
            <input
              type="radio"
              id="30min"
              value={1800}
              checked={interval.value === 1800}
              onChange={e =>
                setInterval({
                  value: parseInt(e.target.value),
                  text: `30 ${intl.formatMessage({ id: 'misc.minutes' })}`,
                })
              }
            />
            <span className="checkmark" />
          </RadioButton>
          <RadioButton htmlFor="60min">
            60 {intl.formatMessage({ id: 'misc.minutes' })}
            <input
              type="radio"
              id="60min"
              value={3600}
              checked={interval.value === 3600}
              onChange={e =>
                setInterval({
                  value: parseInt(e.target.value),
                  text: `60 ${intl.formatMessage({ id: 'misc.minutes' })}`,
                })
              }
            />
            <span className="checkmark" />
          </RadioButton>
        </ResponsibleGamingOptions>
        <div className="button-wrapper">
          <Button primary onClick={() => submitHandler()}>
            {intl.formatMessage({ id: 'realityCheck.button' })}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RealityCheck;
