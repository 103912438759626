import { all, AllEffect, call, ForkEffect, put, StrictEffect, takeEvery } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { axiosInstance as axios } from '../../shared/axiosInstance';
import { isAxiosResponse } from '../../utils/type-guards';
import {
  GetUserPlayStatsAndLimitsAction,
  getUserPlayStatsAndLimitsError,
  getUserPlayStatsAndLimitsSuccess,
  UserPlayStatsAndLimitsActionType,
} from '../actions/userPlayStatsAndLimitsActions';

export function* getUserPlayStatsAndLimitsSaga(
  action: GetUserPlayStatsAndLimitsAction,
): Generator<StrictEffect, void, AxiosResponse<any>> {
  try {
    const response = yield call(axios.post, '/api/responsiblegaming/userplaystatsandlimits', {
      token: action.token,
      months: action.months,
    });
    if (isAxiosResponse(response)) {
      yield put(getUserPlayStatsAndLimitsSuccess(response.data));
    } else {
      yield put(getUserPlayStatsAndLimitsError());
    }
  } catch (error) {
    yield put(getUserPlayStatsAndLimitsError());
  }
}

export function* watchUserPlayStatsAndLimits(): Generator<AllEffect<ForkEffect<never>>, void> {
  yield all([
    takeEvery(
      UserPlayStatsAndLimitsActionType.GetUserPlayStatsAndLimits,
      getUserPlayStatsAndLimitsSaga,
    ),
  ]);
}
