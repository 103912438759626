import { Action } from 'redux';
import { WpReferrerContent } from '../../models/wp-referrer-content.model';
import { WpBonusIntro } from '../../models/wp-bonus-intro.model';
import { WpTermsAndConditions } from '../../models/wp-terms-and-conditions.model';
import { WpPrivacyPolicy } from '../../models/wp-privacy-policy.model';

export enum WordpressActionType {
  GetReferrerContent = 'GET_REFERRER_CONTENT',
  GetReferrerContentSuccess = 'GET_REFERRER_CONTENT_SUCCESS',
  GetHeaderMessage = 'GET_HEADER_MESSAGE',
  GetHeaderMessageSuccess = 'GET_HEADER_MESSAGE_SUCCESS',
  GetBonusIntro = 'GET_BONUS_INTRO',
  GetBonusIntroSuccess = 'GET_BONUS_INTRO_SUCCESS',
  GetTermsAndConditions = 'GET_TERMS_AND_CONDITIONS',
  GetTermsAndConditionsSuccess = 'GET_TERMS_AND_CONDITIONS_SUCCESS',
  GetPrivacyPolicy = 'GET_PRIVACY_POLICY',
  GetPrivacyPolicySuccess = 'GET_PRIVACY_POLICY_SUCCESS',
}

export type GetReferrerContentAction = Action<WordpressActionType.GetReferrerContent> & {
  locale: string;
  id: string;
};

export type GetReferrerContentSuccessAction =
  Action<WordpressActionType.GetReferrerContentSuccess> & {
    content: WpReferrerContent;
  };

export type GetHeaderMessageAction = Action<WordpressActionType.GetHeaderMessage> & {
  locale: string;
};

export type GetHeaderMessageSuccessAction = Action<WordpressActionType.GetHeaderMessageSuccess> & {
  message: string;
};

export type GetBonusIntroAction = Action<WordpressActionType.GetBonusIntro> & { locale: string };

export type GetBonusIntroSuccessAction = Action<WordpressActionType.GetBonusIntroSuccess> & {
  content: WpBonusIntro;
};

export type GetTermsAndConditionsAction = Action<WordpressActionType.GetTermsAndConditions> & {
  locale: string;
};

export type GetTermsAndConditionsSuccessAction =
  Action<WordpressActionType.GetTermsAndConditionsSuccess> & {
    terms: WpTermsAndConditions;
    locale: string;
  };

export type GetPrivacyPolicyAction = Action<WordpressActionType.GetPrivacyPolicy> & {
  locale: string;
};

export type GetPrivacyPolicySuccessAction = Action<WordpressActionType.GetPrivacyPolicySuccess> & {
  privacyPolicy: WpPrivacyPolicy;
  locale: string;
};

export type WordpressActions =
  | GetReferrerContentAction
  | GetReferrerContentSuccessAction
  | GetHeaderMessageAction
  | GetHeaderMessageSuccessAction
  | GetBonusIntroAction
  | GetBonusIntroSuccessAction
  | GetTermsAndConditionsAction
  | GetTermsAndConditionsSuccessAction
  | GetPrivacyPolicyAction
  | GetPrivacyPolicySuccessAction;

export const getReferrerContent = (locale: string, id: string): GetReferrerContentAction => ({
  type: WordpressActionType.GetReferrerContent,
  locale,
  id,
});

export const getReferrerContentSuccess = (
  content: WpReferrerContent,
): GetReferrerContentSuccessAction => ({
  type: WordpressActionType.GetReferrerContentSuccess,
  content,
});

export const getHeaderMessage = (locale: string): GetHeaderMessageAction => ({
  type: WordpressActionType.GetHeaderMessage,
  locale,
});

export const getHeaderMessageSuccess = (message: string): GetHeaderMessageSuccessAction => ({
  type: WordpressActionType.GetHeaderMessageSuccess,
  message,
});

export const getBonusIntro = (locale: string): GetBonusIntroAction => ({
  type: WordpressActionType.GetBonusIntro,
  locale,
});

export const getBonusIntroSuccess = (content: WpBonusIntro): GetBonusIntroSuccessAction => ({
  type: WordpressActionType.GetBonusIntroSuccess,
  content,
});

export const getTermsAndConditions = (locale: string): GetTermsAndConditionsAction => ({
  type: WordpressActionType.GetTermsAndConditions,
  locale,
});

export const getTermsAndConditionsSuccess = (
  terms: WpTermsAndConditions,
  locale: string,
): GetTermsAndConditionsSuccessAction => ({
  type: WordpressActionType.GetTermsAndConditionsSuccess,
  terms,
  locale,
});

export const getPrivacyPolicy = (locale: string): GetPrivacyPolicyAction => ({
  type: WordpressActionType.GetPrivacyPolicy,
  locale,
});

export const getPrivacyPolicySuccess = (
  privacyPolicy: WpPrivacyPolicy,
  locale: string,
): GetPrivacyPolicySuccessAction => ({
  type: WordpressActionType.GetPrivacyPolicySuccess,
  privacyPolicy,
  locale,
});
