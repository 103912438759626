import { useDispatch, useSelector } from 'react-redux';
import { selectLocale } from '../store/selectors/commonSelectors';
import { useEffect } from 'react';
import {
  getBonusIntro,
  getHeaderMessage,
  getReferrerContent,
} from '../store/actions/wordpressActions';
import {
  selectAutoLoginFailed,
  selectAutoLoginFinished,
  selectAutoLoginStep,
} from '../store/selectors/autoLoginSelectors';
import { isRegulatedLocale } from '../shared/utility';
import { selectIsAuthenticated } from '../store/selectors/authSelectors';
import { AutoLoginStep } from '../models/enum/auto-login-step.enum';
import { selectHardReloadPending } from '../store/selectors/userSelectors';
import { selectUserBtag } from '../store/selectors/combined.selectors';

export function useWordpressLoader() {
  const locale = useSelector(selectLocale);
  const hardReloadPending = useSelector(selectHardReloadPending);
  const autoLoginStep = useSelector(selectAutoLoginStep);
  const autoLoginFailed = useSelector(selectAutoLoginFailed);
  const autoLoginFinished = useSelector(selectAutoLoginFinished);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const btag = useSelector(selectUserBtag);
  const dispatch = useDispatch();

  // Header message
  // don't call the header message until the auto login is finished
  // reload the header message every time the locale changes
  useEffect(() => {
    if (!locale || !autoLoginFinished || hardReloadPending) {
      return;
    }
    dispatch(getHeaderMessage(locale));
  }, [locale, autoLoginFinished, hardReloadPending]);

  // Referrer content
  // 1. don't need referrer content for regulated locale
  // 2. don't call referrer content if there is no btag in the query param
  // 3. don't call referrer content if auto login hasn't finished
  useEffect(() => {
    if (!autoLoginFinished) {
      return;
    }
    if (
      !locale ||
      isRegulatedLocale(locale) ||
      !btag ||
      !autoLoginFinished ||
      isAuthenticated ||
      autoLoginStep === AutoLoginStep.LoggedOut
    ) {
      return;
    }
    // Get referrer content, which is displayed in as a notification and on registration in the regily modal, if the user has a btag in the cookies
    const referrerId = btag.split('_')[0];
    dispatch(getReferrerContent(locale, referrerId));
  }, [btag, locale, autoLoginFinished, isAuthenticated, autoLoginStep]);

  // bonus intro should be loaded only when auto login has finished and failed
  // 1. locale is not regulated (we don't need bonus intro content on pay and play)
  // 2. we don't need bonus intro for Thailand (we have static stuff there)
  // 3. auto login flow finished and failed
  useEffect(() => {
    if (locale && !isRegulatedLocale(locale) && locale !== 'th' && autoLoginFailed) {
      dispatch(getBonusIntro(locale));
    }
  }, [locale, autoLoginFailed]);
}
