import React from 'react';

import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../models/root-state.model';
import { useTranslate } from '../../../../hooks/useTranslate';
import { openModal, updateModalProps } from '../../../../store/actions';
import axios from 'axios';
import { getAxiosError } from '../../../../shared/utility';
import { TranslatedRichText } from '../../../../components/TranslatedRichText';
import Button from '../../../../components/UI/Buttons/Button';

const PanicTimeout = (): JSX.Element => {
  const token = useSelector((state: RootState) => state.auth.token);
  const intl = useIntl();
  const dispatch = useDispatch();
  const { translateError } = useTranslate();

  function addTimeout() {
    dispatch(updateModalProps({ loading: true }));
    axios
      .post('/api/responsiblegaming/timeout/panictimeout', { token })
      .then(response => {
        console.log(response);
        dispatch(updateModalProps({ loading: false }));
      })
      .catch(error => {
        const err = getAxiosError(error);
        dispatch(updateModalProps({ loading: false, error: translateError(err) }));
      });
  }

  function submitHandler() {
    dispatch(
      openModal('confirm', {
        header: intl.formatMessage({ id: 'responsibleGaming.selfExclusion.confirm.header' }),
        text: `${intl.formatMessage({
          id: 'responsibleGaming.selfExclusion.confirm.text',
        })} 24 timmar.`,
        buttonText: intl.formatMessage({ id: 'responsibleGaming.selfExclusion.submitButton' }),
        onSubmit: () => addTimeout(),
        loading: false,
        error: false,
      }),
    );
  }

  return (
    <div>
      <h2 className="mb-6">
        {intl.formatMessage({
          id: 'responsibleGaming.panicTimeOut.24hrs',
        })}
      </h2>
      <TranslatedRichText id="responsibleGaming.takeABreak.content" tagName="div" />
      <div className="button-wrapper">
        <Button
          primary
          onClick={() => submitHandler()}
          to={undefined}
          width={undefined}
          small={undefined}
          ghost={undefined}
          iconleft={undefined}
          iconright={undefined}
          nomargin={undefined}
          icononly={undefined}
          big={undefined}
          cancel={undefined}
        >
          {intl.formatMessage({
            id: 'responsibleGaming.panicTimeOut.24hrs',
          })}
        </Button>
      </div>
    </div>
  );
};

export default PanicTimeout;
