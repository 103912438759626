import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { useLocalizedTo } from '../hooks/useLocalizedTo';

export function AbsoluteNavLink({
  to,
  children,
  className,
  end,
}: NavLinkProps): JSX.Element | null {
  const localizedTo = useLocalizedTo(to);

  if (!localizedTo) {
    return null;
  }

  return (
    <NavLink to={localizedTo} className={className} end={end}>
      {children}
    </NavLink>
  );
}
