import { all, AllEffect, call, ForkEffect, put, StrictEffect, takeEvery } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { axiosInstance as axios } from '../../shared/axiosInstance';
import { isAxiosResponse } from '../../utils/type-guards';
import {
  GetLoginHistoryAction,
  getLoginHistoryError,
  getLoginHistorySuccess,
  LoginHistoryActionType,
} from '../actions/loginHistoryActions';

export function* getLoginHistorySaga(
  action: GetLoginHistoryAction,
): Generator<StrictEffect, void, AxiosResponse<any>> {
  try {
    const response = yield call(axios.post, '/api/user/getloginhistory', {
      token: action.token,
    });
    if (isAxiosResponse(response)) {
      yield put(getLoginHistorySuccess(response.data));
    } else {
      yield put(getLoginHistoryError());
    }
  } catch (error) {
    yield put(getLoginHistoryError());
  }
}

export function* watchLoginHistory(): Generator<AllEffect<ForkEffect<never>>, void> {
  yield all([takeEvery(LoginHistoryActionType.GetLoginHistory, getLoginHistorySaga)]);
}
