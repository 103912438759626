import React from 'react';

import classNames from 'classnames';
import { useField } from 'formik';

import Radio from './Radio';
import { isEmpty } from '../../../shared/utility';

export interface RadioProps {
  value?: string;
  label: string;
}

export interface RadioGroupProps {
  name: string;
  label: string;
  labelClassName?: string;
  options: RadioProps[];
  key?: string;
  multiple?: boolean;
}

const RadioGroup = ({ options, name, label, labelClassName, multiple, key }: RadioGroupProps) => {
  const [field, , { setValue }] = useField(name);

  return (
    <div className="flex flex-col space-y-2" key={key}>
      <label className={classNames('mb-2 text-lg font-bold', labelClassName)}>{label}</label>
      {options.map(({ value, label }, index) => (
        <Radio
          key={name + index.toString()}
          value={label}
          onClick={() => {
            if (!multiple) {
              value ? setValue(value) : setValue(label);
            } else {
              let values = field.value;
              if (isEmpty(values) && values !== []) {
                values = [];
              }
              const elementMatches = (element: string) => element === value || element === label;
              const indexOfElement = values?.findIndex(elementMatches);
              if (indexOfElement > -1) {
                values.splice(indexOfElement, 1);
              } else {
                values.push(value ? value : label);
              }
              setValue(values);
            }
          }}
          checked={
            multiple
              ? field.value?.includes(value) || field.value?.includes(label)
              : field.value === value || field.value === label
          }
        />
      ))}
    </div>
  );
};

export default RadioGroup;
