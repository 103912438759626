import { create } from 'zustand';
import axios from 'axios';
import { CDN_DATA, envIsMexico } from '../constants/constants';

interface ExcludedGamesState {
  excludedGames: string;
  loading: boolean;
  error: boolean;
  fetch: () => void;
}

export const useExcludedGamesStore = create<ExcludedGamesState>((set, get) => ({
  excludedGames: '',
  loading: false,
  error: false,
  fetch: async () => {
    if (get().excludedGames.length > 0) {
      return;
    }

    set({
      loading: true,
      error: false,
    });
    try {
      const fileName = envIsMexico() ? 'excluded_games_mx.json' : 'excluded_games.json';
      const response = await axios.get<{ excludedGames: string }>(`${CDN_DATA}/${fileName}`);
      set({ loading: false, error: false, excludedGames: response.data.excludedGames });
    } catch {
      set({
        loading: false,
        error: true,
      });
    }
  },
}));
