import parse from 'html-react-parser';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import PageHeader from '../Layout/PageHeader';
import PrivacyPolicyStyles from '../styles/TermsAndConditionsStyles';
import Spinner from '../UI/Spinner';
import { usePrivacyPolicyLoader } from '../../hooks/usePrivacyPolicyLoader';
import { useSelector } from 'react-redux';
import { selectLocale } from '../../store/selectors/commonSelectors';

function PrivacyPolicy(): JSX.Element {
  const locale = useSelector(selectLocale);
  const privacyPolicy = usePrivacyPolicyLoader();
  const intl = useIntl();

  return (
    <>
      <Helmet>
        <title>LuckyDays - {intl.formatMessage({ id: 'privacyPolicy.header' })}</title>
      </Helmet>
      <PageHeader>
        {locale === 'no' && isMobile ? (
          <h1>{intl.formatMessage({ id: 'privacyPolicy.header.mobile' })}</h1>
        ) : (
          <h1>{intl.formatMessage({ id: 'privacyPolicy.header' })}</h1>
        )}
        <p>{!!privacyPolicy && privacyPolicy.last_updated}</p>
      </PageHeader>
      <PrivacyPolicyStyles>
        <div className="content">
          {!!privacyPolicy ? parse(privacyPolicy.content.toString()) : <Spinner dark absolute />}
        </div>
      </PrivacyPolicyStyles>
    </>
  );
}

export default PrivacyPolicy;
