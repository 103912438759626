import { all, AllEffect, call, ForkEffect, put, select, takeEvery } from 'redux-saga/effects';
import { axiosInstance as axios } from '../../shared/axiosInstance';
import { isAxiosResponse } from '../../utils/type-guards';
import {
  getTimeoutLegislationDurationsError,
  getTimeoutLegislationDurationsSkip,
  getTimeoutLegislationDurationsSuccess,
  UserExtrasActionTypes,
} from '../actions/userExtrasActions';
import { selectAuthToken } from '../selectors/authSelectors';
import { selectTimeoutLegislationDurations } from '../selectors/userExtrasSelectors';
import { SagaIterator } from 'redux-saga';

export function* getTimeOutLegislationDurationsSaga(): SagaIterator {
  const token = yield select(selectAuthToken);
  const legislationDurations = yield select(selectTimeoutLegislationDurations);

  if (!token || legislationDurations.length > 0) {
    yield put(getTimeoutLegislationDurationsSkip());
    return;
  }

  try {
    const response = yield call(axios.post, '/api/responsiblegaming/timeoutlegislationdurations', {
      token,
    });
    if (isAxiosResponse(response)) {
      yield put(getTimeoutLegislationDurationsSuccess(response.data));
    } else {
      yield put(getTimeoutLegislationDurationsError());
    }
  } catch (error) {
    yield put(getTimeoutLegislationDurationsError());
  }
}

export function* watchUserExtras(): Generator<AllEffect<ForkEffect<never>>, void> {
  yield all([
    takeEvery(
      UserExtrasActionTypes.GetTimeoutLegislationDurations,
      getTimeOutLegislationDurationsSaga,
    ),
  ]);
}
