import React, { ReactNode, useState } from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

export const WelcomeTextHeading = ({ heading }: { heading: string }) => (
  <h2 className="m-0 text-2xl md:text-3xl">{heading}</h2>
);

export const WelcomeTextBody = ({ children }: { children: ReactNode }) => (
  <p className="m-0 text-justify">{children}</p>
);

export const WelcomeTextLink = ({ children, href }: { children: ReactNode; href: string }) => (
  <a rel="noreferrer" target="_blank" href={href} className="underline text-blue-600">
    {children}
  </a>
);

export const WelcomeTextSection = ({ children }: { children: ReactNode }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const intl = useIntl();

  return (
    <div
      className={classNames(
        'relative z-[1] md:max-w-[650px] lg:max-w-[1200px] mx-auto',
        isExpanded ? 'pb-0' : 'pb-8',
      )}
    >
      <div
        className={classNames(
          'space-y-4 overflow-hidden',
          'transition-all ease-in-out duration-300',
          isExpanded ? 'max-h-[4900px]' : 'max-h-[318px] lg:max-h-[515px]',
        )}
      >
        {children}
      </div>
      <div className={classNames('absolute', isExpanded ? '-bottom-9 left-0' : '-bottom-9 left-0')}>
        <button
          type="button"
          onClick={() => setIsExpanded(!isExpanded)}
          className="m-0 font-bold text-blue-blue"
        >
          {isExpanded
            ? intl.formatMessage({ id: 'misc.showLess' })
            : intl.formatMessage({ id: 'misc.showMore' })}
        </button>
      </div>
    </div>
  );
};

export const EnrichedWelcomeText = ({
  children,
  imageUrl,
}: {
  children: ReactNode;
  imageUrl: string;
}) => {
  return (
    <div className="flex flex-col lg:flex-row-reverse w-full items-center lg:items-start relative overflow-hidden">
      <div className="h-full max-h-[450px] lg:w-[40%] lg:max-h-full">
        <img
          className="object-contain w-full max-w-[500px]"
          alt="lobby_texts_image"
          src={imageUrl}
        />
      </div>

      <div className="flex flex-col items-center space-y-12 md:space-y-16 pb-8 w-full lg:w-[60%]  border-t-2 border-blue-blue lg:border-t-0 lg:border-b-2">
        {children}
      </div>
      <div className="absolute w-[650px] h-[800px] top-[500px] lg:top-[80px] z-[0] left-0">
        <div className="bg-blue-blue/20 backdrop-blur-sm w-full h-full [clip-path:polygon(0_0,_100%_0,_35%_100%,_0_100%,_50%_20%,_0_20%)]"></div>
      </div>
    </div>
  );
};
