import React from 'react';
import Currency from '../../components/UI/Currency';
import { IS_SWEDEN } from '../../constants/constants';
import { classNames } from '../../utils/utils';

export function PnPAmountSelect({
  handleAmountSelect,
  depositAmount,
  low,
  mid,
  high,
}: {
  handleAmountSelect: (amount: number) => void;
  depositAmount: number | string;
  low: number;
  mid: number;
  high: number;
}): JSX.Element | null {
  return (
    <div className="mt-6 grid grid-cols-3 gap-2">
      <button
        type="button"
        className={classNames(
          depositAmount === low && '!border-green-600 !bg-green-500 text-white',
          'rounded-full border-b-2 border-gray-400 bg-gray-200 px-3 py-2 font-bold hover:bg-gray-100',
        )}
        onClick={() => handleAmountSelect(low)}
      >
        <Currency currency={IS_SWEDEN ? 'SEK' : 'EUR'} amount={low} />
      </button>
      <button
        type="button"
        className={classNames(
          depositAmount === mid && '!border-green-600 !bg-green-500 text-white',
          'rounded-full border-b-2 border-gray-400 bg-gray-200 px-3 py-2 font-bold hover:bg-gray-100',
        )}
        onClick={() => handleAmountSelect(mid)}
      >
        <Currency currency={IS_SWEDEN ? 'SEK' : 'EUR'} amount={mid} />
      </button>
      <button
        type="button"
        className={classNames(
          depositAmount === high && '!border-green-600 !bg-green-500 text-white',
          'rounded-full border-b-2 border-gray-400 bg-gray-200 px-3 py-2 font-bold hover:bg-gray-100',
        )}
        onClick={() => handleAmountSelect(high)}
      >
        <Currency currency={IS_SWEDEN ? 'SEK' : 'EUR'} amount={high} />
      </button>
    </div>
  );
}
