import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSimpleRequest } from '../../hooks/useSimpleRequest';
import { useSelector } from 'react-redux';
import { selectAuthToken } from '../../store/selectors/authSelectors';
import { StyledSwitch } from './Details';
import { selectUser } from '../../store/selectors/userSelectors';
import PromptMessage from '../../components/UI/PromptMessage';
import Spinner from '../../components/UI/Spinner';

enum AuthenticationMethod {
  OneFactor = 1,
  TwoFactor = 2,
}

const Settings2FA = (): JSX.Element => {
  const intl = useIntl();
  const user = useSelector(selectUser);
  const [authenticationMethod, setAuthenticationMethod] = useState<AuthenticationMethod>(
    AuthenticationMethod.OneFactor,
  );
  const token = useSelector(selectAuthToken);
  const { request } = useSimpleRequest<
    { authenticationMethod: AuthenticationMethod },
    { token: string }
  >();
  const {
    request: setAuthenticationMethodRequest,
    success,
    loading,
  } = useSimpleRequest<null, { token: string; authenticationMethod: AuthenticationMethod }>();

  useEffect(() => {
    request('/api/user/getauthenticationmethod', { token: token! }, data =>
      setAuthenticationMethod(data.authenticationMethod),
    );
  }, []);

  const submit = () => {
    setAuthenticationMethodRequest('/api/user/setauthenticationmethod', {
      token: token!,
      authenticationMethod,
    });
  };

  const toggleTwoFactor = () => {
    setAuthenticationMethod(prevState =>
      prevState === AuthenticationMethod.OneFactor
        ? AuthenticationMethod.TwoFactor
        : AuthenticationMethod.OneFactor,
    );
  };

  return (
    <section className="rounded bg-white p-8">
      <h3 className="text-2xl font-bold">{intl.formatMessage({ id: 'settings.otp.title' })}</h3>
      <p className="my-6">{intl.formatMessage({ id: 'settings.otp.description' })}</p>
      <div className="mb-8 w-full">
        <div className="text-sm opacity-50">
          {intl.formatMessage({ id: 'settings.otp.registeredPhoneLabel' })}{' '}
          {`${user?.MobilePrefix} ${user?.Mobile}`}
        </div>
        <StyledSwitch htmlFor="twoFactorSms">
          {intl.formatMessage({ id: 'inputs.toggle.sms' })}
          <div className="switch">
            <input
              id="twoFactorSms"
              type="checkbox"
              checked={authenticationMethod === AuthenticationMethod.TwoFactor}
              onChange={toggleTwoFactor}
            />
            <span className="slider" />
          </div>
        </StyledSwitch>
      </div>
      {success && (
        <div className="w-full">
          <PromptMessage
            type="success"
            message={intl.formatMessage({
              id: 'settings.details.successMessage',
            })}
          />
        </div>
      )}
      <div className="flex w-full justify-end">
        <button
          type="button"
          className="rounded-full bg-cyan-500 px-10 py-4 font-bold text-white shadow-md transition hover:bg-cyan-600 hover:shadow-lg md:w-auto"
          onClick={submit}
        >
          {loading ? (
            <Spinner color="white" height={22} width={22} borderwidth={3} />
          ) : (
            intl.formatMessage({ id: 'settings.details.saveButton' })
          )}
        </button>
      </div>
    </section>
  );
};

export default Settings2FA;
