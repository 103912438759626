import React, { useEffect, useState } from 'react';

import { CheckCircleIcon, GlobeAmericasIcon } from '@heroicons/react/20/solid';
import { useIntl } from 'react-intl';

import { BtnOneLineBlue } from '../buttons/BtnOneLineBlue';
import { useAbsoluteNavigate } from '../../hooks/useAbsoluteNavigate';

export function LocationSmartModal({
  callback,
  closeModal,
  error = false,
  success = false,
  loading = false,
  isRecheck = false,
}: {
  callback: (isRecheck: boolean) => void;
  closeModal: () => void;
  error?: boolean;
  success?: boolean;
  loading?: boolean;
  isRecheck?: boolean;
}): JSX.Element {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const intl = useIntl();
  const absoluteNavigate = useAbsoluteNavigate();
  const header = isRecheck
    ? intl.formatMessage({ id: 'locationsmart.recheck.header' })
    : intl.formatMessage({ id: 'locationsmart.header' });
  const text = isRecheck
    ? intl.formatMessage({ id: 'locationsmart.recheck.text' })
    : intl.formatMessage({ id: 'locationsmart.text' });

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (success) {
      timer = setTimeout(() => {
        closeModal();
      }, 2000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [success]);

  const handleUserResponse = () => {
    if (isSubmitDisabled) return;

    setIsSubmitDisabled(true);
    callback(isRecheck);
  };

  if (success) {
    return (
      <div className="w-full max-w-sm rounded-xl bg-white px-8 py-8">
        <CheckCircleIcon className="mx-auto h-8 w-8 fill-green-500 stroke-green-600" />
        <h1 className="mt-4 text-lg font-bold">
          {intl.formatMessage({ id: 'locationsmart.success.header' })}
        </h1>
        <p className="mt-3 mb-0">{intl.formatMessage({ id: 'locationsmart.success.text' })}</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full max-w-sm rounded-xl bg-white px-8 py-8 text-left">
        <h1 className="text-lg font-bold">
          {intl.formatMessage({ id: 'locationsmart.blocked.header' })}
        </h1>
        <p className="mt-3 mb-0">{intl.formatMessage({ id: 'locationsmart.blocked.text1' })}</p>
        <ul className="mt-3 list-disc space-y-2 pl-5 text-left">
          <li className="mb-0">
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="text-cyan-600 underline"
              href="https://support.google.com/chrome/answer/142065"
            >
              Google Chrome
            </a>
          </li>
          <li className="mb-0">
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="text-cyan-600 underline"
              href="https://support.apple.com/en-gb/guide/mac-help/mh35873/mac"
            >
              Mac OS
            </a>
          </li>
          <li className="mb-0">
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="text-cyan-600 underline"
              href="https://support.apple.com/en-us/HT207092"
            >
              iOS
            </a>
          </li>
          <li className="mb-0">
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="text-cyan-600 underline"
              href="https://support.mozilla.org/en-US/kb/does-firefox-share-my-location-websites"
            >
              Firefox
            </a>
          </li>
        </ul>
        <p className="mt-3">
          {intl.formatMessage({ id: 'locationsmart.blocked.text2' })}{' '}
          <button
            type="button"
            className="text-cyan-600 underline"
            onClick={() => {
              closeModal();
              absoluteNavigate('/terms-and-conditions');
            }}
          >
            {intl.formatMessage({ id: 'footer.termsLinkText' })}
          </button>
          .
        </p>
        <button
          type="button"
          onClick={() => closeModal()}
          className="mt-8 w-full rounded-full bg-gray-200 py-3 px-8 font-bold text-gray-700 shadow-md transition-all hover:bg-gray-300 hover:shadow-lg"
        >
          {intl.formatMessage({ id: 'locationsmart.blocked.button' })}
        </button>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="w-full max-w-sm rounded-xl bg-white px-8 py-8">
        <div className="relative inline-flex h-8 w-8">
          <GlobeAmericasIcon className="absolute mx-auto h-8 w-8 fill-green-500 stroke-blue-800" />
          <svg
            aria-hidden="true"
            className="absolute -left-1 -top-1 h-10 w-10 animate-spin fill-blue-600 text-transparent"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            ></path>
          </svg>
        </div>
        <h1 className="mt-4 text-lg font-bold">
          {intl.formatMessage({ id: 'locationsmart.loading.header' })}
        </h1>
        <p className="mt-3 mb-0">{intl.formatMessage({ id: 'locationsmart.loading.text' })}</p>
      </div>
    );
  }

  return (
    <div className="max-w-sm rounded-xl bg-white px-8 py-8">
      <GlobeAmericasIcon className="mx-auto h-8 w-8 fill-green-500 stroke-blue-800" />
      <h1 className="mt-4 text-lg font-bold">{header}</h1>
      <p className="mt-3 mb-8">{text}</p>
      <BtnOneLineBlue
        onClickHandler={handleUserResponse}
        isLoading={false}
        text={intl.formatMessage({ id: 'locationsmart.button' })}
        disabled={isSubmitDisabled}
      />
    </div>
  );
}
