import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { envIsCanada, envIsMexico, envIsSweden } from '../constants/constants';
import { RootState } from '../models/root-state.model';
import { mapLocaleToPathLocale } from '../utils/mappers';

export function useAbsoluteNavigate(): (to: string) => void {
  const locale = useSelector((state: RootState) => state.common.locale);
  const navigate = useNavigate();

  const absoluteNavigate = useCallback(
    (to: string) => {
      if (envIsSweden() || envIsMexico()) {
        navigate(to);
        return;
      }

      if (envIsCanada()) {
        if (locale === 'ca') {
          navigate(to);
          return;
        }
      }

      const path = to === '/' ? '' : to;
      navigate(`/${mapLocaleToPathLocale(locale)}${path}`);
    },
    [locale],
  );

  return absoluteNavigate;
}
