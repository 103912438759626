import React, { InputHTMLAttributes, useState } from 'react';

import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';
import { useField } from 'formik';
import { useIntl } from 'react-intl';

import Tooltip from './UI/Tooltip';
import { classNames } from '../utils/utils';

interface IFormPasswordProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
  label: string;
  labelLink?: { text: string; onClick: () => void };
  formatOnChange?: (value: any) => any;
}

export function FormPassword({
  label,
  labelLink,
  formatOnChange,
  ...rest
}: IFormPasswordProps): JSX.Element {
  const { name, className } = rest;

  const [showPassword, setShowPassword] = useState(false);
  const intl = useIntl();
  const [field, meta, helpers] = useField(name!);

  const { setValue } = helpers;

  const toggleShowPassword = () => {
    setShowPassword(prevState => !prevState);
  };

  return (
    <div className="relative mb-8 pb-2">
      <label
        htmlFor={name}
        className="align-start flex flex-col text-left text-sm font-bold text-[#007078]"
      >
        <div className="flex justify-between">
          <div>{label}</div>
          {labelLink && (
            <button
              className="text-sm font-bold text-cyan-500 underline transition hover:text-cyan-600"
              type="button"
              onClick={labelLink.onClick}
              tabIndex={-1}
            >
              {labelLink.text}
            </button>
          )}
        </div>
        <input
          {...field}
          {...rest}
          type={showPassword ? 'text' : 'password'}
          className={classNames(
            meta.touched && meta.error ? 'border-b-red-500' : 'border-b-slate-200',
            'border-0 border-b-2 bg-transparent px-0 pr-2 text-xl font-bold text-black placeholder:font-normal focus:border-b-slate-200 focus:ring-0 disabled:cursor-not-allowed disabled:text-gray-400',
            className,
          )}
          onChange={e => {
            if (formatOnChange) {
              setValue(formatOnChange(e.target.value));
              return;
            }
            setValue(e.target.value);
          }}
        />
        <button
          tabIndex={-1}
          type="button"
          className="absolute right-0 top-[50%] translate-y-[-25%]"
          onClick={toggleShowPassword}
        >
          {showPassword ? <EyeSlashIcon className="h-5 w-5" /> : <EyeIcon className="h-5 w-5" />}
          <Tooltip
            width="auto"
            text={
              showPassword
                ? intl.formatMessage({
                    id: 'inputs.password.hide',
                  })
                : intl.formatMessage({
                    id: 'inputs.password.show',
                  })
            }
            position="top-right"
          />
        </button>
      </label>
      {meta.touched && meta.error && (
        <div className="absolute -bottom-3 text-xs text-red-500">{meta.error}</div>
      )}
    </div>
  );
}
