import React from 'react';
import { CheckBox } from '../../components/CheckBox';
import { useIntl } from 'react-intl';

export function PayNPlayWelcomeOptIn({
  id,
  optIn,
  toggleOptIn,
}: {
  id: string;
  optIn: boolean;
  toggleOptIn: () => void;
}): JSX.Element | null {
  const intl = useIntl();
  return (
    <div className="flex border-y border-gray-300 py-3 items-center">
      <p className="m-0 text-sm italic">
        {intl.formatMessage({ id: 'paynplay.modal.bonus.optin' })}
      </p>
      <CheckBox id={id} value="checked" label="" initialCheck={optIn} handleChange={toggleOptIn} />
    </div>
  );
}
