import React from 'react';
import classNames from 'classnames';

interface PaymentMethodButtonProps {
  body: string;
  onClick?: () => void;
  isActive?: boolean;
  isLeft?: boolean;
}

const PaymentMethodButton = ({
  body,
  onClick,
  isActive,
  isLeft = false,
}: PaymentMethodButtonProps) => {
  return (
    <div
      onClick={onClick}
      className={classNames(
        'transition-all ease-in-out duration-150 cursor-pointer',
        'px-3 py-1 text-lg font-bold hover:text-white',
        isActive && 'bg-white/20',
        !isActive && 'text-white/40 border-transparent bg-transparent',
        isLeft && 'border-r-4 rounded-l-lg',
        !isLeft && 'border-l-4 rounded-r-lg',
      )}
    >
      <div className="m-0 p-0">{body}</div>
    </div>
  );
};

export default PaymentMethodButton;
