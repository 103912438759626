import React, { useEffect, useMemo } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Form, RadioGroup, SearchDropdownField } from '../form';
import { selectLocale } from '../../store/selectors/commonSelectors';
import { useApiV2Request } from '../../hooks/useApiV2Request';
import ApiService from '../../shared/apiService';
import Spinner from '../UI/Spinner';
import { kycGetDocuments } from '../../store/actions/kycActions';
import { Button } from '../buttons/Button';
import { isEmpty } from '../../shared/utility';
import { XMarkIcon } from '@heroicons/react/24/solid';

type SOWQuestionnaire = SOWQuestion[];

interface SOWAnswer {
  label: string;
  value: string;
}

interface SOWQuestion {
  label: string;
  value: string;
  answers: SOWAnswer[];
}

interface SOWQSubmitData {
  1: string;
  2: string;
  3: string;
  4: string[];
  5: string;
  6: string;
}

const LabeledSearchField = ({
  name,
  label,
  options,
  optionsLabel,
}: {
  name: string;
  label: string;
  options: any;
  optionsLabel: string;
}) => {
  return (
    <div className="flex w-full flex-col space-y-2">
      <label className="mb-2 text-lg font-bold">{label}</label>
      <div className="ml-3 rounded-lg border-2 border-blue-blue/20">
        <SearchDropdownField
          inputClassName="text-black"
          labelClassName="text-black"
          optionsClassName="border-[1px] border-blue-blue/30"
          name={name}
          label={optionsLabel}
          options={options}
        />
      </div>
    </div>
  );
};

export const SOWQModal = ({ closeModal }: { closeModal: () => void }) => {
  const intl = useIntl();
  const locale = useSelector(selectLocale);
  const dispatch = useDispatch();
  const {
    request: getSowqRequest,
    loading: sowqLoading,
    data: sowqData,
  } = useApiV2Request<SOWQuestionnaire>();
  const {
    request: postSowqRequest,
    loading: postSowqLoading,
    error: postSowqError,
  } = useApiV2Request();

  useEffect(() => {
    getSowqRequest(ApiService.getSowQuestionnaire, { locale });
  }, []);

  const initialValues = useMemo(
    () =>
      sowqData &&
      sowqData.reduce((acc, sowquestion) => ({ ...acc, [sowquestion.value]: null }), {}),
    [sowqData],
  );

  return (
    <div className="relative w-full max-w-[570px] max-h-[90vh] lg:max-h-[670px] lg:max-w-xl p-6 pt-12 overflow-y-scroll overscroll-none no-scrollbar rounded-xl bg-white text-left">
      <button
        type="button"
        onClick={() => closeModal()}
        className="absolute top-0 left-0 rounded-br-xl p-2 text-gray-700 hover:bg-gray-200"
      >
        <XMarkIcon className="h-6 w-6" />
      </button>
      <h2 className="text-3xl text-center">{intl.formatMessage({ id: 'verify.sow.header' })}</h2>
      <p className="mt-4 pb-4  border-b-[1px] border-black/30">
        {intl.formatMessage({ id: 'verify.sow.text' })}
      </p>
      {sowqLoading ? (
        <div className="w-full flex justify-center">
          <Spinner absolute={false} width={60} height={60} color="white" borderwidth={2} />
        </div>
      ) : (
        sowqData &&
        initialValues && (
          <Form
            initialValues={initialValues}
            onSubmit={data =>
              postSowqRequest(ApiService.postSowQuestionnaire, data as SOWQSubmitData, () => {
                dispatch(kycGetDocuments());
                closeModal();
              })
            }
          >
            {({ values }) => (
              <div className="flex flex-col space-y-16">
                {sowqData && (
                  <>
                    <RadioGroup
                      options={sowqData[0].answers}
                      name={sowqData[0].value}
                      label={sowqData[0].label}
                    />
                    <LabeledSearchField
                      name={sowqData[1].value}
                      label={sowqData[1].label}
                      optionsLabel={intl.formatMessage({ id: 'sowq.primaryOccupation' })}
                      options={sowqData[1].answers}
                    />

                    <RadioGroup
                      options={sowqData[2].answers}
                      name={sowqData[2].value}
                      label={sowqData[2].label}
                    />
                    <RadioGroup
                      options={sowqData[3].answers}
                      name={sowqData[3].value}
                      label={sowqData[3].label}
                      multiple
                    />
                    <LabeledSearchField
                      name={sowqData[4].value}
                      label={sowqData[4].label}
                      optionsLabel={intl.formatMessage({ id: 'sowq.nationality' })}
                      options={sowqData[4].answers}
                    />
                    <LabeledSearchField
                      name={sowqData[5].value}
                      label={sowqData[5].label}
                      optionsLabel={intl.formatMessage({ id: 'sowq.placeOfBirth' })}
                      options={sowqData[5].answers}
                    />
                    <RadioGroup
                      options={sowqData[6].answers}
                      name={sowqData[6].value}
                      label={sowqData[6].label}
                    />
                  </>
                )}
                <div className="mt-8 flex flex-col space-y-4">
                  {postSowqError && (
                    <p className="text-red-300 text-lg">
                      {`${intl.formatMessage({ id: 'error.general' })} [${postSowqError.errorId}]`}
                    </p>
                  )}
                  <Button
                    disabled={
                      Object.values(values).filter(value => !isEmpty(value)).length !==
                        sowqData?.length || postSowqLoading
                    }
                    type={'submit'}
                    text={intl.formatMessage({ id: 'state.update.modal.submit' })}
                  ></Button>
                </div>
              </div>
            )}
          </Form>
        )
      )}
    </div>
  );
};
