import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { SelfExclusion, TimeOut } from '../limits';
import { Select, SelectItem } from '../../../../components/Select';

export enum Limit {
  TimeOut = 1,
  SelfExclusion = 2,
}

const availableOptions = () => {
  const allOptions: Record<Limit, { translationId: string }> = {
    [Limit.TimeOut]: {
      translationId: 'responsibleGaming.takeABreak.header',
    },
    [Limit.SelfExclusion]: {
      translationId: 'playerLimits.selfExclusion',
    },
  };

  const mapOptions = (options: Limit[]): SelectItem[] => {
    return options.reduce((a: SelectItem[], i: Limit) => {
      const option = allOptions[i];
      a.push({ id: i.toString(), ...option });
      return a;
    }, []);
  };

  return mapOptions([Limit.TimeOut, Limit.SelfExclusion]);
};

const AccountClosure = ({
  showLimitsInitially = false,
  initialLimit = Limit.TimeOut,
}: {
  showLimitsInitially?: boolean;
  initialLimit?: Limit;
}): JSX.Element => {
  const [showLimits, setShowLimits] = useState(showLimitsInitially);
  const intl = useIntl();
  const [options] = useState(availableOptions());
  const [selectedOption, setSelectedOption] = useState<SelectItem>(options[0]);

  useEffect(() => {
    const findOption = options.find(o => o.id === initialLimit.toString());
    if (initialLimit && findOption) {
      setSelectedOption(findOption);
    }
  }, [initialLimit]);

  const selectedSection = (id: string): JSX.Element => {
    switch (parseInt(id) as Limit) {
      case Limit.TimeOut:
        return <TimeOut />;
      case Limit.SelfExclusion:
        return <SelfExclusion />;
    }
  };

  return (
    <section className="player-limits space-y-4">
      {!showLimits && (
        <div className="button-wrapper mt-4">
          <button
            type="button"
            className="mx-2 mt-4 mb-4 rounded-full bg-cyan-500 py-4 px-6 font-bold leading-[22px] text-white shadow-md transition-all hover:bg-cyan-600 hover:shadow-lg"
            onClick={() => setShowLimits(true)}
          >
            {intl.formatMessage({ id: 'accountClosure.button' })}
          </button>
        </div>
      )}
      {showLimits && (
        <>
          <div className="mx-auto w-full max-w-xs pt-4">
            <Select
              items={options}
              selectedItem={selectedOption}
              onChangeHandler={setSelectedOption}
              size="md"
            />
          </div>
          <div className="pt-4">{selectedSection(selectedOption.id)}</div>
        </>
      )}
    </section>
  );
};

export default AccountClosure;
