import React, { useEffect } from 'react';

import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { AgcoUserDetails } from '@lucky7ventures/lucky-components';

import ModalLayout from './ModalLayout';
import Spinner from '../UI/Spinner';
import DetailsReverificationForm from '../DetailsReverificationForm';
import { useApiV2Request } from '../../hooks/useApiV2Request';
import { selectUser } from '../../store/selectors/userSelectors';
import ApiService from '../../shared/apiService';
import { GTagEvents, triggerGTag } from '../../lib/gTagManager';

const DetailsReverificationModal = ({ closeModal }: { closeModal: () => void }) => {
  const intl = useIntl();
  const user = useSelector(selectUser);
  const { request: additionalUserDataRequest, data: additionalUserDetails } =
    useApiV2Request<AgcoUserDetails>();

  useEffect(() => {
    additionalUserDataRequest(ApiService.getAgcoUserDetails, {});
    triggerGTag(GTagEvents.reverification_triggered);
  }, []);

  return (
    <ModalLayout id="e2e_detailsReverificationModal" className="!max-w-md">
      <h1 className="mb-4 text-center text-lg font-bold">
        {intl.formatMessage({ id: 'detailsReverification.title' })}
      </h1>
      <p className="text-center">{intl.formatMessage({ id: 'detailsReverification.text' })}</p>
      {additionalUserDetails && user ? (
        <DetailsReverificationForm
          user={user}
          additionalUserDetails={additionalUserDetails}
          closeModal={closeModal}
        />
      ) : (
        <div className="flex justify-center w-full">
          <Spinner />
        </div>
      )}
    </ModalLayout>
  );
};

export default DetailsReverificationModal;
