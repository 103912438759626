import { Action } from 'redux';
import { AutoLoginStep } from '../../models/enum/auto-login-step.enum';

export enum AutoLoginActionType {
  AutoLogin = 'AUTO_LOGIN',
  AutoLoginUpdate = 'AUTO_LOGIN_UPDATE',
  RefreshUser = 'REFRESH_USER',
}

export type AutoLoginAction = Action<AutoLoginActionType.AutoLogin>;
export type AutoLoginUpdateAction = Action<AutoLoginActionType.AutoLoginUpdate> & {
  step: AutoLoginStep;
};
export type RefreshUserAction = Action<AutoLoginActionType.RefreshUser> & {
  token: string;
};

export type AutoLoginActions = AutoLoginAction | AutoLoginUpdateAction | RefreshUserAction;

export const autoLogin = (): AutoLoginAction => ({
  type: AutoLoginActionType.AutoLogin,
});

export const autoLoginUpdate = (autoLoginState: AutoLoginStep): AutoLoginUpdateAction => ({
  type: AutoLoginActionType.AutoLoginUpdate,
  step: autoLoginState,
});

export const refreshUser = (token: string): RefreshUserAction => ({
  type: AutoLoginActionType.RefreshUser,
  token,
});
