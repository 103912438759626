import { UserExtrasActions, UserExtrasActionTypes } from '../actions/userExtrasActions';
import { TimeoutDurationModel } from '../../models/timeoutDuration.model';

export interface UserExtrasState {
  timeOutLegislationDurations: TimeoutDurationModel[];
  timeOutLegislationDurationsLoading: boolean;
  timeOutLegislationDurationsError: boolean;
}

const initialState = {
  timeOutLegislationDurations: [],
  timeOutLegislationDurationsLoading: false,
  timeOutLegislationDurationsError: false,
};

export const userExtrasReducer = (
  state = initialState,
  action: UserExtrasActions,
): UserExtrasState => {
  switch (action.type) {
    case UserExtrasActionTypes.ResetUserExtras:
      return initialState;
    case UserExtrasActionTypes.GetTimeoutLegislationDurations:
      return {
        ...state,
        timeOutLegislationDurationsLoading: true,
        timeOutLegislationDurationsError: false,
      };
    case UserExtrasActionTypes.GetTimeoutLegislationDurationsSuccess:
      return {
        ...state,
        timeOutLegislationDurations: action.legislationDurations,
        timeOutLegislationDurationsLoading: false,
      };
    case UserExtrasActionTypes.GetTimeoutLegislationDurationsError:
      return {
        ...state,
        timeOutLegislationDurationsLoading: false,
        timeOutLegislationDurationsError: true,
      };
    case UserExtrasActionTypes.GetTimeoutLegislationDurationsSkip:
      return {
        ...state,
        timeOutLegislationDurationsLoading: false,
        timeOutLegislationDurationsError: false,
      };
    default:
      return state;
  }
};
