import { Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { depositLimits } from '../../../../utils/utils';
import { LimitDuration, LimitType } from '@lucky7ventures/lucky-components';
import {
  selectUser,
  selectUserHasFixedDepositLimit,
} from '../../../../store/selectors/userSelectors';
import {
  selectActiveDepositLimits,
  selectFutureDepositLimits,
} from '../../../../store/selectors/limitsSelectors';
import { selectLocale } from '../../../../store/selectors/commonSelectors';
import { useSetUserLimits } from '../../../../hooks/useSetUserLimits';
import { getLimits } from '../../../../store/actions/limitsActions';
import { openModal, updateModalProps } from '../../../../store/actions';
import { BUILD_MODE } from '../../../../constants/constants';
import { formatCurrency } from '../../../../utils/currencyUtils';
import { Select, SelectItem } from '../../../../components/Select';
import ActiveLimitsTable from './ActiveLimitsTable';
import { FutureLimitsTable } from './FutureLimitsTable';
import { FormInput } from '../../../../components/FormInput';

interface FormValues {
  amount: string;
}

const DEPOSIT_LIMITS = depositLimits();

const DepositLimit = (): JSX.Element => {
  const initialValues: FormValues = { amount: '' };
  const intl = useIntl();

  const depositErrorDay = intl.formatMessage(
    { id: 'depositLimit.amount.error' },
    { min: DEPOSIT_LIMITS.min, max: DEPOSIT_LIMITS.day },
  );
  const depositErrorWeek = intl.formatMessage(
    { id: 'depositLimit.amount.error' },
    { min: DEPOSIT_LIMITS.min, max: DEPOSIT_LIMITS.week },
  );
  const depositErrorMonth = intl.formatMessage(
    { id: 'depositLimit.amount.error' },
    { min: DEPOSIT_LIMITS.min, max: DEPOSIT_LIMITS.month },
  );

  const DEPOSIT_DURATIONS = [
    {
      duration: LimitDuration._24Hours,
      text: `${intl.formatMessage({ id: 'misc.daily' })}`,
      validationSchema: Yup.object().shape({
        amount: Yup.number()
          .typeError(depositErrorDay)
          .min(DEPOSIT_LIMITS.min, depositErrorDay)
          .max(DEPOSIT_LIMITS.day, depositErrorDay)
          .required(depositErrorDay),
      }),
    },
    {
      duration: LimitDuration._1Week,
      text: `${intl.formatMessage({ id: 'misc.weekly' })}`,
      validationSchema: Yup.object().shape({
        amount: Yup.number()
          .typeError(depositErrorWeek)
          .min(DEPOSIT_LIMITS.min, depositErrorWeek)
          .max(DEPOSIT_LIMITS.week, depositErrorWeek)
          .required(depositErrorWeek),
      }),
    },
    {
      duration: LimitDuration._1Month,
      text: `${intl.formatMessage({ id: 'misc.monthly' })}`,
      validationSchema: Yup.object().shape({
        amount: Yup.number()
          .typeError(depositErrorMonth)
          .min(DEPOSIT_LIMITS.min, depositErrorMonth)
          .max(DEPOSIT_LIMITS.month, depositErrorMonth)
          .required(depositErrorMonth),
      }),
    },
  ];
  const formikRef = useRef<any>();
  const [duration, setDuration] = useState(DEPOSIT_DURATIONS[0]);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const activeLimits = useSelector(selectActiveDepositLimits);
  const futureLimits = useSelector(selectFutureDepositLimits);
  const hasFixedLimit = useSelector(selectUserHasFixedDepositLimit);
  const locale = useSelector(selectLocale);
  const { success, loading, error, setUserLimits } = useSetUserLimits();

  useEffect(() => {
    // Empty the form if the user changes duration or
    // if the request of changing the duration is successful
    if (success || duration) {
      formikRef.current.resetForm();
    }

    if (success) {
      // if the request of changing the deposit limit is successful rerun the remaining limits
      dispatch(getLimits());
    }
  }, [success, duration]);

  useEffect(() => {
    dispatch(updateModalProps({ error, loading }));
  }, [loading, error]);

  const modalText = (
    isRaising: boolean,
    currency: string,
    amount: string,
    timeRange: string,
  ): string => {
    if (BUILD_MODE === 'normal') {
      return intl.formatMessage(
        { id: 'depositLimit.confirm.text' },
        { amount: formatCurrency(parseInt(amount), locale, currency), timeRange },
      );
    }

    if (isRaising) {
      return intl.formatMessage(
        { id: 'depositLimit.confirm.text.isRising' },
        { amount: formatCurrency(parseInt(amount), locale, currency), timeRange },
      );
    }

    return intl.formatMessage(
      { id: 'depositLimit.confirm.text.notRising' },
      { amount: formatCurrency(parseInt(amount), locale, currency), timeRange },
    );
  };

  function submitHandler(values: FormValues) {
    if (!user) {
      return;
    }

    // Check if the user is raising the limit or not
    const activeLimit = activeLimits.find(limit => limit.Duration === duration.duration);
    const isRaising = !!(activeLimit && activeLimit.Amount < parseInt(values.amount, 10));

    // If the player has the fixed limit tag and is trying to raise the deposit limit we show an error modal instead of the confirm modal
    if (BUILD_MODE === 'sweden' && hasFixedLimit && isRaising) {
      dispatch(openModal('fixedDepositLimitError'));

      return;
    }

    dispatch(
      openModal('confirm', {
        header: intl.formatMessage({ id: 'depositLimit.confirm.header' }),
        text: modalText(isRaising, user.Currency, values.amount, duration.text),
        buttonText: intl.formatMessage({ id: 'depositLimit.button' }),
        onSubmit: () =>
          setUserLimits(
            [
              {
                UserId: user.UserID,
                Duration: duration.duration,
                Type: LimitType.Deposit,
                LimitAmount: parseInt(values.amount, 10),
              },
            ],
            true,
          ),
        loading: false,
        error: false,
      }),
    );
  }

  const onChangeDepositDuration = (item: SelectItem) => {
    const depositDuration = DEPOSIT_DURATIONS.find(i => i.duration.toString() === item.id);
    if (!depositDuration) {
      return;
    }
    setDuration(depositDuration);
  };

  return (
    <div>
      <h2 className="mb-4">{intl.formatMessage({ id: 'depositLimit.header' })}</h2>
      <p>{intl.formatMessage({ id: 'depositLimit.text' })}</p>
      <div>
        {activeLimits && activeLimits.length > 0 && (
          <div>
            <h6 className="mb-2 font-semibold text-gray-600">
              {intl.formatMessage({ id: 'depositLimit.currentLimits' })}
            </h6>
            <ActiveLimitsTable activeLimits={activeLimits} />
          </div>
        )}
        {futureLimits.length > 0 && (
          <div className="mt-8">
            <h6 className="mb-2 font-semibold text-gray-600">
              {intl.formatMessage({ id: 'depositLimit.futureLimits' })}
            </h6>
            <FutureLimitsTable futureLimits={futureLimits} />
          </div>
        )}
      </div>

      <div className="mt-8">
        <h6 className="mb-2 font-semibold text-gray-600">
          {intl.formatMessage({ id: 'depositLimit.newLimits' })}
        </h6>
        <Select
          items={DEPOSIT_DURATIONS.map(item => ({
            id: item.duration.toString(),
            text: item.text,
          }))}
          onChangeHandler={onChangeDepositDuration}
          selectedItem={{ id: duration.duration.toString(), text: duration.text }}
        />
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={duration.validationSchema}
          onSubmit={values => submitHandler(values)}
        >
          {({ dirty, isValid }) => (
            <Form id="deposit-limit-form">
              <div className="mt-4">
                <FormInput
                  name="amount"
                  type="text"
                  className="mt-2 rounded-md !bg-white !px-2 !text-sm shadow-md"
                  label={`${intl.formatMessage({ id: 'limit.amount' })} (${user?.Currency})`}
                />
              </div>

              <button
                type="submit"
                className="rounded-full bg-cyan-500 px-5 py-3 font-bold text-white hover:bg-cyan-600 disabled:bg-cyan-500 disabled:opacity-75"
                disabled={!isValid || !dirty}
              >
                {intl.formatMessage({ id: 'depositLimit.button' })}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default DepositLimit;
