import { DateFilterModel } from '../models/date-filter.model';
import { getDateRangeBetweenDays } from '@lucky7ventures/lucky-components';
import { envIsCanada, IS_SWEDEN } from '../constants/constants';

export const dateFilterOptionsPerMonth = (
  now: Date,
  locale: string,
): Record<number, DateFilterModel> => {
  const currentMonth = now.getMonth();
  return Array(13)
    .fill(null)
    .map((_, i) => i)
    .reduce((a: Record<number, DateFilterModel>, i) => {
      const d = new Date(now);
      d.setMonth(currentMonth - i);
      const label = d.toLocaleDateString(locale, {
        year: 'numeric',
        month: 'short',
      });
      const firstDayOfMonth = new Date(d.getFullYear(), d.getMonth(), 1);
      firstDayOfMonth.setMinutes(
        firstDayOfMonth.getMinutes() - firstDayOfMonth.getTimezoneOffset(),
      );
      const lastDayOfMonth = new Date(d.getFullYear(), d.getMonth() + 1, 1);
      lastDayOfMonth.setMinutes(lastDayOfMonth.getMinutes() - lastDayOfMonth.getTimezoneOffset());
      const toDate = i === 0 ? d : lastDayOfMonth;

      return {
        ...a,
        [i + 1]: {
          label,
          dateRange: { from: firstDayOfMonth.toUTCString(), to: toDate.toUTCString() },
          skipIntl: true,
        },
      };
    }, {});
};

export const DEFAULT_HISTORY_FILTER: Record<number, DateFilterModel> = {
  1: {
    dateRange: getDateRangeBetweenDays(new Date(), -30, 0),
    label: 'history.filter.last30days',
  },
  2: {
    dateRange: getDateRangeBetweenDays(new Date(), -60, -30),
    label: 'history.filter.3060days',
  },
  3: {
    dateRange: getDateRangeBetweenDays(new Date(), -90, -60),
    label: 'history.filter.6090days',
  },
  4: {
    dateRange: getDateRangeBetweenDays(new Date(), -120, -90),
    label: 'history.filter.90120days',
  },
  5: {
    dateRange: getDateRangeBetweenDays(new Date(), -150, -120),
    label: 'history.filter.120150days',
  },
  6: {
    dateRange: getDateRangeBetweenDays(new Date(), -180, -150),
    label: 'history.filter.150180days',
  },
};

export const getDateFilterOptions = (locale: string): Record<number, DateFilterModel> => {
  // Up to 180 days intervals are needed only for MGA
  if (locale === 'fi') return DEFAULT_HISTORY_FILTER;
  if (envIsCanada()) return DEFAULT_HISTORY_FILTER;
  if (IS_SWEDEN) return dateFilterOptionsPerMonth(new Date(), 'sv-SE');
  return Object.fromEntries(Object.entries(DEFAULT_HISTORY_FILTER).slice(0, 3));
};

export const mapDateFilterItems = (items: Record<number, DateFilterModel>) =>
  Object.keys(items)
    .map(key => +key)
    .map(key => ({
      id: `${key}`,
      translationId: !items[key].skipIntl ? items[key].label : undefined,
      text: items[key].skipIntl ? items[key].label : undefined,
      props: items[key].dateRange,
    }));
