import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { BFF_URL, envIsCanada } from '@/constants/constants';
import { RootState } from '@/models/root-state.model';
import { Store } from 'redux';
import { BFF_API_URLS } from '@/constants/bff-api-urls';
import { DocumentType } from '@lucky7ventures/lucky-components';
import {
  BffAvailableBonusesRequest,
  BffAvailableBonusesResponse,
  BffCompleteProfileMexicoRequest,
  BffGamesJackpotsRequest,
  BffGamesJackpotsResponse,
  BffGamesRequest,
  BffGamesResponse,
  BffLifetimeDepositsResponse,
  BffRegisterMexicoRequest,
  BffRegisterResponse,
  BffResponse,
  BffVerifyUserResponse,
} from '@lucky7ventures/bff-types';

const axiosInstance = axios.create({
  baseURL: BFF_URL,
});

export function bffApiServiceInterceptor(store: Store) {
  axiosInstance.interceptors.request.use(request => {
    const state: RootState = store.getState();
    const locale = state.common.locale;
    const token = state.auth.token;
    let headers = request.headers;

    // A singular place in the app where we attach the token to the request for API V2
    if (token) {
      headers = { ...headers, 'l7-t': `${token}` };
    }
    headers = {
      ...headers,
      'l7-b': '1', // 1 is for LuckyDays
      'l7-locale': envIsCanada() ? `${locale}-on` : `${locale}`,
    };
    request.headers = headers;
    return request;
  });
}

export type ApiServiceGetMethod<R> = (arg?: {
  config?: AxiosRequestConfig;
}) => Promise<AxiosResponse<R>>;

export type ApiServicePostMethod<P, R> = ({
  payload,
  config,
}: {
  payload: P;
  config?: AxiosRequestConfig<P>;
}) => Promise<AxiosResponse<R>>;

export type ApiServicePostMethodWithPathParams<D, P, R> = ({
  payload,
  pathParams,
  config,
}: {
  payload: D;
  pathParams: P;
  config?: AxiosRequestConfig<D>;
}) => Promise<AxiosResponse<R>>;

export interface BffApiServiceI {
  registerMexico: ApiServicePostMethod<BffRegisterMexicoRequest, BffResponse<BffRegisterResponse>>;
  completeProfileMexico: ApiServicePostMethod<BffCompleteProfileMexicoRequest, BffResponse<null>>;
  verifyUser: ApiServiceGetMethod<BffResponse<BffVerifyUserResponse>>;
  getLifetimeDeposits: ApiServiceGetMethod<BffResponse<BffLifetimeDepositsResponse>>;
  uploadKycDocuments: ApiServicePostMethodWithPathParams<FormData, DocumentType, BffResponse<null>>;
  getAllGames: ApiServicePostMethod<BffGamesRequest, BffResponse<BffGamesResponse>>;
  getGamesJackpots: ApiServicePostMethod<
    BffGamesJackpotsRequest,
    BffResponse<BffGamesJackpotsResponse>
  >;
  getAvailableBonuses: ApiServicePostMethod<
    BffAvailableBonusesRequest,
    BffResponse<BffAvailableBonusesResponse>
  >;
}

const apiPostCall = <P>(url: string, payload: P, config?: AxiosRequestConfig<P>) => {
  return axiosInstance.post(url, payload, config);
};

const BffApiService: BffApiServiceI = {
  registerMexico: ({ payload, config }) =>
    apiPostCall(BFF_API_URLS.REGISTER_MEXICO, payload, config),
  completeProfileMexico: ({ payload, config }) =>
    apiPostCall(BFF_API_URLS.COMPLETE_PROFILE_MEXICO, payload, config),
  verifyUser: arg => apiPostCall(BFF_API_URLS.VERIFY, null, arg?.config),
  getLifetimeDeposits: arg => apiPostCall(BFF_API_URLS.GET_LIFETIME_DEPOSITS, null, arg?.config),
  uploadKycDocuments: ({ payload, pathParams, config }) =>
    apiPostCall(BFF_API_URLS.KYC_DOCUMENTS_UPLOAD(pathParams), payload, config),
  getAllGames: ({ payload, config }) => {
    return apiPostCall(BFF_API_URLS.GET_ALL_GAMES, payload, config);
  },
  getGamesJackpots: ({ payload, config }) => {
    return apiPostCall(BFF_API_URLS.GET_GAMES_JACKPOTS, payload, config);
  },
  getAvailableBonuses: ({ payload, config }) =>
    apiPostCall(BFF_API_URLS.GET_AVAILABLE_BONUSES, payload, config),
};

export default BffApiService;
