import React, { InputHTMLAttributes, useEffect, useRef, useState } from 'react';
import { FieldInputProps, useField } from 'formik';
import { appendDefaultContainerClassNames, FieldError, FieldLabel } from '../field';
import { FieldEndIcon } from '../field/Field';
import FieldIcon from '../field/FieldIcon';
import { useIntl } from 'react-intl';
import { DayInput, DOBInputProps, MonthInput, Separator, YearInput } from './Fields';
import {
  DATE_FORMAT_CANADA_LOCALES,
  DATE_FORMAT_REGULAR_LOCALES,
} from '../../../constants/constants';
import { useSelector } from 'react-redux';
import { selectLocale } from '../../../store/selectors/commonSelectors';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';

function focusTarget(locale: string, field: FieldInputProps<any>) {
  if (DATE_FORMAT_CANADA_LOCALES.includes(locale)) {
    if (field.value.year && !field.value.month) {
      document.getElementById('month')?.focus();
    } else if (field.value.month && !field.value.day && field.value.year) {
      document.getElementById('day')?.focus();
    } else if (!field.value.year) {
      document.getElementById('year')?.focus();
    }
  } else if (DATE_FORMAT_REGULAR_LOCALES.includes(locale)) {
    if (field.value.month && !field.value.day) {
      document.getElementById('day')?.focus();
    } else if (field.value.day && !field.value.year && field.value.month) {
      document.getElementById('year')?.focus();
    } else if (!field.value.month) {
      document.getElementById('month')?.focus();
    }
  } else {
    if (field.value.day && !field.value.month) {
      document.getElementById('month')?.focus();
    } else if (field.value.month && !field.value.year && field.value.day) {
      document.getElementById('year')?.focus();
    } else if (!field.value.day) {
      document.getElementById('day')?.focus();
    }
  }
}

interface DateOfBirthFieldProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onBlur'>,
    FieldEndIcon {
  name: string;
  label: string;
  labelClassName?: string;
  onValidationError?: (error: string) => void;
  onBlur?: ({ day, month, year }: { day: string; month: string; year: string }) => void;
  onFocus?: () => void;
}

function getDateFormat(
  {
    day,
    month,
    year,
  }: {
    day: DOBInputProps;
    month: DOBInputProps;
    year: Omit<DOBInputProps, 'setValue'>;
  },
  locale: string,
) {
  if (DATE_FORMAT_CANADA_LOCALES.includes(locale)) {
    return (
      <>
        <YearInput nextID="month" {...year} />
        {year.value || month.value ? <Separator /> : null}
        <MonthInput nextID="day" prevID="year" {...month} />
        {month.value ? <Separator /> : null}
        <DayInput prevID="month" {...day} />
      </>
    );
  } else if (DATE_FORMAT_REGULAR_LOCALES.includes(locale)) {
    return (
      <>
        <MonthInput {...month} nextID="day" />
        {month.value || day.value ? <Separator /> : null}
        <DayInput {...day} nextID="year" prevID="month" />
        {day.value ? <Separator /> : null}
        <YearInput {...year} prevID="day" />
      </>
    );
  } else {
    return (
      <>
        <DayInput {...day} nextID="month" />
        {day.value || month.value ? <Separator /> : null}
        <MonthInput {...month} nextID="year" prevID="day" />
        {month.value ? <Separator /> : null}
        <YearInput {...year} prevID="month" />
      </>
    );
  }
}

const DateOfBirthField = ({
  className,
  name,
  label,
  labelClassName,
  endIcon,
  endIconAction,
  onValidationError,
  onBlur,
  onFocus,
  disabled,
  ...props
}: DateOfBirthFieldProps) => {
  const intl = useIntl();
  const [field, { error: errorObj, touched }, { setValue }] = useField(name);
  const [controlledFocus, setControlledFocus] = useState(false);
  const [gtmErrorTriggered, setGtmErrorTriggered] = useState(false);
  const error = errorObj as unknown as { day?: string; month?: string; year?: string };
  const errorMessage =
    typeof error === 'string' ? error : error?.day || error?.month || error?.year;
  const locale = useSelector(selectLocale);
  const [dobFocused, setDobFocused] = useState(false);
  const [dobTouched, setDobTouched] = useState(false);
  const dobRef = useRef(null);

  useOnClickOutside(
    dobRef,
    () => {
      if (dobFocused && (!field.value.month || !field.value.day || !field.value.year)) {
        setDobTouched(true);
      }
      setDobFocused(false);
    },
    [dobFocused],
  );

  const handleOnFocus = () => {
    onFocus?.();
    setControlledFocus(true);
  };
  const handleOnBlur = (event: any) => {
    !(field.value.day || field.value.month || field.value.year) && setControlledFocus(false);
    onBlur?.({ day: field.value.day, month: field.value.month, year: field.value.year });
    field.onBlur(event);
  };

  const defaultProps = {
    controlledFocus: controlledFocus,
    fieldName: field.name,
    onBlur: handleOnBlur,
    onChange: field.onChange,
    onFocus: handleOnFocus,
    intl: intl,
    disabled,
  };

  useEffect(() => {
    if (dobTouched && errorMessage) {
      onValidationError?.(errorMessage!);
    }
  }, [errorMessage, dobTouched]);

  return (
    <div
      ref={dobRef}
      className={appendDefaultContainerClassNames(
        className,
        errorMessage,
        dobTouched && !!errorMessage,
      )}
      {...props}
    >
      <div
        id="dob-fields"
        className="flex cursor-text"
        onClick={event => {
          event.stopPropagation();
          focusTarget(locale, field);
          setDobFocused(true);
        }}
        onBlur={() => {
          if (!gtmErrorTriggered && touched && errorMessage) {
            onValidationError?.(errorMessage!);
            setGtmErrorTriggered(true);
          }
        }}
      >
        {getDateFormat(
          {
            day: {
              ...defaultProps,
              value: field.value.day ?? '',
              setValue: (newVal: string) => setValue({ ...field.value, day: newVal }),
            },
            month: {
              ...defaultProps,
              value: field.value.month ?? '',
              setValue: (newVal: string) => setValue({ ...field.value, month: newVal }),
            },
            year: {
              ...defaultProps,
              value: field.value.year ?? '',
            },
          },
          locale,
        )}
      </div>
      <FieldLabel
        className={labelClassName}
        value={field.value.day || field.value.month || field.value.year || controlledFocus}
      >
        {label}
      </FieldLabel>
      <FieldError error={errorMessage} show={Boolean(dobTouched && errorMessage)} />
      {endIcon && <FieldIcon icon={endIcon} onClick={endIconAction} />}
    </div>
  );
};

export default DateOfBirthField;
