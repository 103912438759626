import { create } from 'zustand';

import ApiService from '../shared/apiService';
import { CountryResponseDto } from '../models/apiV2/response/country-response-dto.model';

interface State {
  countries: CountryResponseDto;
  loading: boolean;
  error: boolean;
}

interface Actions {
  fetch: () => void;
}

export const useCountriesStore = create<State & Actions>((set, get) => ({
  countries: {},
  loading: false,
  error: false,
  fetch: async () => {
    const { countries } = get();
    if (Object.keys(countries).length > 0) {
      return;
    }

    set({
      loading: true,
      error: false,
    });
    try {
      const response = await ApiService.getCountries();
      set({
        loading: false,
        error: false,
        countries: response.data,
      });
    } catch {
      set({
        loading: false,
        error: true,
      });
    }
  },
}));
