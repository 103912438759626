import React, { ReactNode } from 'react';

const Masonry = ({ children, ...props }: { children: ReactNode[] }) => (
  <div className="flex flex-shrink-0 space-x-3 w-[93%] md:max-w-[666px]" {...props}>
    <div className="flex flex-col w-[30%] md:max-w-[266px] flex-shrink-0 space-y-3">
      <div className="h-1/2">{children[0]}</div>
      <div className="h-1/2">{children[1]}</div>
    </div>
    <div className="w-full">{children[2]}</div>
  </div>
);

export default Masonry;
