import React, { useEffect, useMemo, useState } from 'react';
import { Duration } from 'luxon';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../models/root-state.model';
import {
  selectActiveSessionLimits,
  selectFutureSessionLimits,
} from '../../../../store/selectors/limitsSelectors';
import { closeModal, openModal, updateModalProps } from '../../../../store/actions';
import { Select, SelectItem } from '../../../../components/Select';
import { LimitDuration, LimitType } from '@lucky7ventures/lucky-components';
import ActiveLimitsTable from './ActiveLimitsTable';
import { FutureLimitsTable } from './FutureLimitsTable';
import { useApiV2Request } from '../../../../hooks/useApiV2Request';
import ApiService from '../../../../shared/apiService';
import { BtnOneLineBlue } from '../../../../components/buttons/BtnOneLineBlue';
import { GetUserLimitModel } from '../../../../models/gig/get-user-limit.model';
import { getLimitsSuccess } from '../../../../store/actions/limitsActions';

const SessionLimits = (): JSX.Element => {
  const intl = useIntl();

  const user = useSelector((state: RootState) => state.user.user);
  const dispatch = useDispatch();
  const futureLimits = useSelector(selectFutureSessionLimits);
  const {
    request: setLimitsRequest,
    error: setLimitsError,
    loading: setLimitsLoading,
    success: setLimitsSuccess,
  } = useApiV2Request<GetUserLimitModel>();
  const activeLimits = useSelector(selectActiveSessionLimits);

  const limitDurationOptions = [
    {
      id: 'None',
      text: intl.formatMessage({ id: 'sessionLimit.select.limit' }),
      props: { duration: LimitDuration.NoDuration },
    },
    {
      id: '24hrs',
      text: intl.formatMessage({ id: 'sessionLimit.range.daily' }),
      props: { duration: LimitDuration._24Hours },
    },
    {
      id: 'weekly',
      text: intl.formatMessage({ id: 'sessionLimit.range.weekly' }),
      props: { duration: LimitDuration._1Week },
    },
    {
      id: 'monthly',
      text: intl.formatMessage({ id: 'sessionLimit.range.monthly' }),
      props: { duration: LimitDuration._1Month },
    },
  ];

  const limitValueOptions: SelectItem[] = useMemo(
    () =>
      [0, 15, 30, 45, 60, 120, 180].map(value => {
        const text =
          value === 0
            ? intl.formatMessage({ id: 'sessionLimit.select.amount' })
            : value < 60
            ? Duration.fromObject({ minutes: value }).toHuman()
            : Duration.fromObject({ hours: value / 60 }).toHuman();

        return {
          id: value.toString(),
          text: text,
          props: {
            value: value,
          },
        };
      }),
    [],
  );

  const [limitDuration, setLimitDuration] = useState<SelectItem>(limitDurationOptions[0]);
  const [limitValue, setLimitValue] = useState<SelectItem>(limitValueOptions[0]);

  function submitHandler() {
    if (!user) {
      return;
    }

    dispatch(
      openModal('confirm', {
        header: intl.formatMessage({ id: 'sessionLimit.confirm.header' }),
        text: intl.formatMessage({
          id:
            limitDuration.props.duration === LimitDuration._24Hours
              ? 'sessionLimit.confirm.daily'
              : limitDuration.props.duration === LimitDuration._1Week
              ? 'sessionLimit.confirm.weekly'
              : 'sessionLimit.confirm.monthly',
        }),
        buttonText: intl.formatMessage({ id: 'sessionLimit.button' }),
        onSubmit: () => {
          const userLimits = [
            {
              type: LimitType.Session,
              duration: limitDuration.props.duration,
              amount: limitValue.props.value,
            },
          ];
          setLimitsRequest(ApiService.setLimits, userLimits, data => {
            dispatch(closeModal());
            dispatch(getLimitsSuccess(data));
          });
        },
      }),
    );
  }

  useEffect(() => {
    if (setLimitsError?.error) {
      const messageId = setLimitsError.errorId === 338 ? 'error.setLimits.338' : 'error.support';
      dispatch(
        updateModalProps({
          error: `${intl.formatMessage({ id: messageId })} [${setLimitsError.errorId}]`,
        }),
      );
    }

    if (!setLimitsError) {
      dispatch(updateModalProps({ error: undefined }));
    }
  }, [setLimitsError]);

  useEffect(() => {
    dispatch(updateModalProps({ loading: setLimitsLoading }));
  }, [setLimitsLoading]);

  useEffect(() => {
    if (setLimitsSuccess) {
      setLimitDuration(limitDurationOptions[0]);
      setLimitValue(limitValueOptions[0]);
      dispatch(updateModalProps({ loading: false, error: undefined }));
    }
  }, [setLimitsSuccess]);

  return (
    <div>
      <h2 className="mb-4">{intl.formatMessage({ id: 'sessionLimit.header' })}</h2>
      <p>{intl.formatMessage({ id: 'sessionLimit.text' })}</p>
      {activeLimits && activeLimits.length > 0 && (
        <div>
          <h6 className="mb-2 font-semibold text-gray-600">
            {intl.formatMessage({ id: 'depositLimit.currentLimits' })}
          </h6>
          <ActiveLimitsTable activeLimits={activeLimits} />
        </div>
      )}
      {futureLimits.length > 0 && (
        <div className="mt-8">
          <h6 className="mb-2 font-semibold text-gray-600">
            {intl.formatMessage({ id: 'depositLimit.futureLimits' })}
          </h6>
          <FutureLimitsTable futureLimits={futureLimits} />
        </div>
      )}
      <div className="form">
        <div className="flex justify-center">
          <div className="flex flex-col xs:flex-row w-full xs:w-3/4 sm:w-2/3 xs:justify-between xs:space-x-2 space-y-3 xs:space-y-0">
            <div className="w-full xs:w-[175px]">
              <Select
                items={limitDurationOptions}
                onChangeHandler={option => setLimitDuration(option)}
                selectedItem={limitDuration}
                size="md"
              />
            </div>
            <div className="w-full xs:w-[175px]">
              <Select
                items={limitValueOptions}
                onChangeHandler={option => setLimitValue(option)}
                selectedItem={limitValue}
                size="md"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center">
        <div>
          <BtnOneLineBlue
            className="min-h-0 py-4 px-6"
            type="submit"
            onClickHandler={() => submitHandler()}
            disabled={
              limitDuration.props.duration === LimitDuration.NoDuration ||
              limitValue.props.value === 0
            }
            text={intl.formatMessage({ id: 'sessionLimit.button' })}
          />
        </div>
      </div>
    </div>
  );
};

export default SessionLimits;
