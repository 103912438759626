import React, { useEffect, useState } from 'react';

import { IntlShape, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { Select, SelectItem } from '../../../../components/Select';
import { envIsCanada } from '../../../../constants/constants';
import { useAddTimeout } from '../../../../hooks/useAddTimeOut';
import {
  selectTimeoutLegislationDurations,
  selectTimeoutLegislationDurationsError,
  selectTimeoutLegislationDurationsLoading,
} from '../../../../store/selectors/userExtrasSelectors';
import { getTimeoutLegislationDurations } from '../../../../store/actions/userExtrasActions';
import { openModal, updateModalProps } from '../../../../store/actions';
import { TranslatedRichText } from '../../../../components/TranslatedRichText';
import { ButtonWithLoader } from '../../../../components/Common/ButtonWithLoader';

type TIMEOUT_ID = '24hours' | '48hours' | '1week' | '1month' | '2months' | '3months';

const ARBITRARY_DATES: Record<TIMEOUT_ID, string> = {
  '24hours': DateTime.utc().plus({ hours: 24 }).toISO(),
  '48hours': DateTime.utc().plus({ hours: 48 }).toISO(),
  '1week': DateTime.utc().plus({ weeks: 1 }).toISO(),
  '1month': DateTime.utc().plus({ months: 1 }).toISO(),
  '2months': DateTime.utc().plus({ months: 2 }).toISO(),
  '3months': DateTime.utc().plus({ months: 3 }).toISO(),
};

const availableTimeouts = (intl: IntlShape): SelectItem[] => {
  const allTimeouts: Record<number, SelectItem> = {
    1: {
      id: '24hours',
      text: `24 ${intl.formatMessage({ id: 'misc.hours' })}`,
    },
    2: {
      id: '1week',
      text: `1 ${intl.formatMessage({ id: 'misc.week' })}`,
    },
    3: {
      id: '1month',
      text: `1 ${intl.formatMessage({ id: 'misc.month' })}`,
    },
    4: {
      id: '2months',
      text: `2 ${intl.formatMessage({ id: 'misc.months' })}`,
    },
    5: {
      id: '3months',
      text: `3 ${intl.formatMessage({ id: 'misc.months' })}`,
    },
  };

  const reduceOptions = (options: number[]) => {
    return options.reduce((a: SelectItem[], i) => {
      const timeout = allTimeouts[i];
      a.push(timeout);
      return a;
    }, []);
  };

  if (envIsCanada()) {
    return reduceOptions([1, 2, 3, 4, 5]);
  }

  return reduceOptions([1, 2, 3]);
};

const TimeOut = (): JSX.Element => {
  const intl = useIntl();
  const [selectedTimeOut, setSelectedTimeOut] = useState<SelectItem>(availableTimeouts(intl)[0]);
  const dispatch = useDispatch();
  const { loading, error, addTimeout } = useAddTimeout();
  const legislationDurations = useSelector(selectTimeoutLegislationDurations);
  const legislationDurationsLoading = useSelector(selectTimeoutLegislationDurationsLoading);
  const legislationDurationsError = useSelector(selectTimeoutLegislationDurationsError);

  useEffect(() => {
    dispatch(getTimeoutLegislationDurations());
  }, []);

  useEffect(() => {
    dispatch(updateModalProps({ loading, error }));
  }, [loading, error]);

  function submitHandler(): void {
    if (!selectedTimeOut) {
      return;
    }

    // get the timeoutCategoryID for the selected timeout option, a bit of a hacky code here - I'm not too proud :S
    const duration = legislationDurations.find(i => {
      if (selectedTimeOut.id === '24hours') {
        return i.days === 1;
      }

      if (selectedTimeOut.id === '1week') {
        return i.days === 7;
      }

      if (selectedTimeOut.id === '1month') {
        return i.months === 1;
      }

      if (selectedTimeOut.id === '2months') {
        return i.months === 2;
      }

      if (selectedTimeOut.id === '3months') {
        return i.months === 3;
      }

      return false;
    });

    dispatch(
      openModal('confirm', {
        header: intl.formatMessage({ id: 'responsibleGaming.takeABreak.confirm.header' }),
        text: `${intl.formatMessage({
          id: 'responsibleGaming.selfExclusion.confirm.text',
        })} ${selectedTimeOut.text}.`,
        buttonText: intl.formatMessage({ id: 'responsibleGaming.takeABreak.submitButton' }),
        onSubmit: () =>
          addTimeout({
            timeoutCategoryID: duration?.categoryId,
            endDate: ARBITRARY_DATES[selectedTimeOut.id as TIMEOUT_ID],
          }),
      }),
    );
  }

  return (
    <div>
      <h2 className="mb-6">{intl.formatMessage({ id: 'responsibleGaming.takeABreak.header' })}</h2>
      <TranslatedRichText id="responsibleGaming.takeABreak.content" tagName="div" />
      <div className="mx-auto w-full max-w-xs pt-4">
        <Select
          items={availableTimeouts(intl)}
          selectedItem={selectedTimeOut}
          onChangeHandler={item => setSelectedTimeOut(item)}
          size="md"
        />
      </div>

      <div className="flex justify-around">
        <div className="max-w-xs flex-1">
          <ButtonWithLoader
            onClick={submitHandler}
            disabled={legislationDurationsLoading || legislationDurationsError}
            loading={loading}
            message={intl.formatMessage({ id: 'responsibleGaming.takeABreak.header' })}
          />
        </div>
      </div>
    </div>
  );
};

export default TimeOut;
