import axios from 'axios';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { envIsSweden } from '../constants/constants';
import { PNP_PROVIDER } from '../constants/constants';
import { PNP_CURRENCY } from '../constants/paynplayConstants';
import { payAndPlayIframeUrl } from '../shared/utility';
import { selectLocale } from '../store/selectors/commonSelectors';
import { useCancelToken } from './useCancelToken';
import { selectUserBtag } from '../store/selectors/combined.selectors';

const welcomeOfferBonusCode = (): string | null => {
  if (envIsSweden()) {
    return process.env.NODE_ENV === 'production' ? 'WELCOME1SE' : 'WELCOMESWEDTEST1';
  }

  return null;
};

export function usePayNPlayIframe(welcomeBonus: boolean): {
  getIframeUrl: (amount: number | null) => void;
  loading: boolean;
  error: boolean;
  iframeUrl: string | null;
  resetIframe: () => void;
} {
  const locale = useSelector(selectLocale);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [iframeUrl, setIframeUrl] = useState<string | null>(null);
  const btag = useSelector(selectUserBtag);
  const { newCancelToken } = useCancelToken();

  const getIframeUrl = async (amount: number | null) => {
    setLoading(true);

    try {
      let url;

      const bonusCode = !amount || !welcomeBonus ? null : welcomeOfferBonusCode();

      if (envIsSweden()) {
        url = payAndPlayIframeUrl(PNP_CURRENCY, 'SE', amount, btag, 'swe', bonusCode, PNP_PROVIDER);
      } else {
        const lang = locale === 'fi-en' ? 'eng' : 'fin';
        url = payAndPlayIframeUrl(PNP_CURRENCY, 'FI', amount, btag, lang, bonusCode, PNP_PROVIDER);
      }

      const { data } = await axios.get(url, { cancelToken: newCancelToken() });

      if (data.success) {
        const redirectUrl = data.redirectOutput.url;
        setLoading(false);
        setIframeUrl(redirectUrl);
      }
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  };

  const resetIframe = () => {
    setIframeUrl(null);
  };

  return { getIframeUrl, loading, error, iframeUrl, resetIframe };
}
