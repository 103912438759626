import { Listbox, Transition } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { useIntl } from 'react-intl';
import { classNames } from '../utils/utils';

interface SelectProps {
  items: SelectItem[];
  onChangeHandler: (item: SelectItem) => void;
  selectedItem: SelectItem;
  size?: 'sm' | 'md';
}

export interface SelectItem {
  id: string;
  text?: string;
  translationId?: string;
  props?: any;
}

export function Select({
  items,
  selectedItem,
  onChangeHandler,
  size = 'sm',
}: SelectProps): JSX.Element {
  const intl = useIntl();

  function getLabel(item: SelectItem): string {
    if (item.translationId) {
      return intl.formatMessage({ id: item.translationId });
    }

    if (item.text) {
      return item.text;
    }

    return item.id;
  }

  return (
    <Listbox value={selectedItem} onChange={onChangeHandler}>
      {({ open }) => (
        <div className="relative">
          <Listbox.Button
            className={`relative w-full py-2 pl-3 pr-10 text-${size} cursor-pointer rounded-md bg-white text-left font-bold shadow-md`}
          >
            <span className="block truncate capitalize">{getLabel(selectedItem)}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </Listbox.Button>
          <Transition
            show={open}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              className={`absolute z-10 mt-1 w-full overflow-auto p-1 text-${size} max-h-60 rounded-md bg-white shadow-lg`}
            >
              {items.map(item => (
                <Listbox.Option
                  key={item.id}
                  value={item}
                  className={({ active, selected }) =>
                    classNames(
                      selected ? 'font-bold' : 'font-normal',
                      active ? 'bg-cyan-500 text-white' : 'text-gray-900',
                      'relative mb-0 cursor-pointer select-none rounded-md p-2 font-bold capitalize',
                    )
                  }
                >
                  {getLabel(item)}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}
