import React from 'react';
import classNames from 'classnames';

interface ImageBannerProps {
  heading: string;
  buttonOnClick: () => void;
  buttonText: string;
  desktopImage: string;
  mobileImage: string;
}

const ImageBanner = ({
  buttonOnClick,
  buttonText,
  heading,
  desktopImage,
  mobileImage,
}: ImageBannerProps) => (
  <div className="min-w-[100vw] min-h-[220px] lg:min-h-[350px] snap-start">
    <div className="w-full relative">
      <div>
        <picture>
          <source media="(max-width: 760px)" srcSet={mobileImage} />
          <img
            className=" object-cover object-right lg:object-right-bottom"
            alt="banner_image"
            src={desktopImage}
          />
        </picture>
        <div className="absolute inset-0"></div>
      </div>
      <div className="absolute inset-y-0 flex flex-col space-y-3 lg:space-y-8 justify-center w-[59%] lg:w-[80%] px-4 lg:pl-[12%]">
        <h3 className="hidden max-w-[450px] text-justify lg:block m-0 text-white">{heading}</h3>
        <h4 className="m-0 text-[15.3px] lg:hidden text-white">{heading}</h4>
        <button
          onClick={buttonOnClick}
          className={classNames(
            'px-2 py-1.5 bg-black/40 rounded-lg backdrop-blur-lg border-[1px] border-gray-400 text-white font-bold w-fit',
            'text-sm lg:text-base',
            'ease-in-out transition-all duration-200 active:scale-[98%] hover:scale-[102%]',
          )}
          type="button"
        >
          {buttonText}
        </button>
      </div>
    </div>
  </div>
);

export default ImageBanner;
