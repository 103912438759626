import React from 'react';
import { useIntl } from 'react-intl';
import { AbsoluteLink } from '../AbsoluteLink';
import { CDN_BASE, CDN_IMAGES_COMMON_PATH } from '../../constants/constants';

export function NotFound(): JSX.Element {
  const intl = useIntl();
  return (
    <div className="relative flex min-h-screen items-center justify-center text-center">
      <img
        className="pointer-events-none absolute top-0 right-0 h-full w-full object-cover object-right-bottom"
        src={`${CDN_BASE}/cdn-cgi/image/fit=cover,width=1440,height=900,gravity=0x1,format=auto,brightness=0.5${CDN_IMAGES_COMMON_PATH}/luckydays-tokyo.jpg`}
        alt="Lucky the cat in a back alley"
      />
      <div className="relative p-4">
        <h1 className="mb-6 text-4xl text-white md:text-6xl">
          {intl.formatMessage({ id: '404.header' })}
        </h1>
        <p className="text-xl text-white">{intl.formatMessage({ id: '404.text' })}</p>
        <AbsoluteLink
          className="relative mx-2 mt-8 mb-4 inline-flex w-52 justify-center rounded-full bg-cyan-500 py-4 px-6 font-bold text-white shadow hover:bg-cyan-600 hover:shadow-xl"
          to={'/'}
        >
          {intl.formatMessage({ id: '404.button' })}
        </AbsoluteLink>
      </div>
    </div>
  );
}
