import React, { ReactNode } from 'react';

import { useDispatch } from 'react-redux';

import { openModal } from '../../store/actions';
import { FormattedMessage } from 'react-intl';
import { useAbsoluteNavigate } from '../../hooks/useAbsoluteNavigate';

const DisclaimerButton = ({
  text,
  clickCallback,
}: {
  text: ReactNode;
  clickCallback: () => void;
}) => {
  return (
    <button
      type="button"
      className="underline text-white sm:text-slate-700 text-sm italic"
      onClick={clickCallback}
    >
      {text}
    </button>
  );
};

export function PayNPlayTCs() {
  const dispatch = useDispatch();
  const navigate = useAbsoluteNavigate();

  const handleOnBonusTermsClick = () => {
    dispatch(openModal('bonusTermsAndConditions'));
  };

  const openTerms = (): void => {
    navigate('/terms-and-conditions');
  };

  const openPrivacyPolicy = (): void => {
    navigate('/privacy-policy');
  };

  return (
    <p>
      <FormattedMessage
        id="paynplay.modal.disclaimer"
        values={{
          terms: str => <DisclaimerButton clickCallback={openTerms} text={str} />,
          bonusterms: str => (
            <DisclaimerButton clickCallback={handleOnBonusTermsClick} text={str} />
          ),
          privacy: str => <DisclaimerButton clickCallback={openPrivacyPolicy} text={str} />,
        }}
      ></FormattedMessage>
    </p>
  );
}
