import { useState } from 'react';
import { axiosInstance as axios } from '../shared/axiosInstance';
import { useCancelToken } from './useCancelToken';
import { getAxiosError } from '../shared/utility';
import { useTranslate } from './useTranslate';
import { ApiError } from '../models/api-error.model';

type SimpleRequest<T, P> = (
  url: string,
  payload: P,
  successCallback?: (data: T) => void,
  errorCallback?: (error: ApiError) => void,
) => void;

interface Return<T, P> {
  loading: boolean;
  success: boolean;
  error: string | null;
  data: T | null;
  request: SimpleRequest<T, P>;
  reset: () => void;
}

export function useSimpleRequest<T, P>(): Return<T, P> {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<string | null>(null);
  const { newCancelToken } = useCancelToken();
  const { translateError } = useTranslate();

  const request = (
    url: string,
    payload: P,
    successCallback?: (data: T) => void,
    errorCallback?: (error: ApiError) => void,
  ): void => {
    setLoading(true);
    setSuccess(false);
    setError(null);
    axios
      .post<T>(url, payload, {
        cancelToken: newCancelToken(),
      })
      .then(response => {
        setData(response.data);
        setSuccess(true);
        if (successCallback) {
          successCallback(response.data);
        }
      })
      .catch(error => {
        const apiError = getAxiosError(error);
        const err = translateError(apiError);
        setError(err);
        if (errorCallback) {
          errorCallback(apiError);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const reset = (): void => {
    setLoading(false);
    setError(null);
    setData(null);
    setSuccess(false);
  };

  return { loading, success, error, data, request, reset };
}
