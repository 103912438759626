import React, { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import { GamePlayable } from '@/hooks/useGameUrl';
import { useAbsoluteNavigate } from '@/hooks/useAbsoluteNavigate';
import { openModal } from '@/store/actions';
import { IS_SWEDEN } from '@/constants/constants';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';

export function GameRestricted({ playable }: { playable: GamePlayable }): JSX.Element | null {
  const intl = useIntl();
  const dispatch = useDispatch();
  const absoluteNavigate = useAbsoluteNavigate();
  const [header, setHeader] = useState('');
  const [message, setMessage] = useState('');
  const [btnText, setBtnText] = useState('');
  const [btnCallback, setBtnCallback] = useState<() => void>();
  const navigate = useAbsoluteNavigate();

  const openContactInfoModal = () => {
    IS_SWEDEN ? dispatch(openModal('contactInfoSweden')) : dispatch(openModal('contactInfo'));
  };

  const openVerify = () => {
    navigate('/settings/verify');
  };

  const openUpdateUser = () => {
    dispatch(openModal('completeProfile'));
    triggerGTag(GTagEvents.complete_profile_game_click);
  };

  const handleAmlVerify = () => {
    absoluteNavigate('/settings/verify');
  };

  useEffect(() => {
    switch (playable) {
      case GamePlayable.MissingKyc: {
        setHeader(intl.formatMessage({ id: 'game.verify.restricted.header' }));
        setBtnText(intl.formatMessage({ id: 'game.verify.restricted.button' }));
        setMessage('');
        setBtnCallback(() => openVerify);
        return;
      }
      case GamePlayable.MissingBankIdProfile: {
        setHeader(intl.formatMessage({ id: 'game.paynplay.restricted.header' }));
        setBtnText(intl.formatMessage({ id: 'game.paynplay.restricted.button' }));
        setMessage('');
        setBtnCallback(() => openContactInfoModal);
        return;
      }
      case GamePlayable.HasFastRegTag: {
        setHeader(intl.formatMessage({ id: 'completeProfile.prompt.header' }));
        setBtnText(intl.formatMessage({ id: 'completeProfile.prompt.button' }));
        setMessage(intl.formatMessage({ id: 'completeProfile.prompt.text' }));
        setBtnCallback(() => openUpdateUser);
        return;
      }
      case GamePlayable.AMLVerifyRequired: {
        setBtnText(intl.formatMessage({ id: 'amlBlock.cta' }));
        setMessage(intl.formatMessage({ id: 'amlBlock.text' }));
        setBtnCallback(() => handleAmlVerify);
        return;
      }
    }
  }, [playable]);

  if (playable === GamePlayable.Checking || playable === GamePlayable.Yes) {
    return null;
  }

  return (
    <div className="absolute left-0 right-0 bottom-0 top-0 flex flex-col items-center justify-center bg-black">
      <div className="m-4 text-center">
        {header && <div className="mb-8 text-2xl">{header}</div>}
        {message && <p className="mb-8">{message}</p>}
        {btnCallback && (
          <button
            type="button"
            onClick={btnCallback}
            className="mx-auto mt-8 mb-4 block h-14 w-full cursor-pointer rounded-full bg-cyan-500 font-bold text-white shadow-md transition hover:bg-cyan-600 hover:shadow-lg disabled:cursor-not-allowed disabled:opacity-60 md:px-4"
          >
            {btnText}
          </button>
        )}
      </div>
    </div>
  );
}
