import { useDispatch, useSelector } from 'react-redux';
import {
  getAvailableBonuses,
  getUserBonuses,
  getUserDepositCount,
  getUserWallet,
} from '../store/actions';
import { getLastDeposit } from '../store/actions/lastDepositActions';
import { selectAuthToken } from '../store/selectors/authSelectors';
import { getLimits } from '../store/actions/limitsActions';

export function useUpdateUser(): {
  updateUser: () => void;
  updateUserWithTimeout: (timeout: number) => void;
} {
  const token = useSelector(selectAuthToken);
  const dispatch = useDispatch();

  const updateUser = () => {
    dispatch(getUserDepositCount());
    dispatch(getUserWallet());
    dispatch(getUserBonuses());
    dispatch(getAvailableBonuses());
    dispatch(getLastDeposit(token!));
    dispatch(getLimits());
  };

  const updateUserWithTimeout = (timeout: number): void => {
    setTimeout(() => {
      updateUser();
    }, timeout);
  };

  return { updateUser, updateUserWithTimeout };
}
