import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { selectIsAuthenticated } from '../store/selectors/authSelectors';
import { selectIsDRConsented } from '../store/selectors/consentsSelectors';
import { selectAutoLoginFinished } from '../store/selectors/autoLoginSelectors';
import { envIsCanada } from '../constants/constants';
import { openModal } from '../store/actions';

export function useDetailsReverification() {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isLoginFinished = useSelector(selectAutoLoginFinished);
  const isDRConsented = useSelector(selectIsDRConsented);
  const dispatch = useDispatch();

  useEffect(() => {
    // We want to show the modal only if:
    // 1. Build is Ontario
    // 2. User is logged in
    // 4. DETAILS_REVERIFICATION consent is not consented
    if (envIsCanada() && isAuthenticated && isLoginFinished && isDRConsented === false) {
      dispatch(openModal('detailsReverification'));
    }
  }, [isAuthenticated, isLoginFinished, isDRConsented]);
}
