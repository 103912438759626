import { Action } from 'redux';
import { IpDataModel } from '../../models/ip-data.model';

export enum CommonActionType {
  SetLocale = 'SET_LOCALE',
  LoadZendesk = 'LOAD_ZENDESK',
  SetMigrationLoading = 'SET_MIGRATION_LOADING',
  SetFitToPlay = 'SET_FIT_TO_PLAY',
  SetIpData = 'SET_IP_DATA',
  SetBtag = 'SET_BTAG',
}

export type SetLocaleAction = Action<CommonActionType.SetLocale> & { locale: string };
export type LoadZendeskAction = Action<CommonActionType.LoadZendesk>;
export type SetMigrationLoadingAction = Action<CommonActionType.SetMigrationLoading> & {
  migrationLoading: boolean;
};
export type SetFitToPlayAction = Action<CommonActionType.SetFitToPlay> & { fitToPlay: boolean };
export type SetIpDataAction = Action<CommonActionType.SetIpData> & { data: IpDataModel };
export type SetBtag = Action<CommonActionType.SetBtag> & { btag: string };

export type CommonActions =
  | SetLocaleAction
  | LoadZendeskAction
  | SetMigrationLoadingAction
  | SetFitToPlayAction
  | SetIpDataAction
  | SetBtag;

export const setLocale = (locale: string): SetLocaleAction => ({
  type: CommonActionType.SetLocale,
  locale,
});

export const loadZendesk = (): LoadZendeskAction => ({ type: CommonActionType.LoadZendesk });

export const setFitToPlay = (fitToPlay: boolean): SetFitToPlayAction => ({
  type: CommonActionType.SetFitToPlay,
  fitToPlay,
});

export const setMigrationLoading = (loading: boolean): SetMigrationLoadingAction => ({
  type: CommonActionType.SetMigrationLoading,
  migrationLoading: loading,
});

export const setIpData = (data: IpDataModel): SetIpDataAction => ({
  type: CommonActionType.SetIpData,
  data,
});

export const setBtag = (btag: string): SetBtag => ({
  type: CommonActionType.SetBtag,
  btag,
});
