import React, { lazy, Suspense, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import GlobalStyles from './components/Common/GlobalStyles';
import DrawerContainer from './components/Drawers/DrawerContainer2';
import { Footer } from '@/components/Layout/Footer';
import TabBar from './components/Layout/TabBar';
import ModalContainer from './components/Modals/ModalContainer';
import { Outlet } from 'react-router-dom';
import { usePingLogin } from '@/hooks/usePingLogin';
import {
  getHomeMetaDescByLocale,
  getHomeTitleByLocale,
  getHtmlLangByLocale,
} from '@/shared/utility';
import { Zendesk } from '@/components/Zendesk';
import { Spelpaus } from '@/components/Spelpaus';
import { APP_VERSION, envIsCanada, envIsSweden } from '@/constants/constants';
import { useStatsAndLimits } from '@/hooks/useStatsAndLimits';
import { SearchPalette } from '@/components/SearchPalette';
import { selectIsAuthenticated } from '@/store/selectors/authSelectors';
import { useSearchParams } from '@/hooks/useSearchParams';
import { useConsents } from '@/hooks/useConsents';
import { useOntarioLoginMigration } from '@/hooks/useOntarioLoginMigration';
import { useLocationSmart } from '@/hooks/useLocationSmart';
import { useOntarioCheck } from '@/hooks/useOntarioCheck';
import { useIncompleteDataCheck } from '@/hooks/useIncompleteDataCheck';
import { useRequiredDepositLimitsCheck } from '@/hooks/useRequiredDepositLimitsCheck';
import { usePepHioHook } from '@/hooks/usePepHioHook';
import { mapLocaleToLang } from '@/utils/utils';
import { Settings } from 'luxon';
import CookieConsentManager from './features/cookie-consent/CookieConsentManager';
import useIsFluidEnabled from './hooks/useIsFluidEnabled';
import { selectUserId } from '@/store/selectors/userSelectors';
import { buildLocales } from '@/constants/locales';
import { Header } from './components/shared';
import { autoLogin } from '@/store/actions/autoLoginActions';
import { useLifetimeDeposits } from '@/hooks/useLifetimeDeposits';

const CashierContainer = lazy(() => import('./features/cashier/CashierContainer'));
const FluidPaymentsWrapper = lazy(() => import('./features/cashier/fluid/FluidPaymentsWrapper'));

export function App(): JSX.Element {
  const locale = useSelector((state: any) => state.common.locale);
  const userId = useSelector(selectUserId);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const pathWithoutQuery = window.location.origin + window.location.pathname;
  const isFluidEnabled = useIsFluidEnabled();
  const dispatch = useDispatch();

  useEffect(() => {
    Settings.defaultLocale = mapLocaleToLang(locale);
  }, [locale]);

  useStatsAndLimits();
  usePingLogin();
  useSearchParams();
  useConsents();
  useLocationSmart();
  useOntarioLoginMigration();
  useOntarioCheck();
  useIncompleteDataCheck();
  useRequiredDepositLimitsCheck();
  usePepHioHook();
  useLifetimeDeposits();

  useEffect(() => {
    // Initiate auto login flow
    dispatch(autoLogin());
  }, []);

  useEffect(() => {
    // Scroll to top of page when the user is logging in
    if (isAuthenticated) {
      window.scrollTo(0, 0);
    }
  }, [isAuthenticated]);

  return (
    <>
      <Helmet>
        <html lang={getHtmlLangByLocale(locale)} />
        <title>{getHomeTitleByLocale(locale)}</title>
        <meta name="description" content={getHomeMetaDescByLocale(locale)} />
        {APP_VERSION && <meta name="luckydays_version" content={APP_VERSION} />}
        {envIsCanada() && (
          <>
            <script
              type="text/javascript"
              src="https://servicesbl.luckydays.ca/v4/html5/?version=v2"
            ></script>
            <script type="text/javascript" src="/toolkit.js"></script>
          </>
        )}
        <link rel="canonical" href={pathWithoutQuery} />
        {!envIsSweden() &&
          buildLocales().map(loc => (
            <link
              key={`${loc}`}
              rel="alternate"
              href={pathWithoutQuery.replace(locale, loc)}
              hrefLang={getHtmlLangByLocale(loc)}
            />
          ))}
      </Helmet>
      <GlobalStyles />
      {envIsSweden() && <Spelpaus />}
      <Header />
      <main className="min-h-screen">
        <Outlet />
      </main>
      <Footer />
      <CookieConsentManager />
      <TabBar />
      <DrawerContainer />
      <ModalContainer />
      <SearchPalette />
      <Suspense fallback={<div></div>}>
        {!isFluidEnabled && isAuthenticated && userId && <CashierContainer />}
        {isFluidEnabled && <FluidPaymentsWrapper />}
      </Suspense>
      <Zendesk locale={locale} />
    </>
  );
}
