import { WpBonusContentModel } from '../../models/wp-bonus-content.model';
import axios, { AxiosResponse } from 'axios';
import {
  BonusesContentActionTypes,
  GetBonusContentAction,
  getBonusContentError,
  getBonusContentSuccess,
} from '../actions/bonusesContentActions';
import { all, AllEffect, call, ForkEffect, put, select, takeEvery } from 'redux-saga/effects';
import { selectBonusesContent } from '../selectors/bonusesContentSelector';
import { isSuccessfulWpResponse, wpApiUrl } from '../../utils/utils';
import { BonusesContentStateModel } from '../reducers/bonusesContentReducer';

export function* getBonusContentSaga(action: GetBonusContentAction) {
  const { bonusId, locale } = action;

  // check if the bonus content for that locale is already available
  const bonusesContent: BonusesContentStateModel = yield select(selectBonusesContent);
  if (bonusesContent && bonusesContent[locale] && bonusesContent[locale][bonusId]) {
    return;
  }

  try {
    const response: AxiosResponse<WpBonusContentModel> = yield call(
      axios.get,
      wpApiUrl(locale, `/bonuses/${bonusId}`),
    );

    if (isSuccessfulWpResponse<WpBonusContentModel>(response)) {
      yield put(getBonusContentSuccess(bonusId, locale, response.data));
    } else {
      yield put(getBonusContentError(bonusId, locale));
    }
  } catch {
    yield put(getBonusContentError(bonusId, locale));
  }
}

export function* watchBonusesContent(): Generator<AllEffect<ForkEffect<never>>, void> {
  yield all([takeEvery(BonusesContentActionTypes.Get, getBonusContentSaga)]);
}
