import React from 'react';

import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import { isBrowser } from 'react-device-detect';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Anchor from '../UI/Buttons/Anchor';
import Button from '../UI/Buttons/Button';
import PromptMessage from '../UI/PromptMessage';
import Spinner from '../UI/Spinner';
import { AbsoluteNavigate } from '../AbsoluteNavigate';
import { isEmpty } from '@/shared/utility';
import * as actions from '../../store/actions';
import { selectLocale } from '@/store/selectors/commonSelectors';
import { selectUser } from '@/store/selectors/userSelectors';
import { IMAGES_BASE } from '@/constants/constants';
import { formatCurrency } from '@/utils/currencyUtils';
import { useCashierShow } from '@/features/cashier/useCashier';
import { useApiV2Request } from '@/hooks/useApiV2Request';
import ApiService from '../../shared/apiService';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';
import { BonusRedeemType } from '@lucky7ventures/bff-types';

const StyledBonusCard = styled.div`
  border-radius: ${props => props.theme.borderRadius};
  background: ${props => props.theme.colors.white};
  margin: 16px;
  position: relative;
  overflow: hidden;
  max-width: 350px;
  min-width: 250px;
  width: 100%;
  transition: all 0.2s ease;
  padding: 48px 32px 16px;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  flex: 1;

  @media (min-width: ${props => props.theme.breakpoints.small}) {
    margin: 16px;
    margin-top: -64px;
    margin-bottom: calc(64px + 16px);
  }

  .anchor {
    position: absolute;
    top: -20px;
    left: 0;

    @media (min-width: ${props => props.theme.breakpoints.small}) {
      top: -100px;
    }
  }

  img {
    width: 100%;
    max-width: 90px;
    margin: 0 auto 10px;
    height: auto;
  }

  .bonusType {
    color: ${props => props.theme.colors.greenHover};
    font-weight: bold;
    font-size: 14px;
    border-radius: 50vh;
    display: inline-block;
    justify-self: flex-end;
    margin-bottom: 16px;
  }

  .bonus-content {
    position: relative;
    display: flex;
    justify-content: space-between;
    text-align: center;
    flex-flow: column wrap;
    flex: 1;

    .bonusName {
      margin-top: 0;
      color: #222;
      font-size: 22px;
      margin-bottom: 16px;
    }

    .bonusDesc {
      margin-bottom: 0;
      font-style: italic;
      font-weight: bold;
      opacity: 0.8;
    }

    .bonus-buttons {
      display: flex;
      justify-content: center;
      flex-flow: row wrap;
      margin-top: 10px;

      button,
      a {
        width: 100%;
      }

      button:not(.terms-and-conditions-link),
      a:not(.terms-and-conditions-link) {
        background: ${props => props.theme.colors.green};
        margin: 0;

        &:hover {
          background: ${props => props.theme.colors.greenHover};
        }
      }

      .terms-and-conditions-link {
        color: black;
        background: none;
        font-size: 14px;
        font-weight: bold;
        margin: 0;
        padding: 16px;
        transition: opacity 0.2s ease;
        display: block;
        width: 100%;
        text-decoration: none;
        opacity: 0.6;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
`;

function BonusCard({
  bonusCode,
  id,
  name,
  image,
  desc,
  bonusType,
  redeemTypeId,
  bonusTerms,
  availableUntilText,
  desktopGameLink,
  mobileGameLink,
  userBonusId,
  shortTerms,
  realBonusMoney,
  bonusMoney,
}) {
  const locale = useSelector(selectLocale);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const intl = useIntl();
  const {
    request,
    loading: claimBonusLoading,
    error: claimBonusError,
    success: claimBonusSuccess,
  } = useApiV2Request();
  const showCashier = useCashierShow();

  const openCashierHandler = () => {
    showCashier({ bonusCode });
  };

  function forfeitHandler(userBonusId) {
    dispatch(actions.updateModalProps({ loading: true }));
    request(
      ApiService.deleteBonus,
      { userBonusId },
      () => {
        dispatch(actions.getUserBonuses());
        dispatch(actions.getUserWallet());
        dispatch(actions.getAvailableBonuses());
        dispatch(actions.closeModal());
        dispatch(actions.updateModalProps({ loading: false }));
      },
      error => {
        dispatch(actions.updateModalProps({ loading: false, error: error.error }));
      },
    );
  }

  function openModalHandler(header, userBonusId) {
    const bonusMoneyFormatted = formatCurrency(bonusMoney, locale, user.Currency);
    const realBonusMoneyFormatted = formatCurrency(realBonusMoney, locale, user.Currency);
    if (realBonusMoney > 0) {
      dispatch(
        actions.openModal('confirm', {
          header: intl.formatMessage({ id: 'activeBonuses.confirm.header' }),
          text: intl.formatMessage(
            { id: 'activeBonuses.confirm.text1' },
            {
              bonusName: header,
              bonusMoney: bonusMoneyFormatted,
              realBonusMoney: realBonusMoneyFormatted,
            },
          ),
          buttonText: intl.formatMessage({ id: 'activeBonuses.forfeit' }),
          onSubmit: () => forfeitHandler(userBonusId),
          loading: false,
          error: false,
        }),
      );
    }
    if (realBonusMoney === 0) {
      dispatch(
        actions.openModal('confirm', {
          header: intl.formatMessage({ id: 'activeBonuses.confirm.header' }),
          text: intl.formatMessage(
            { id: 'activeBonuses.confirm.text2' },
            { bonusName: header, bonusMoney: bonusMoneyFormatted },
          ),
          buttonText: intl.formatMessage({ id: 'activeBonuses.forfeit' }),
          onSubmit: () => forfeitHandler(userBonusId),
          loading: false,
          error: false,
        }),
      );
    }
  }

  return (
    <>
      {!claimBonusSuccess && !bonusCode && isBrowser && desktopGameLink && (
        <AbsoluteNavigate to={desktopGameLink} />
      )}
      {!claimBonusSuccess && !bonusCode && !isBrowser && mobileGameLink && (
        <AbsoluteNavigate to={mobileGameLink} />
      )}
      <StyledBonusCard className="bonus-card" image={image}>
        <div className="anchor" id={id} />
        <div className="bonus-content">
          <div className="text">
            <p className="bonusType">{bonusType}</p>
            <h3 className="bonusName">{name}</h3>
            <div className="bonusDesc">
              {!isEmpty(desc) && <p className="description">{desc}</p>}
              {!isEmpty(availableUntilText) && (
                <p className="bonusExpiration">
                  {intl.formatMessage({ id: 'bonusCard.availableUntil' })}: <br />
                  {availableUntilText}
                </p>
              )}
            </div>
            {!isEmpty(image) && (
              <img
                src={`${IMAGES_BASE}/cdn-cgi/image/fit=cover,width=180,height=auto,format=auto,dpr=2${image}.png`}
                alt={image}
              />
            )}
            {!isEmpty(claimBonusError) && (
              <PromptMessage type="error" message={claimBonusError?.error} />
            )}
          </div>
          <div className="bonus-buttons">
            {redeemTypeId === BonusRedeemType.BonusCode && (
              <>
                {!claimBonusSuccess && (
                  <Button
                    onClick={() =>
                      request(ApiService.creditBonus, { bonusCode }, () => {
                        dispatch(actions.getAvailableBonuses());
                        triggerGTag(GTagEvents.user_claimed_bonus, {
                          bonusCode,
                        });
                      })
                    }
                  >
                    {intl.formatMessage({ id: 'bonusCard.claimBonusButton' })}
                    {claimBonusLoading === bonusCode && (
                      <Spinner
                        height={20}
                        width={20}
                        borderwidth={2}
                        margin="0 0 0 8px"
                        color="white"
                      />
                    )}
                  </Button>
                )}
              </>
            )}
            {(redeemTypeId === BonusRedeemType.DepositBonusCode ||
              redeemTypeId === BonusRedeemType.Deposit) && (
              <Button onClick={() => openCashierHandler()}>
                {intl.formatMessage({ id: 'bonusCard.depositButton' })}
              </Button>
            )}
            {/* For active bonuses able to be forfeited */}
            {redeemTypeId === BonusRedeemType.ForfeitBonus && (
              <Button onClick={() => openModalHandler(name, userBonusId)}>
                {intl.formatMessage({ id: 'activeBonuses.forfeit' })}
              </Button>
            )}
            <Anchor
              className="terms-and-conditions-link"
              onClick={() =>
                dispatch(
                  actions.openModal('bonusTerms', {
                    text: bonusTerms ? parse(bonusTerms.toString()) : '',
                  }),
                )
              }
            >
              {intl.formatMessage({ id: 'bonusCard.termsLink' })}
            </Anchor>
            {!isEmpty(shortTerms) && <p className="mt-4 text-xs italic">{shortTerms}</p>}
          </div>
        </div>
      </StyledBonusCard>
    </>
  );
}

BonusCard.propTypes = {
  id: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  availableUntilText: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  desc: PropTypes.any.isRequired,
  bonusTerms: PropTypes.any.isRequired,
  bonusType: PropTypes.string.isRequired,
  redeemTypeId: PropTypes.number.isRequired,
  bonusCode: PropTypes.string.isRequired,
  desktopGameLink: PropTypes.string.isRequired,
  mobileGameLink: PropTypes.string.isRequired,
  userBonusId: PropTypes.number,
  shortTerms: PropTypes.any.isRequired,
};

BonusCard.defaultProps = {
  userBonusId: null,
};

export default BonusCard;
