import {
  all,
  AllEffect,
  ForkEffect,
  StrictEffect,
  takeEvery,
  put,
  select,
  call,
} from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { GetAmlVerificationStatusDto } from '@lucky7ventures/lucky-components';

import { selectAuthToken } from '../selectors/authSelectors';
import { axiosInstance as axios } from '../../shared/axiosInstance';
import {
  AmlActionTypes,
  getAmlVerificationStatusError,
  getAmlVerificationStatusSuccess,
} from '../actions/amlActions';
import { ApiResponse } from '../../models/api-response.model';

export function* getVerificationStatusSaga(): Generator<
  StrictEffect,
  void,
  AxiosResponse<ApiResponse<GetAmlVerificationStatusDto>>
> {
  const token = yield select(selectAuthToken);

  try {
    const response = yield call(axios.post, '/api/user/kyc/getamlverificationstatus', {
      token: token,
    });

    if (response.data.success) {
      yield put(getAmlVerificationStatusSuccess(response?.data?.data?.AmlVerificationStatus));
    } else {
      yield put(getAmlVerificationStatusError());
    }
  } catch (error) {
    yield put(getAmlVerificationStatusError());
  }
}

export function* watchAml(): Generator<AllEffect<ForkEffect<never>>, void> {
  yield all([takeEvery(AmlActionTypes.GetVerificationStatus, getVerificationStatusSaga)]);
}
