import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useBankIdProfile } from '@/hooks/useBankIdProfile';
import { useFastRegTag } from '@/hooks/useFastRegTag';
import { openModal } from '@/store/actions';
import { selectKycDocumentsLoaded, selectKycPhotoIdApproved } from '@/store/selectors/kycSelectors';
import { selectAutoLoginFinished } from '@/store/selectors/autoLoginSelectors';
import { selectAuthToken, selectIsAuthenticated } from '@/store/selectors/authSelectors';
import { selectLocale } from '@/store/selectors/commonSelectors';
import { selectTagsLoaded, selectUserMigrated } from '@/store/selectors/userSelectors';
import { selectAmlVerificationStatus } from '@/store/selectors/amlSelectors';
import { getGameLocale, isRegulatedLocale } from '@/shared/utility';
import { axiosInstance as axios } from '@/shared/axiosInstance';
import { envIsCanada, envIsMexico, envIsSweden } from '@/constants/constants';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';

export enum GamePlayable {
  Checking = 'CHECKING',
  Yes = 'YES',
  MissingKyc = 'MISSING_KYC',
  MissingBankIdProfile = 'MISSING_BANK_ID_PROFILE',
  HasFastRegTag = 'HAS_FAST_REG_TAG',
  AMLVerifyRequired = 'AML_VERIFY_REQUIRED',
}

// Instructions from the casino team:
// these game vendors need to use language 'es' instead of 'mx'
const mexicoESVendors = [
  'Pragmatic Play',
  'Evolution',
  'Games Global',
  'Playtech',
  'Microgaming',
  'Oryx Gaming',
];

const localesExcludedFromCompleteProfileRequirement = ['mx'];

export function useGameUrl(
  gameId: string,
  vendor?: string,
): {
  gameUrl: string | null;
  error: string;
  loading: boolean;
  playable: GamePlayable;
} {
  const dispatch = useDispatch();
  const { isCompletedBankIdProfile } = useBankIdProfile();
  const locale = useSelector(selectLocale);
  const photoIdApproved = useSelector(selectKycPhotoIdApproved);
  const autoLoginFinished = useSelector(selectAutoLoginFinished);
  const tagsLoaded = useSelector(selectTagsLoaded);
  const kycLoaded = useSelector(selectKycDocumentsLoaded);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const userMigrated = useSelector(selectUserMigrated);
  const token = useSelector(selectAuthToken);
  const userAmlVerificationStatus = useSelector(selectAmlVerificationStatus);
  const { hasFastRegTag } = useFastRegTag();
  const [gameUrl, setGameUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [playable, setPlayable] = useState(GamePlayable.Checking);

  const openContactInfoModal = () => {
    envIsSweden() ? dispatch(openModal('contactInfoSweden')) : dispatch(openModal('contactInfo'));
  };

  useEffect(() => {
    setPlayable(GamePlayable.Checking);
    if (!locale) {
      return;
    }

    if (!autoLoginFinished) {
      return;
    }

    if (envIsCanada()) {
      // We don't need to check for isAuthenticated here because an ontario user
      // is not allowed to play a game without being authenticated.

      // We want to wait for both kyc and tags to be loaded
      if (!kycLoaded || !tagsLoaded) {
        return;
      }

      // If the user doesn't have a photo id approved, we don't want to show the game
      // But if the user has the DC:Migrated KYC tag, we want to show the game anyway (the tag will be removed after 30 days)
      if (!photoIdApproved && !userMigrated) {
        setPlayable(GamePlayable.MissingKyc);
        return;
      }
    }

    if (isAuthenticated) {
      if (!tagsLoaded) {
        // we want to hold out on decisions until the tags are loaded
        return;
      }

      if (hasFastRegTag && !localesExcludedFromCompleteProfileRequirement.includes(locale)) {
        dispatch(openModal('completeProfile'));
        triggerGTag(GTagEvents.complete_profile_game_click);
        setPlayable(GamePlayable.HasFastRegTag);
        return;
      }

      if (!isCompletedBankIdProfile) {
        openContactInfoModal();
        setPlayable(GamePlayable.MissingBankIdProfile);
        return;
      }

      if (isRegulatedLocale(locale) && userAmlVerificationStatus !== 0) {
        setPlayable(GamePlayable.AMLVerifyRequired);
        return;
      }
    }

    setPlayable(GamePlayable.Yes);
    setGameUrl('');
    setError('');
    setLoading(true);

    const language =
      envIsMexico() && vendor && mexicoESVendors.includes(vendor) ? 'es' : getGameLocale(locale);

    const payload = isAuthenticated
      ? {
          language,
          playForFun: false,
          token,
        }
      : {
          language,
          playForFun: true,
        };

    axios
      .post(`/api/games/url/${gameId}`, payload)
      .then(response => {
        if (response.data.success) {
          setGameUrl(response.data.data);
          setLoading(false);
        } else {
          setError(response.data.error[0].Message);
          setLoading(false);
        }
      })
      .catch(() => {
        setError('error');
        setLoading(false);
      });
  }, [
    locale,
    autoLoginFinished,
    kycLoaded,
    isAuthenticated,
    photoIdApproved,
    isCompletedBankIdProfile,
    token,
    tagsLoaded,
    hasFastRegTag,
    userMigrated,
    userAmlVerificationStatus,
    gameId,
  ]);

  return { gameUrl, loading, error, playable };
}
