import React from 'react';

import { useIntl } from 'react-intl';

import { TranslatedRichText } from '../TranslatedRichText';
import CloseButton from '../UI/Buttons/CloseButton';
import { CDN_BASE, CDN_IMAGES_COMMON_PATH } from '../../constants/constants';

interface ILineChatModalProps {
  closeModal: () => void;
}

export function LineChatModal({ closeModal }: ILineChatModalProps): JSX.Element | null {
  const intl = useIntl();

  return (
    <div
      id="e2e_lineChatModal"
      className="prose relative flex w-full max-w-sm flex-col overflow-hidden rounded-lg bg-white p-8 text-left"
    >
      <CloseButton left onClick={closeModal} />
      <h1 className="mb-0.5 text-center text-2xl">
        {intl.formatMessage({ id: 'modal.lineChat.header' })}
      </h1>
      <TranslatedRichText tagName="p" id="modal.lineChat.text" />
      <img
        src={`${CDN_BASE}${CDN_IMAGES_COMMON_PATH}/line-app-luckydays.png`}
        alt="LINE Chat QR code"
        width={160}
        height={160}
        className="mt-0 w-1/2 self-center"
      />
      <a
        href="https://page.line.me/luckydays"
        target="_blank"
        rel="noopener noreferrer"
        className="mt-4 flex w-full items-center justify-center rounded-full bg-cyan-500 p-4 font-bold text-white shadow-md transition hover:bg-cyan-600 hover:shadow-lg"
      >
        {intl.formatMessage({ id: 'modal.lineChat.cta' })}
      </a>
      <button
        type="button"
        className="p-4 font-bold text-black/60 transition-colors hover:text-black"
        onClick={closeModal}
      >
        {intl.formatMessage({ id: 'misc.close' })}
      </button>
    </div>
  );
}
