import { put } from 'redux-saga/effects';

import { axiosInstance as axios } from '../../shared/axiosInstance';
import * as actions from '../actions';
import { mapUser } from '../../utils/mappers';
import { IS_SWEDEN } from '../../constants/constants';
import { isAxiosResponse } from '../../utils/type-guards';
import CookiesManager from '../../shared/cookies-manager';

export function* getUserSaga(action) {
  yield put(actions.getUserStart());

  try {
    const response = yield axios.post('/api/user/v2', { token: action.token });
    const user = mapUser(response.data);
    yield put(actions.getUserSuccess(user));
  } catch (error) {
    yield put(actions.getUserFail(error));
  }
}

export function* getUserWalletSaga() {
  const token = CookiesManager.getTokenCookie();

  if (token) {
    yield put(actions.getUserWalletStart());

    const payload = {
      token,
    };

    try {
      const response = yield axios.post('/api/user/wallet', payload);

      if (response.data.success) {
        yield put(actions.getUserWalletSuccess(response.data.data));
      } else {
        yield put(actions.getUserWalletFail());
      }
    } catch (error) {
      yield put(actions.getUserWalletFail());
    }
  } else {
    console.log('Failed to get wallet (no token)');
  }
}

export function* getUserDepositCountSaga() {
  const token = CookiesManager.getTokenCookie();

  if (token) {
    const payload = {
      token,
    };

    try {
      const response = yield axios.post('/api/user/getdepositcount', payload);
      if (response.data.success) {
        const depositCount = response.data.data;
        // Sweden only - we save the flag and not show the optin again
        if (IS_SWEDEN && depositCount > 0) {
          localStorage.setItem('PNP_WCB_OPTIN', 'true');
        }
        yield put(actions.getUserDepositCountSuccess(depositCount));
      } else {
        console.log(response.data.error);
      }
    } catch (error) {
      console.log(error);
    }
  } else {
    console.log('Failed to get user kyc status');
  }
}

export function* getUserTagsSaga(action) {
  try {
    const response = yield axios.post('/api/user/tags', { token: action.token });
    if (isAxiosResponse(response)) {
      const tags = response.data;
      yield put(actions.getUserTagsSuccess(tags));
    } else {
      yield put(actions.getUserTagsError());
    }
  } catch (error) {
    yield put(actions.getUserTagsError());
  }
}
