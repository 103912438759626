import React, { useState } from 'react';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { Form, Formik } from 'formik';
import { FormInput } from './FormInput';
import { useSimpleRequest } from '../hooks/useSimpleRequest';
import { AuthResponseDto } from '@lucky7ventures/lucky-components';
import PromptMessage from './UI/PromptMessage';
import { ApiError } from '../models/api-error.model';
import { ButtonWithLoader } from './Common/ButtonWithLoader';

interface FormValues {
  otp: string;
}

interface VerifyPayload {
  username: string;
  password: string;
  otp: string;
}

export function LoginOtpStep({
  username,
  password,
  onSuccessfulOtp,
}: {
  username: string;
  password: string;
  onSuccessfulOtp: (data: AuthResponseDto) => void;
}): JSX.Element {
  const intl = useIntl();
  const { loading, request } = useSimpleRequest<AuthResponseDto, VerifyPayload>();
  const [customError, setCustomError] = useState<string | null>(null);

  const validationSchema = Yup.object().shape({
    otp: Yup.string().required(intl.formatMessage({ id: 'inputs.otp.required' })),
  });

  const submitHandler = (values: FormValues) => {
    const errorCallback = (error: ApiError): void => {
      if (error.id === 429) {
        setCustomError(intl.formatMessage({ id: 'error.429' }));
      } else {
        setCustomError(intl.formatMessage({ id: 'error.support' }));
      }
    };

    request(
      '/api/auth/v2/verifyotpcode',
      { otp: values.otp, username, password },
      onSuccessfulOtp,
      errorCallback,
    );
  };

  return (
    <div id="e2e_loginOtp">
      <p className="mb-8 font-semibold">{intl.formatMessage({ id: 'login.otp.title' })}</p>
      {customError && <PromptMessage className="m-t[-24px]" type="error" message={customError} />}
      <Formik
        initialValues={{
          otp: '',
        }}
        validationSchema={validationSchema}
        onSubmit={values => submitHandler(values)}
      >
        {({ isValid }) => (
          <Form>
            <FormInput
              name="otp"
              type="text"
              label={intl.formatMessage({ id: 'inputs.otp' })}
              placeholder={intl.formatMessage({
                id: 'inputs.otp.placeholder',
              })}
            />
            <ButtonWithLoader
              disabled={!isValid || loading}
              loading={loading}
              message={intl.formatMessage({ id: 'login.login' })}
            />
          </Form>
        )}
      </Formik>
      <p className="absolute bottom-8 right-0 left-0 text-sm font-bold">
        {intl.formatMessage({ id: 'login.notReceivingCode' })}{' '}
        <button
          type="button"
          className="cursor-pointer text-cyan-500 underline transition hover:text-cyan-600"
          onClick={() => {}}
        >
          {intl.formatMessage({ id: 'login.contactSupport' })}
        </button>
      </p>
    </div>
  );
}
