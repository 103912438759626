import React, { FocusEvent, useEffect } from 'react';
import { useField } from 'formik';
import { appendDefaultContainerClassNames, FieldError, FieldLabel } from '../field';
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import { mobileInputExcludedCountries } from '@/constants/mobileInputConstants';
import { IS_SWEDEN } from '@/constants/constants';

interface PhoneNumberFieldProps extends PhoneInputProps {
  className?: string;
  name: string;
  label: string;
  labelClassName?: string;
  onValidationError?: (error: string) => void;
}

interface CountryValue {
  countryCode: string;
  dialCode: string;
  format: string;
  name: string;
}

const PhoneNumberField = ({
  className,
  disabled,
  label,
  labelClassName,
  name,
  onValidationError,
  ...props
}: PhoneNumberFieldProps) => {
  const [{ value, onChange: _onChange, ...field }, { error: errorObj, touched }, { setValue }] =
    useField(name);

  const error = errorObj as unknown as { wholeNumber: string; number: string };
  const errorMessage = error?.wholeNumber || error?.number;
  const isValidationError = touched && errorMessage;

  function onChangeHandler(value: string, country: CountryValue) {
    const updatedPhone = {
      number: country.dialCode ? value.slice(country.dialCode.length) : '',
      prefix: `+${country.dialCode}`,
      country: country.countryCode,
      wholeNumber: value,
    };

    setValue(updatedPhone);
  }

  useEffect(() => {
    if (isValidationError) {
      onValidationError?.(errorMessage!);
    }
  }, [isValidationError]);

  return (
    <div className={appendDefaultContainerClassNames(className, errorMessage, touched)}>
      <PhoneInput
        country={value?.country}
        value={value.prefix + value.number}
        onChange={onChangeHandler}
        excludeCountries={mobileInputExcludedCountries(IS_SWEDEN)}
        masks={{ se: '..........' }}
        disabled={disabled}
        inputClass="border-0 focus:outline-0 focus:border-none focus:ring-0 autofill:!bg-none peer font-light disabled:cursor-not-allowed disabled:text-gray-400"
        inputStyle={{
          border: '1px',
          background: 'transparent',
          width: '100%',
          borderRadius: 0,
          paddingBottom: '1rem',
          paddingLeft: '3.75rem',
          paddingRight: '0.25rem',
          paddingTop: '1.25rem',
          fontSize: '15px',
          lineHeight: 1,
        }}
        containerClass="pb-1.5 pt-3.5"
        dropdownStyle={{ background: 'white', color: 'black' }}
        dropdownClass="[&_li>.flag]:!top-2"
        buttonStyle={{
          background: 'transparent',
          border: 0,
          margin: 0,
          marginTop: 34,
          marginLeft: '0.4rem',
          padding: 0,
          height: 'fit-content',
        }}
        {...props}
        inputProps={{
          ...field,
          onBlur: (event: FocusEvent<HTMLInputElement>) => {
            props.onBlur?.(event, {});
            field.onBlur(event);
          },
        }}
      />
      <FieldLabel className={labelClassName} value={true}>
        {label}
      </FieldLabel>
      <FieldError show={Boolean(isValidationError)} error={errorMessage} />
    </div>
  );
};

export default PhoneNumberField;
