import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { isEmpty } from '../../shared/utility';

function ScrollToTop({ children }) {
  const location = useLocation();

  useEffect(() => {
    if (isEmpty(location.hash)) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return children;
}

export default ScrollToTop;
