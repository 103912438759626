import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from '../shared/utility';
import Spinner from './UI/Spinner';
import Currency from './UI/Currency';

function TotalBalance() {
  const [total, setTotal] = useState(null);
  const wallet = useSelector(state => state.user.wallet);

  useEffect(() => {
    if (!isEmpty(wallet)) {
      let total = wallet.Balance;

      if (!isEmpty(wallet.UserBonuses) && wallet.UserBonuses.length !== 0) {
        wallet.UserBonuses.forEach(bonus => {
          if (!isEmpty(bonus.Bonuses)) {
            bonus.Bonuses.forEach(bonus => {
              total += bonus.BonusMoney + bonus.RealBonusMoney;
            });
          }
        });
      }

      setTotal(total);
    }
  }, [wallet]);

  if (isEmpty(wallet) || isEmpty(total)) {
    return <Spinner height={16} width={16} borderwidth={3} margin="0" color="white" />;
  }
  return <Currency currency={wallet.Currency} amount={total} />;
}

export default TotalBalance;
