import React, { lazy, Suspense, useEffect, useState } from 'react';

import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { PrivateRoutes } from './PrivateRoutes';
import { NotFound } from './Pages/NotFound';
import ActiveBonuses from './Pages/ActiveBonuses';
import Logout from './Pages/Logout';
import Help from './Pages/Help/Help';
import { ResetPassword } from './Pages/ResetPassword';
import { Impersonate } from './Pages/Impersonate';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import TermsAndConditions from './Pages/TermsAndConditions';
import LuckyRewardsClub from './Pages/LuckyRewardsClub';
import { Migration } from './Pages/Migration';
import { ChristmasCalendar } from './Pages/ChristmasCalendar';
import { App } from '../App';
import { Internationalisation } from './Internationalisation';
import { LoginHistory } from './LoginHistory';
import { useXmas } from '@/hooks/useXmas';
import { setIpData, setLocale } from '@/store/actions/commonActions';
import { selectLocale } from '@/store/selectors/commonSelectors';
import { getLocaleByNavigator, localeFromPath } from '@/utils/utils';
import { mapLocaleToPathLocale } from '@/utils/mappers';
import { logException } from '@/utils/logger';
import { getLocaleByCountryCode } from '@/shared/utility';
import { buildLocales } from '@/constants/locales';
import { envIsCanada, envIsMexico, envIsSweden, IS_HALLOWEEN } from '@/constants/constants';
import { fetchIpData } from '@/utils/ip-data.utils';
import {
  AboutUs,
  Details,
  Game,
  GamesLobby,
  GamingHistory,
  History,
  Home,
  PaymentsHistory,
  Registration,
  ResponsibleGamingPage,
  ResponsibleGamingTab,
  Verify,
} from '../pages';
import SettingsHeader from '../pages/settings/SettingsHeader';
import Games from '../pages/games/Games';
import GamesByProvider from '@/pages/games/categories/GamesByProvider';
import { selectIsAuthenticated } from '@/store/selectors/authSelectors';
import {
  CategoryNewGames,
  CategorySlotsGames,
  CategoryTableGames,
  CategoryLastPlayedGames,
  CategoryLiveCasinoGames,
  CategoryRecommendedGames,
  CategoryAllGames,
  CategoryWinterGames,
  CategoryHalloweenGames,
} from '@/pages/games/categories';
import { isLiveCasinoEnabled } from '@/utils/logic.utils';
import CategoryJackpotsGames from '@/pages/games/categories/CategoryJackpotsGames';

const PaymentMethods = lazy(() => import('@/pages/payment-providers/PaymentMethods'));

export function AppRoutes(): JSX.Element | null {
  const locale = useSelector(selectLocale);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isXmasEnabled = useXmas();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const [finalPath, setFinalPath] = useState<string | undefined>();

  useEffect(() => {
    if (envIsMexico()) {
      dispatch(setLocale('mx'));
      return;
    }

    if (envIsSweden()) {
      dispatch(setLocale('sv'));
      return;
    }

    if (envIsCanada()) {
      const localeByNavigator = getLocaleByNavigator();
      const pathLocale = localeFromPath(location);

      if (pathLocale) {
        dispatch(setLocale('ca-fr'));
        return;
      }

      dispatch(setLocale(localeByNavigator));
      return;
    }

    const pathLocale = localeFromPath(location);

    if (pathLocale) {
      // Locale is included in the url set locale in redux storage
      dispatch(setLocale(pathLocale));
    } else {
      // Locale is not included in the url -> get country code by IP and set locale in redux storage
      fetchIpData()
        .then(data => {
          const countryCode = data.country;
          let ipDataLocale = getLocaleByCountryCode(countryCode);

          // Check if the user should be redirected to /ca or /ca-fr when comming from a canadian IP
          if (countryCode === 'CA') {
            ipDataLocale = getLocaleByNavigator();
          }
          dispatch(setIpData(data));
          dispatch(setLocale(ipDataLocale));
        })
        .catch(e => {
          logException(new Error('App Routes Ip Data Error'), { error: e });
          // Fallback to /row if IP API Fails
          dispatch(setLocale('row'));
        });
    }
  }, []);

  useEffect(() => {
    if (!locale) {
      return;
    }
    // Check if the pathname starts with at least one of the locales
    // if not append the locale to the pathname
    const pathname = location.pathname;
    const pathLocale = localeFromPath(location);

    if (envIsCanada()) {
      if (locale === 'ca') {
        setFinalPath(pathname);
        return;
      }
    }

    // if the locale already starts with one of the LOCALES set it as a final path
    // also for Sweden and Mexico build we want to keep the path as it is
    if (envIsSweden() || envIsMexico() || pathLocale) {
      setFinalPath(pathname);
      return;
    }

    // in other cases, if the route doesn't start with locale we need to do a redirect to the locale
    const path = pathname === '/' ? '' : pathname;
    const combinedPath = `${mapLocaleToPathLocale(locale)}${path}${location.search}`;
    setFinalPath(combinedPath);
    navigate(combinedPath);
  }, [locale]);

  if (!finalPath) {
    return null;
  }

  return (
    <Internationalisation locale={locale}>
      <Suspense fallback={<div />}>
        <Routes>
          {buildLocales().map(locale => (
            <Route key={locale} path={locale} element={<App />}>
              <Route index element={<Home />} />
              {envIsCanada() && <Route path="register" element={<Registration />} />}
              <Route path="games" element={<Games />}>
                <Route index element={<GamesLobby />} />
                <Route path="last-played" element={<CategoryLastPlayedGames />} />
                <Route path="recommended" element={<CategoryRecommendedGames />} />
                <Route path="table-games" element={<CategoryTableGames />} />
                <Route path="new-games" element={<CategoryNewGames />} />
                <Route path="slots" element={<CategorySlotsGames />} />
                <Route path="jackpots" element={<CategoryJackpotsGames />} />
                <Route path="all-games" element={<CategoryAllGames />} />
                {isXmasEnabled && <Route path="winter-games" element={<CategoryWinterGames />} />}
                {IS_HALLOWEEN && (
                  <Route path="halloween-games" element={<CategoryHalloweenGames />} />
                )}
                {isLiveCasinoEnabled(locale, isAuthenticated) && (
                  <Route path="live-casino" element={<CategoryLiveCasinoGames />} />
                )}
                {envIsMexico() && <Route path={`:provider`} element={<GamesByProvider />} />}
                <Route element={<NotFound />} />
              </Route>
              <Route path="games/:game/:gameId" element={<Game />} />
              <Route path="help" element={<Help />} />
              {envIsMexico() && <Route path="payment-methods" element={<PaymentMethods />} />}
              {envIsMexico() && <Route path="about-us" element={<AboutUs />} />}
              <Route path="reset-password/:recoveryCode" element={<ResetPassword />} />
              <Route path="impersonate/:token" element={<Impersonate />} />
              {envIsCanada() && <Route path="migration/:token" element={<Migration />} />}
              <Route path="responsible-gaming" element={<ResponsibleGamingPage />} />
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
              <Route path="terms-and-conditions" element={<TermsAndConditions />} />
              <Route path="lucky-rewards-club" element={<LuckyRewardsClub />} />
              {isXmasEnabled && (
                <Route path="lucky-days-of-december" element={<ChristmasCalendar />} />
              )}
              <Route path="cashier" element={<Home cashierDrawerOpen={true} />} />
              <Route path="promotions" element={null} />
              {/* Private Routes */}
              <Route element={<PrivateRoutes />}>
                <Route path="settings" element={<SettingsHeader />}>
                  <Route index element={<Details />} />
                  <Route path="verify" element={<Verify />} />
                  <Route path="responsible-gaming" element={<ResponsibleGamingTab />} />
                  <Route path="history" element={<History />}>
                    <Route index element={<GamingHistory />} />
                    <Route path="payments" element={<PaymentsHistory />} />
                    {(envIsSweden() || envIsCanada()) && (
                      <Route path="login" element={<LoginHistory />} />
                    )}
                  </Route>
                  <Route path="active-bonuses" element={<ActiveBonuses />} />
                </Route>
                <Route path="logout" element={<Logout />} />
              </Route>
              <Route path="*" element={<NotFound />} />
            </Route>
          ))}
          <Route path="*" element={<Navigate to={locale} />} />
        </Routes>
      </Suspense>
    </Internationalisation>
  );
}
