import { ErrorMessage, Field, FieldProps, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { LimitDuration, LimitType } from '@lucky7ventures/lucky-components';
import { BUILD_MODE, IS_SWEDEN } from '@/constants/constants';
import { selectUser } from '@/store/selectors/userSelectors';
import { selectActiveLossLimits, selectFutureLossLimits } from '@/store/selectors/limitsSelectors';
import { useSetUserLimits } from '@/hooks/useSetUserLimits';
import { selectLocale } from '@/store/selectors/commonSelectors';
import { formatCurrency } from '@/utils/currencyUtils';
import { openModal } from '@/store/actions';
import ActiveLimits from './ActiveLimits';
import { ResponsibleGamingOptions } from '@/components/ResponsibleGamingOptions';
import { RadioButton } from '@/components/RadioButton';
import ValidationMessage from '../../../../components/UI/ValidationMessage';

interface FormValues {
  amount: string;
}

const LOSS_LIMITS = IS_SWEDEN
  ? {
      min: 1,
      max: 999999,
    }
  : {
      min: 1,
      max: 100000,
    };

const LossLimit = () => {
  const initialValues: FormValues = { amount: '' };
  const intl = useIntl();
  const DEPOSIT_DURATIONS = [
    {
      duration: LimitDuration._24Hours,
      text: `24 ${intl.formatMessage({ id: 'misc.hours' })}`,
    },
    {
      duration: LimitDuration._1Week,
      text: `1 ${intl.formatMessage({ id: 'misc.week' })}`,
    },
    {
      duration: LimitDuration._1Month,
      text: `1 ${intl.formatMessage({ id: 'misc.month' })}`,
    },
  ];
  const formikRef = useRef<any>();
  const [duration, setDuration] = useState(DEPOSIT_DURATIONS[0]);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const activeLimits = useSelector(selectActiveLossLimits);
  const futureLimits = useSelector(selectFutureLossLimits);
  const { success, setUserLimits } = useSetUserLimits();
  const locale = useSelector(selectLocale);
  const lossLimitError = intl.formatMessage(
    { id: 'lossLimit.amount.error' },
    { min: LOSS_LIMITS.min, max: LOSS_LIMITS.max },
  );

  useEffect(() => {
    // Empty the form if the user changes duration or
    // if the request of changing the duration is successful
    if (success || duration) {
      formikRef.current.resetForm();
    }
  }, [success, duration]);

  const modalText = (
    isRaising: boolean,
    currency: string,
    amount: string,
    timeRange: string,
  ): string => {
    if (BUILD_MODE === 'normal') {
      return intl.formatMessage(
        { id: 'lossLimit.confirm.text' },
        { amount: formatCurrency(parseInt(amount), locale, currency), timeRange },
      );
    }

    if (isRaising) {
      return intl.formatMessage(
        { id: 'lossLimit.confirm.text.isRising' },
        { amount: formatCurrency(parseInt(amount), locale, currency), timeRange },
      );
    }

    return intl.formatMessage(
      { id: 'lossLimit.confirm.text.notRising' },
      { amount: formatCurrency(parseInt(amount), locale, currency), timeRange },
    );
  };

  function submitHandler(values: FormValues) {
    if (!user) {
      return;
    }

    // Check if the user is raising the limit or not
    const activeLimit = activeLimits.find(limit => limit.Duration === duration.duration);
    const isRaising = !!(activeLimit && activeLimit.Amount < parseInt(values.amount, 10));

    dispatch(
      openModal('confirm', {
        header: intl.formatMessage({ id: 'lossLimit.confirm.header' }),
        text: modalText(isRaising, user.Currency, values.amount, duration.text),
        buttonText: intl.formatMessage({ id: 'lossLimit.button' }),
        onSubmit: () =>
          setUserLimits(
            [
              {
                UserId: user.UserID,
                Duration: duration.duration,
                Type: LimitType.CasinoNetLoss,
                LimitAmount: parseInt(values.amount, 10),
              },
            ],
            true,
          ),
        loading: false,
        error: false,
      }),
    );
  }

  return (
    <div>
      <h2 className="mb-4">{intl.formatMessage({ id: 'lossLimit.header' })}</h2>
      <p>{intl.formatMessage({ id: 'lossLimit.text' })}</p>
      {activeLimits && activeLimits.length > 0 && user && user.Currency && (
        <ActiveLimits
          currency={user.Currency}
          limits={activeLimits}
          header={intl.formatMessage({ id: 'lossLimit.currentLimits' })}
          amountFormat="currency"
        />
      )}
      {futureLimits && futureLimits.length > 0 && user && user.Currency && (
        <ActiveLimits
          currency={user.Currency}
          limits={futureLimits}
          header={intl.formatMessage({ id: 'lossLimit.futureLimits' })}
          amountFormat="currency"
        />
      )}
      <div className="form">
        <ResponsibleGamingOptions options={DEPOSIT_DURATIONS.length}>
          {DEPOSIT_DURATIONS.map(depositDuration => (
            <RadioButton htmlFor={depositDuration.text} key={depositDuration.duration}>
              {depositDuration.text}
              <input
                type="radio"
                id={depositDuration.text}
                value={depositDuration.duration}
                checked={duration.duration === depositDuration.duration}
                onChange={() => setDuration(depositDuration)}
              />
              <span className="checkmark" />
            </RadioButton>
          ))}
        </ResponsibleGamingOptions>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            amount: Yup.number()
              .typeError(lossLimitError)
              .min(LOSS_LIMITS.min, lossLimitError)
              .max(LOSS_LIMITS.max, lossLimitError)
              .required(lossLimitError),
          })}
          onSubmit={values => submitHandler(values)}
        >
          {() => (
            <Form>
              <Field name="amount">
                {({ field }: FieldProps) => (
                  <label htmlFor="amount">
                    {intl.formatMessage({ id: 'limit.amount' })} ({user?.Currency})
                    <input type="text" id="amount" {...field} className="font-bold text-black" />
                    <ErrorMessage name="amount">
                      {msg => <ValidationMessage message={msg} />}
                    </ErrorMessage>
                  </label>
                )}
              </Field>
              <button
                type="submit"
                className="mx-2 mt-4 mb-4 rounded-full bg-cyan-500 py-4 px-6 font-bold leading-[22px] text-white shadow-md transition-all hover:bg-cyan-600 hover:shadow-lg"
              >
                {intl.formatMessage({ id: 'lossLimit.button' })}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default LossLimit;
