import React, { useEffect, useState } from 'react';

import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { PayNPlayModal } from '../../features/pay-n-play/PayNPlayModal';
import { BonusTermsModal } from './BonusTermsModal';
import { ConfirmModal } from './ConfirmModal';
import { ConsentsModal } from './ConsentsModal';
import FailedLoginModal from './FailedLoginModal';
import ForgotPasswordModal from './ForgotPasswordModal';
import RegistrationUnavailableModal from './RegistrationUnavailableModal';
import { SOWQModal } from './SOWQModal';
import TermsAndConditionsModal from './TermsAndConditionsModal';
import UserProfileCompletionModal from './UserProfileCompletionModal';
import { ChristmasModal } from './ChristmasModal';
import ChristmasBonusTermsModal from './ChristmasBonusTermsModal';
import { ContactInfoModal } from '../ContactInfoModal';
import { ContactInfoModalSweden } from '../ContactInfoModalSweden';
import { StatsAndLimitsModal } from './StatsAndLimitsModal';
import { PayNPlayIframeModal } from '../../features/pay-n-play/PayNPlayIframeModal';
import { UpdateStateModal } from './UpdateStateModal';
import { selectModalQueueOrdered } from '../../store/selectors/modalSelectors';
import { closeSpecificModal, setShowBalanceUpdate } from '../../store/actions/modal';
import { LoginModal } from './LoginModal';
import { MigratedUserConsentsModal } from './MigratedUserConsentsModal';
import { OntarioMigrationModal } from '../OntarioMigrationModal';
import { OntarioWelcomeModal } from '../OntarioWelcomeModal';
import { LocationSmartModal } from './LocationSmartModal';
import { FitToPlayModal } from './FitToPlayModal';
import { DepositBalanceUpdateModal } from './DepositBalanceUpdateModal';
import { FixedDepositLimitErrorModal } from './FixedDepositLimitErrorModal';
import { SmsMailReminderModal } from './SmsMailReminderModal';
import { IncompleteUserDataModal } from './IncompleteUserDataModal';
import { LineChatModal } from './LineChatModal';
import * as actions from '../../store/actions/index';
import * as animations from '../../styles/animations';
import { RequiredDepositLimitsModal } from '../RequiredDepositLimitsModal';
import CookiesModal from './CookiesModal';
import BonusTermsAndConditionsModal from './BonusTermsAndConditions/BonusTermsAndConditionsModal';
import BasicModal from './BasicModal';
import DetailsReverificationModal from './DetailsReverificationModal';
import { ExitIntent } from '../../features/modals';

// eslint-disable-next-line react/prop-types
function ReactModalAdapter({ className, ...props }) {
  return (
    <Modal
      portalClassName={className}
      className={`${className}-content`}
      overlayClassName={`${className}-overlay`}
      {...props}
    />
  );
}

const StyledModal = styled(ReactModalAdapter)`
  .ReactModal__Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.75);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    opacity: 0;
    animation-duration: 150ms;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
    animation-play-state: running;
    text-align: center;
    display: flex;
    justify-content: flex-start;
    padding: 1rem;
    z-index: 120;

    @media (min-width: ${props => props.theme.breakpoints.small}) {
      padding: 2rem;
    }

    &--after-open {
      animation-name: ${animations.fadeIn};
    }

    &--before-close {
      animation-name: ${animations.fadeOut};
    }
  }

  .ReactModal__Content {
    position: relative;
    display: flex;
    justify-content: center;
    margin: auto;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    animation-duration: 150ms;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: forwards;
    animation-play-state: running;
    overflow: hidden;
    padding-bottom: 2rem;

    &--after-open {
      animation-name: ${animations.scaleIn};
    }

    &--before-close {
      animation-name: ${animations.scaleOut};
    }
  }
`;

const MODALS = {
  login: LoginModal,
  forgotPassword: ForgotPasswordModal,
  consent: ConsentsModal,
  failedLogin: FailedLoginModal,
  registrationUnavailable: RegistrationUnavailableModal,
  bonusTerms: BonusTermsModal,
  termsAndConditions: TermsAndConditionsModal,
  confirm: ConfirmModal,
  sowq: SOWQModal,
  completeProfile: UserProfileCompletionModal,
  christmas: ChristmasModal,
  christmasBonusTerms: ChristmasBonusTermsModal,
  paynplay: PayNPlayModal,
  contactInfo: ContactInfoModal,
  contactInfoSweden: ContactInfoModalSweden,
  statsAndLimits: StatsAndLimitsModal,
  paynplayIframe: PayNPlayIframeModal,
  updateState: UpdateStateModal,
  migratedUserConsents: MigratedUserConsentsModal,
  ontarioMigration: OntarioMigrationModal,
  ontarioWelcome: OntarioWelcomeModal,
  locationSmart: LocationSmartModal,
  fitToPlay: FitToPlayModal,
  depositBalanceUpdate: DepositBalanceUpdateModal,
  fixedDepositLimitError: FixedDepositLimitErrorModal,
  smsMailReminder: SmsMailReminderModal,
  incompleteUserData: IncompleteUserDataModal,
  lineChat: LineChatModal,
  requiredDepositLimits: RequiredDepositLimitsModal,
  cookies: CookiesModal,
  bonusTermsAndConditions: BonusTermsAndConditionsModal,
  basicModal: BasicModal,
  detailsReverification: DetailsReverificationModal,
  exitIntent: ExitIntent,
};

const DELAY = 150;

function ModalContainer() {
  const [isOpen, setIsOpen] = useState(false);
  const [modalName, setModalName] = useState('');
  const [modalProps, setModalProps] = useState({});
  const modalQueue = useSelector(selectModalQueueOrdered);

  const dispatch = useDispatch();

  useEffect(() => {
    if (modalQueue.length === 0) {
      setIsOpen(false);
      if (modalName !== '') {
        setTimeout(() => {
          setModalName('');
        }, DELAY);
      }
      return;
    }

    setIsOpen(true);
    const firstModal = modalQueue[0];
    setModalName(firstModal.modalName);
    setModalProps(firstModal.modalProps);
  }, [modalQueue]);

  function closeModal() {
    switch (modalName) {
      case 'consent':
      case 'migratedUserConsents':
      case 'statsAndLimits':
      case 'ontarioWelcome': {
        dispatch(closeSpecificModal(modalName));
        return;
      }
      case 'depositBalanceUpdate': {
        dispatch(setShowBalanceUpdate(false));
        dispatch(actions.closeModal());
        return;
      }
      default:
        dispatch(actions.closeModal());
    }
  }

  const onRequestCloseHandler = () => {
    // for some modals we don't want to close the modal on backdrop click
    switch (modalName) {
      case 'paynplayIframe':
      case 'contactInfo':
      case 'ontarioMigration':
      case 'ontarioWelcome':
      case 'ontarioWelcomeKyc':
      case 'locationSmart':
      case 'incompleteUserData':
      case 'requiredDepositLimits':
      case 'cookies':
      case 'fitToPlay':
        return;
      default:
        return closeModal;
    }
  };

  if (!modalName) {
    return null;
  }

  const Modal = MODALS[modalName];

  if (!Modal) {
    return null;
  }

  return (
    <StyledModal
      isOpen={isOpen}
      onRequestClose={onRequestCloseHandler}
      ariaHideApp={false}
      closeTimeoutMS={DELAY}
    >
      <Modal closeModal={closeModal} {...modalProps} />
    </StyledModal>
  );
}

export default ModalContainer;
