import * as React from 'react';
import { Form, Formik } from 'formik';
import { useIntl } from 'react-intl';
import { Alert } from './Alert';
import { CheckBox } from './CheckBox';
import CloseButton from './UI/Buttons/CloseButton';
import * as Yup from 'yup';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthToken } from '../store/selectors/authSelectors';
import { COUNTRY_PHONE_PREFIX } from '../constants/phonePrefixes';
import { RootState } from '../models/root-state.model';
import { getUser } from '../store/actions';
import { FormInput } from './FormInput';
import { ContactInfoMobile } from './ContactInfoMobile';
import Spinner from './UI/Spinner';
import { getConsents } from '../store/actions/consentsActions';
import ApiService from '../shared/apiService';
import { useApiV2Request } from '../hooks/useApiV2Request';

export function ContactInfoModal({ closeModal }: { closeModal: () => void }): JSX.Element {
  const intl = useIntl();

  const [smsChecked, setSmsChecked] = useState(true);
  const [emailChecked, setEmailChecked] = useState(true);
  const token = useSelector(selectAuthToken);
  const { request, error, loading } = useApiV2Request();
  const user = useSelector((state: RootState) => state.user.user);
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(intl.formatMessage({ id: 'inputs.email.required' }))
      .email(intl.formatMessage({ id: 'inputs.email.invalid' })),
    mobile: Yup.object().shape({
      wholeNumber: Yup.string().required(intl.formatMessage({ id: 'input.error.required' })),
      number: Yup.string().min(5, intl.formatMessage({ id: 'completeProfile.mobile.error' })),
    }),
  });

  const initialValues = {
    email: '',
    mobile: {
      number: '',
      country: user.CountryCode,
      prefix: COUNTRY_PHONE_PREFIX[user.CountryCode],
      wholeNumber: COUNTRY_PHONE_PREFIX[user.CountryCode],
    },
  };

  const submitHandler = (values: any): void => {
    const contactInfoReq = {
      token,
      email: values.email,
      mobileNumber: values.mobile.number,
      mobilePrefix: values.mobile.prefix,
      smsChecked,
      emailChecked,
    };

    const handleOnSuccess = () => {
      dispatch(getUser(token));
      dispatch(getConsents());
      closeModal();
    };

    request(ApiService.updateContactInfo, contactInfoReq, handleOnSuccess);
  };

  return (
    <div
      id="e2e_contactInfo"
      className="relative w-full max-w-md rounded-lg bg-slate-50 px-8 pt-12 pb-8"
    >
      <CloseButton left onClick={closeModal} />
      <h1 className="mb-8 text-2xl">
        {intl.formatMessage({ id: 'updateContactInfo.modal.title' })}
      </h1>
      <div className="content">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={values => submitHandler(values)}
        >
          {({ isValid }) => (
            <Form>
              <FormInput
                name="email"
                type="email"
                label={intl.formatMessage({
                  id: 'inputs.email',
                })}
                placeholder={intl.formatMessage({
                  id: 'inputs.email.placeholder',
                })}
              />
              <ContactInfoMobile
                label={intl.formatMessage({ id: 'completeProfile.mobile.placeholder' })}
                placeholder="+358 12 345 67 89"
                name="mobile"
              />
              <div className="consent flex flex-col justify-between pt-6">
                <p className="mb-4 text-sm">
                  {intl.formatMessage({ id: 'completeProfile.optin.header' })}
                </p>
                <div className="checkboxes flex items-center justify-around">
                  <CheckBox
                    className="mx-2"
                    id="smsOptIn"
                    value="checked"
                    label={intl.formatMessage({ id: 'inputs.toggle.sms' })}
                    initialCheck={smsChecked}
                    handleChange={() => setSmsChecked(prevValue => !prevValue)}
                  />

                  <CheckBox
                    className="mx-2"
                    id="emailOptIn"
                    value="checked"
                    label={intl.formatMessage({ id: 'inputs.toggle.email' })}
                    initialCheck={emailChecked}
                    handleChange={() => setEmailChecked(prevValue => !prevValue)}
                  />
                </div>
              </div>
              <button
                type="submit"
                disabled={!isValid}
                className="my-4 block h-14 w-full cursor-pointer rounded-full bg-cyan-500 font-bold text-white shadow-md transition hover:bg-cyan-600 hover:shadow-lg disabled:cursor-not-allowed disabled:opacity-60 md:px-4"
              >
                {loading && <Spinner width={18} height={18} borderwidth={3} color="white" />}
                {!loading && <>{intl.formatMessage({ id: 'misc.update' })}</>}
              </button>
            </Form>
          )}
        </Formik>
        {error && <Alert text={error.error} />}
      </div>
    </div>
  );
}
