import React from 'react';
import { useIntl } from 'react-intl';

import { LimitDetails } from '@lucky7ventures/lucky-components';
import { getLimitDuration } from '../../../../utils/utils';
import Currency from '../../../../components/UI/Currency';

const ActiveLimits = ({
  currency,
  limits,
  header,
  amountFormat,
}: {
  currency: string;
  limits: LimitDetails[];
  header: string;
  amountFormat: 'currency' | 'minutes';
}): JSX.Element => {
  const intl = useIntl();

  return (
    <div className="my-3 py-1 text-sm">
      <div className="font-bold">{header}: </div>
      <div className="grid md:grid-cols-3">
        {limits.map((limit: LimitDetails) => (
          <span key={limit.Id}>
            {amountFormat === 'currency' ? (
              <>
                {getLimitDuration(limit.Duration, intl)}:{' '}
                <Currency currency={currency} amount={limit?.Amount} />
              </>
            ) : (
              <>
                {limit.Amount} {intl.formatMessage({ id: 'misc.minutes' })} /{' '}
                {getLimitDuration(limit.Duration, intl)}
              </>
            )}
          </span>
        ))}
      </div>
    </div>
  );
};

export default ActiveLimits;
