import { ExclamationCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import { selectHeaderMessage } from '../../../store/selectors/wordpressSelectors';

export function HeaderMessage(): JSX.Element | null {
  const headerMessage = useSelector(selectHeaderMessage);
  const [cookies, setCookie] = useCookies(['notification-read']);

  if (!headerMessage || cookies['notification-read']) {
    return null;
  }

  return (
    <div className="flex items-start justify-between space-x-4 bg-slate-900 px-2 py-1 text-white md:items-center md:px-8">
      <div className="flex items-start space-x-4 md:items-center">
        <ExclamationCircleIcon className="mt-1 ml-1 h-5 w-5 md:m-0" />
        <p className="mb-0 flex-1 text-sm">{headerMessage}</p>
      </div>
      <button
        type="button"
        onClick={() => setCookie('notification-read', true, { path: '/' })}
        className="rounded-md p-1 transition hover:bg-slate-800"
      >
        <XMarkIcon className="h-5 w-5" />
      </button>
    </div>
  );
}
