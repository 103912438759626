import React, { ReactNode } from 'react';
import classNames from 'classnames';

const BonusHighlight = ({ children, fullWidth }: { children: ReactNode; fullWidth?: boolean }) => (
  <div
    className={classNames(
      'flex flex-col self-center place-self-center justify-self-center max-w-[275px] flex-shrink-0 snap-start',
      {
        'w-[90%]': !fullWidth,
        'w-full': fullWidth,
      },
    )}
  >
    {children}
  </div>
);

export default BonusHighlight;
