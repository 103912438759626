import {
  all,
  AllEffect,
  ForkEffect,
  put,
  PutEffect,
  select,
  takeEvery,
  SelectEffect,
  call,
  StrictEffect,
} from 'redux-saga/effects';
import { axiosInstance as axios } from '../../shared/axiosInstance';
import { AxiosResponse } from 'axios';
import { GameFront } from '@lucky7ventures/lucky-components';
import {
  GamesActionsTypes,
  getGamesError,
  getGamesJackpotsSuccess,
  getGamesSuccess,
  getLastPlayedGamesSuccess,
} from '../actions/gamesActions';
import { selectAuthToken } from '../selectors/authSelectors';
import { ApiResponse } from '@/models/api-response.model';
import { checkDevice } from '@/shared/utility';
import { doNothing } from '@/utils/utils';
import bffApiService from '@/shared/bffApiService';
import { BffGamesJackpotsResponse, BffGamesResponse } from '@lucky7ventures/bff-types';

export function* getGamesSaga(): Generator<
  StrictEffect,
  void,
  AxiosResponse<ApiResponse<BffGamesResponse>>
> {
  try {
    const gamesResponse = yield call(bffApiService.getAllGames, {
      payload: { device: checkDevice() },
    });

    if (gamesResponse.data.data) {
      yield put(getGamesSuccess(gamesResponse.data.data));
    }
  } catch (error) {
    yield put(getGamesError());
  }
}

export function* getLastPlayedGamesSaga(): Generator<
  PutEffect | Promise<AxiosResponse> | SelectEffect,
  void,
  AxiosResponse<ApiResponse<{ Game: GameFront }[]>>
> {
  const token = yield select(selectAuthToken);

  try {
    const { data: responseData } = yield axios.post('/api/games/lastplayed', {
      token,
    });

    const { data, success } = responseData;

    if (success && data) {
      const lastPlayedGames = data.map(game => game.Game);
      yield put(getLastPlayedGamesSuccess(lastPlayedGames));
    }
  } catch {
    doNothing();
  }
}

export function* getGamesJackpotsSaga(): Generator<
  StrictEffect,
  void,
  AxiosResponse<ApiResponse<BffGamesJackpotsResponse>>
> {
  try {
    const response = yield call(bffApiService.getGamesJackpots, {
      payload: {
        lang: 'en',
        currencyCode: 'EUR',
        device: checkDevice(),
      },
    });

    if (response.data.data) {
      yield put(getGamesJackpotsSuccess(response.data.data));
    }
  } catch (error) {
    yield put(getGamesError());
  }
}

export function* watchGames(): Generator<AllEffect<ForkEffect<never>>, void> {
  yield all([
    takeEvery(GamesActionsTypes.Get, getGamesSaga),
    takeEvery(GamesActionsTypes.GetLastPlayedGames, getLastPlayedGamesSaga),
    takeEvery(GamesActionsTypes.GetGamesJackpots, getGamesJackpotsSaga),
  ]);
}
