import React from 'react';

const ResponsibleGamingStaticMx = () => (
  <div className="[&_a]:underline">
    <h1 className="mb-4 text-3xl">Juego Responsable</h1>
    <p>
      En luckydays.mx tenemos como objetivo ser un operador fiable, equitativo, seguro y
      transparente, mantenemos nuestro compromiso con el juego responsable, y reconocemos que el
      juego puede causar problemas a una minoría de jugadores. Con este motivo, ofrecemos a los
      jugadores una amplia gama de herramientas, avisos y medidas que les permitan jugar en nuestro
      casino de manera segura y controlable.
    </p>
    <h2 className="mt-16 mb-4 text-2xl">Herramientas.</h2>
    <p>
      Tenemos varias herramientas que pueden ayudarle a disfrutar de una experiencia sostenible y
      agradable, para que puedas continuar jugando en LuckyDays de manera responsable y respetando
      tus limitaciones. Puedes establecer límites a tus gastos y pérdidas, o bloquear el acceso a tu
      cuenta.
    </p>
    <h3 className="mt-8 mb-4 text-xl">Límites de depósito</h3>
    <p>
      Manten el control de tus gastos mediante límites en la cantidad de dinero que puedes agregar a
      tu cuenta, ya sea por día, por semana o por mes.
    </p>
    <p>
      Al ajustarse a un presupuesto, te aseguras de no gastar más de lo que puedes permitirte en
      apuestas. Puedes establecer o modificar tu límite de depósito en “Límites de juego”, dentro de
      la página “Juego responsable” en Ajustes.
    </p>
    <h3 className="mt-8 mb-4 text-xl">Límites de sesión</h3>
    <p>
      Manten el control de tus sesiones mediante límites en el tiempo que puedes pasar con la sesión
      iniciada y jugando en la cuenta, ya sea por día, por semana o por mes.
    </p>
    <p>
      Con un límite de sesión, te aseguras de controlar la cantidad de tiempo que pasas apostando.
      Puedes establecer o modificar tu límite de sesión en “Límites de juego”, dentro de la página
      “Juego responsable” en Ajustes.
    </p>
    <h3 className="mt-8 mb-4 text-xl">Tomar un descanso</h3>
    <p>
      Si crees que te resultaría útil tomarte un descanso de las apuestas, puedes pausar tu cuenta
      por distintos periodos de tiempo. Puedes elegir entre varias opciones: 24 horas, 1 semana, 1
      mes, 2 meses o 3 meses.
    </p>
    <p>
      Durante el descanso, no podrás acceder a tu cuenta ni jugar a ningún juego. Además, puedes
      confiar en que estas restricciones no se eliminarán hasta que se haya cumplido el plazo de
      tiempo seleccionado. Si necesitas un descanso más largo, quizá prefieras una autoexclusión.
      Puedes tomarte un descanso en “Cierre de la cuenta”, dentro de la página “Juego responsable”
      en Ajustes.
    </p>
    <h2 className="mt-16 mb-4 text-2xl">Protección de menores.</h2>
    <p>
      En el Sitio protegemos a los menores, es decir a todas las personas que no cuenten con al
      menos 18 años, razón por la cual llevamos estrictos procedimientos de seguridad y se reserva
      el derecho de solicitar documentos en cualquier momento para comprobar la identidad y edad de
      sus usuarios y así evitar que ningún menor tenga acceso al Sitio, bloquear a jugadores
      restringidos y confirmar la identidad de todos aquellos que reciban pagos. Si conoces a algún
      menor de 18 años que esté registrado con nosotros, ponte en contacto con nosotros
      inmediatamente. Te recomendamos que limites la cantidad de tiempo que los menores a tu cargo
      pasen en Internet e instales un programa de control parental.
    </p>
    <p>
      Además de lo anterior, garantizamos que toda la actividad publicitaria, promocional y de
      patrocinio no sea dirigida a los menores de 18 años.
    </p>
    <p>
      En caso de que comparta dispositivos con menores, como otra medida de protección, sugerimos
      las siguientes precauciones:
    </p>
    <ul>
      <li>Uso software de protección infantil para bloquear sitios de juegos de azar remotos.</li>
      <li>No desatienda la computadora cuando inicie sesión en nuestro sitio.</li>
      <li>No comparta detalles de la cuenta bancaria con menores.</li>
      <li>No habilite “Guardar contraseña” en la pantalla de inicio de sesión de nuestro sitio.</li>
      <li>Cree un perfil en su computadora exclusiva para el uso de menores.</li>
      <li>
        Utilice alguna aplicación de “Control Parental”, nosotros le recomendamos los siguientes:
        <ul>
          <li>
            <a href="https://netnanny.com" target="_blank" rel="noreferrer">
              www.netnanny.com
            </a>
          </li>
          <li>
            <a href="https://cybersitter.com" target="_blank" rel="noreferrer">
              www.cybersitter.com
            </a>
          </li>
        </ul>
      </li>
    </ul>
    <h2 className="mt-16 mb-4 text-2xl">Prevención y protección del jugador.</h2>
    <p>
      Cuando utilices nuestro Sitio, es fundamental que goces de un estado emocional y psicológico
      equilibrado que te permita pensar y reaccionar con claridad y de manera razonable. Si has
      consumido alcohol, drogas o cualquier otra sustancia que pueda mermar tus capacidades
      mentales, no deberías participar usar nuestro Sitio.
    </p>
    <p>
      También algunas presiones externas también pueden ser consideradas influencias perjudiciales
      porque modifican el fin lúdico del juego. Por ejemplo, si se juega para obtener un cierto
      estatus social o la integración en un grupo social. Estas presiones son innecesarias y pueden
      ser perjudiciales a corto, medio y/o largo plazo.
    </p>
    <p>
      El juego debe ser una elección personal cuyo único objetivo sea la diversión. En ningún caso,
      el juego debe ser una obligación o una compulsión como resultado de la influencia de otra
      persona o motivo. El tiempo o el dinero que inviertas en el juego no debe primar o interferir
      con tus compromisos familiares, profesionales o sociales; así como ni afectar a tu trabajo,
      estudios, familia o responsabilidades parentales o perjudicar tu salud o interferir en otras
      actividades que son cruciales para tu bienestar (deportes, una dieta sana, descanso). Debes
      saber definir tus prioridades y mantener un equilibrio adecuado entre un buen estado físico y
      emocional y en las relaciones con tus allegados.
    </p>
    <h2 className="mt-16 mb-4 text-2xl">Autoexclusión</h2>
    <p>
      Es necesario que controles el tiempo que pasa en nuestro. Si consideras que pasas tiempo en
      exceso en nuestro sitio, o bien, si te consideras dependiente al juego con apuestas. Ponte en
      contacto con nosotros para recibir asesoramiento y apoyo.
    </p>
    <p>
      Para ayudarte a evaluar tu comportamiento como jugador, puedes hacerte las siguientes
      preguntas:
    </p>
    <ul>
      <li>¿Dejaste de lado tus estudios o trabajo por el juego?</li>
      <li>¿Está afectando el juego a tus relaciones personales (familia, amigos)?</li>
      <li>
        ¿Financiar el juego se convirtió en una prioridad antes que financiar tus responsabilidades
        familiares?
      </li>
      <li>
        ¿Está afectando el juego a tu salud (insomnio, abandono, aislamiento, depresión, estrés,
        comportamientos extraños)?
      </li>
      <li>
        ¿Te sientes mal o te rechazas después de jugar o necesitas volver a jugar lo antes posible
        para poder ganar más dinero o recuperar las pérdidas?
      </li>
      <li>¿Has jugado para recuperarte de las deudas o para solventar algún déficit financiero?</li>
      <li>¿Jugaste por encima de los límites de tiempo o dinero establecidos por ti?</li>
      <li>¿Tomaste prestado o vendido algo para financiar tu actividad de juego?</li>
      <li>¿Mentiste a tus amigos y familia sobre tus hábitos de juego?</li>
      <li>¿Consideraste cometer algún acto ilegal para financiar el juego?</li>
    </ul>
    <p>
      Si respondiste "Sí" a alguna de estas preguntas, te recomendamos que te pongas en contacto con
      atención al cliente o con alguna de las organizaciones siguientes:
    </p>
    <ul>
      <li>
        <a
          href="https://www.gob.mx/salud/acciones-y-programas/centro-de-atencion-ciudadana-contra-las-adicciones#:~:text=Recibir%20asesor%C3%ADa%20especializada%20de%20manera,llamada%20telef%C3%B3nica%20en%20el%20018009112000"
          target="_blank"
          rel="noreferrer"
        >
          Ludopatía
        </a>
      </li>
      <li>
        <a href="https://jamexico.org/" target="_blank" rel="noreferrer">
          Jugadores Anónimos
        </a>
      </li>
      <li>
        <a href="https://rganm.org/for-families/" target="_blank" rel="noreferrer">
          https://rganm.org/for-families/
        </a>
      </li>
    </ul>
    <h2 className="mt-16 mb-4 text-2xl">Información Adicional.</h2>
    <p>
      Creemos que el juego siempre debe ser una actividad de ocio que pueda disfrutar. Recuerde
      estos sencillos consejos para asegurarse de que el juego no se convierte en un problema.
    </p>
    <ul>
      <li>Apostar debe ser un entretenimiento y no una forma de ganar dinero.</li>
      <li>No apueste tratando de recuperar las pérdidas.</li>
      <li>Sólo apueste el dinero que puede perder.</li>
      <li>Controle el tiempo que invierte jugando.</li>
      <li>
        Combine el juego con otras actividades. Si el juego es su única forma de entretenimiento,
        considere si aún está divirtiéndose.
      </li>
      <li>
        Tome descansos de jugar en línea de manera frecuente. El juego continuado puede hacerle
        perder el control sobre el tiempo, dinero invertido y sus consecuencias.
      </li>
      <li>No apueste bajo los efectos del alcohol, depresión o enojo.</li>
      <li>
        Revise de manera periódica cuánto dinero ha gastado en el juego. Puede ver su actividad en
        línea en el historial de su cuenta.
      </li>
    </ul>
    <p>
      Ofrecemos cobertura de chat 24/7 para asistirle con cualquier solicitud, si no puedes
      controlar tu juego o seguir las indicaciones previamente brindadas; o si sientes que el juego
      en línea puede estar causando dificultades en lugar de ser entretenido, tenemos diferentes
      alternativas para ayudarte a administrar tú tiempo de juego.
    </p>
  </div>
);

export default ResponsibleGamingStaticMx;
