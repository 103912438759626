import React from 'react';
import { LimitDetails } from '@lucky7ventures/lucky-components';
import { useIntl } from 'react-intl';
import { getLimitDuration } from '../../../../utils/utils';
import { useSelector } from 'react-redux';
import { selectLocale } from '../../../../store/selectors/commonSelectors';
import { selectUserCurrency } from '../../../../store/selectors/userSelectors';
import { formatTableValue } from './helpers';

const TABLE_HEADER = ['limit.period', 'limit.amount', 'limit.remaining'];

function LimitsTableCell({ text }: { text?: React.ReactNode }): JSX.Element {
  return <td className="border p-2 capitalize">{text}</td>;
}

const ActiveLimitsTable = ({ activeLimits }: { activeLimits: LimitDetails[] }): JSX.Element => {
  const intl = useIntl();
  const locale = useSelector(selectLocale);
  const currency = useSelector(selectUserCurrency);

  return (
    <table className="table w-full table-auto border-collapse border text-sm">
      <thead>
        <tr>
          {TABLE_HEADER.map(header => (
            <th className="border p-2" key={header}>
              {intl.formatMessage({ id: header })}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {activeLimits.map(limit => (
          <tr key={limit.Id}>
            <LimitsTableCell text={getLimitDuration(limit.Duration, intl)} />
            <LimitsTableCell
              text={formatTableValue(limit.Amount, limit.LimitType, locale, currency, intl)}
            />
            <LimitsTableCell
              text={formatTableValue(
                limit.RemainingAmount,
                limit.LimitType,
                locale,
                currency,
                intl,
              )}
            />
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ActiveLimitsTable;
