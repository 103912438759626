import { RootState } from '@/models/root-state.model';
import { createSelector } from 'reselect';
import { isValidEmail } from '@/utils/utils';
import { User } from '@/models/user.model';
import { getLocaleByCountryCode, isEmpty } from '@/shared/utility';
import { selectLocale } from '@/store/selectors/commonSelectors';
import { TagResponse } from '@lucky7ventures/lucky-components';
import { WalletModel } from '@/models/gig/wallet.model';
import { FluidUserData } from '@fluidpayments/types';
import { selectFluidKycStatus } from '@/store/selectors/kycSelectors';
import { envIsMexico } from '@/constants/constants';
import { selectLifetimeDepositsAmount } from '@/store/selectors/lastDepositSelectors';

export const selectUser = (state: RootState): User | null => state.user.user;
export const selectUserLoading = (state: RootState): boolean => state.user.userLoading;
export const selectUserTags = (state: RootState): TagResponse[] => state.user.tags;
export const selectIsFastReg = (state: RootState): TagResponse[] =>
  state.user.tags.some((tag: TagResponse) => tag.Name === 'Fastreg');
export const selectDepositCount = (state: RootState): number | null => state.user.depositCount;
export const selectWallet = (state: RootState): WalletModel => state.user.wallet;
export const selectTagsLoaded = (state: RootState): boolean => state.user.tagsLoaded;
export const selectWalletError = (state: RootState): boolean => state.user.walletError;
export const selectWalletLoading = (state: RootState): boolean => state.user.walletLoading;

export const selectUserId = createSelector(selectUser, user => user?.UserID);

export const selectUserFirstName = createSelector(selectUser, user => user?.FirstName);
export const selectUserAlphaCode3 = createSelector(selectUser, user => user?.AlphaCode3);
export const selectUserBalance = createSelector(selectWallet, (wallet): number | null => {
  if (!wallet) {
    return null;
  }

  if (!('Balance' in wallet)) {
    return null;
  }

  return wallet.Balance;
});

export const selectUserRealBalance = createSelector(selectWallet, (wallet): number | null => {
  // the real balance is a calculation of Balance + RealBonusMoney across all Bonuses
  if (!wallet) {
    return null;
  }

  if (!('Balance' in wallet)) {
    return null;
  }

  const totalRealBonus = wallet.UserBonuses.reduce((accTotal, userBonus) => {
    return (
      accTotal +
      userBonus.Bonuses.reduce((accTotalBonus, bonus) => accTotalBonus + bonus.RealBonusMoney, 0)
    );
  }, 0);

  return wallet.Balance + totalRealBonus;
});

export const selectUserTotalBalance = createSelector(selectWallet, (wallet): number | null => {
  // The total balance is the sum of real Balance + all the bonuses
  if (!wallet) {
    return null;
  }

  if (!('Balance' in wallet)) {
    return null;
  }

  const totalRealBonus = wallet.UserBonuses.reduce((accTotal, userBonus) => {
    return (
      accTotal +
      userBonus.Bonuses.reduce(
        (accTotalBonus, bonus) => accTotalBonus + bonus.RealBonusMoney + bonus.BonusMoney,
        0,
      )
    );
  }, 0);

  return wallet.Balance + totalRealBonus;
});

export const selectUserLocale = createSelector(selectUser, user => {
  if (isEmpty(user) || user === null) {
    return null;
  }

  const userLocale = getLocaleByCountryCode(user.CountryCode);

  // Check if Canadian French
  if (userLocale === 'ca' && user.LanguageID === 24) {
    return 'ca-fr';
  }

  return userLocale;
});

export const selectUserCurrency = createSelector(selectUser, (user): string => {
  if (!user) {
    return '';
  }
  return user.Currency;
});

export const selectUserEmail = createSelector(selectUser, (user): string => {
  if (!user) {
    return '';
  }

  if (!isValidEmail(user.Email)) {
    return '';
  }

  return user.Email;
});

export const selectUserHasValidContactInfo = createSelector(selectUser, (user): boolean => {
  if (!user) {
    return false;
  }

  return !!user.Mobile && isValidEmail(user.Email);
});

export const selectHardReloadPending = createSelector(
  selectLocale,
  selectUserLocale,
  selectUser,
  (locale, userLocale, user) => {
    if (isEmpty(user)) {
      return false;
    }

    return locale !== userLocale;
  },
);

export const selectUserIsGameTester = createSelector(selectUserTags, tags => {
  if (tags.length === 0) {
    return false;
  }

  return tags.some(tag => tag.Name === 'Game Tester');
});

export const selectUserMigrated = createSelector(selectUserTags, tags => {
  if (tags.length === 0) {
    return false;
  }

  return tags.some(tag => tag.Name === 'DC:Migrated KYC');
});

export const selectDisableLocationSmart = createSelector(selectUserTags, tags => {
  if (tags.length === 0) {
    return false;
  }

  return tags.some(tag => tag.Name === 'Disable LocationSmart');
});

export const selectUserIsXmasTester = createSelector(selectUserTags, tags => {
  if (tags.length === 0) {
    return false;
  }

  return tags.some(tag => tag.Name === 'Xmas Tester');
});

export const selectUserHasFixedDepositLimit = createSelector(selectUserTags, tags => {
  if (tags.length === 0) {
    return false;
  }

  return tags.some(tag => tag.Name === 'RG Fixed Limit');
});

export const selectUserIsFluidTester = createSelector(selectUserTags, tags => {
  if (tags.length === 0) {
    return false;
  }

  return tags.find(tag => tag.Name === 'EnableFluid');
});

export const selectFluidUserData = createSelector(
  selectFluidKycStatus,
  selectLifetimeDepositsAmount,
  (kycStatus, lifetimeDeposits): FluidUserData => {
    let userData: FluidUserData = {};

    if (envIsMexico() && kycStatus && lifetimeDeposits !== undefined) {
      userData.kycStatus = kycStatus;
      userData.lifetimeDeposit = lifetimeDeposits;
    }

    return userData;
  },
);
