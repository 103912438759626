import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Spinner from '../../components/UI/Spinner';
import { isEmpty } from '@/shared/utility';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';
import { GamesCategories } from '@/models/game-category.type';
import { GameCardVM } from '@/models/vm/game-card-vm.model';

const StyledGameListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-end;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 16px 16px;

  @media (min-width: ${props => props.theme.breakpoints.small}) {
    padding: 0 32px 32px;
    align-items: flex-end;
  }

  .text {
    max-width: 60%;

    h1 {
      font-size: 22px;
      margin-bottom: 0;

      @media (min-width: ${props => props.theme.breakpoints.small}) {
        font-size: 28px;
      }
    }

    p {
      font-weight: bold;
      opacity: 0.5;
      margin-bottom: 0;
      font-size: 15px;
      display: none;

      @media (min-width: ${props => props.theme.breakpoints.small}) {
        margin-top: 8px;
        font-size: 17px;
        display: inline-flex;
      }
    }
  }

  .count {
    font-size: 16px;
    font-weight: bold;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    line-height: 1.2;

    .lucky-spinner {
      margin-right: 4px;
    }

    @media (min-width: ${props => props.theme.breakpoints.small}) {
      font-size: 18px;
    }
  }

  .category-link {
    text-decoration: none;
    background: none;
    border: none;
    position: relative;
    display: inline-flex;
    font-size: 16px;
    font-weight: bold;
    transition: all 0.15s ease;
    cursor: pointer;
    color: black;
    border-radius: 50vh;
    line-height: 1.2;
    margin-bottom: 4px;
    opacity: 0.8;

    .count {
      opacity: 0.6;
      font-size: 16px;
      line-height: 1.2;
    }

    &:hover {
      opacity: 1;
    }

    @media (min-width: ${props => props.theme.breakpoints.small}) {
      font-size: 18px;

      .count {
        font-size: 18px;
      }
    }
  }
`;

type GameListHeaderProps = {
  header: string;
  subheader?: string;
  games: GameCardVM[];
  gamesLoading: boolean;
  count: number;
  link?: GamesCategories;
};

function GameListHeader({
  header,
  subheader,
  games,
  gamesLoading,
  count,
  link,
}: GameListHeaderProps): JSX.Element {
  const intl = useIntl();
  const categoryOpened = header;

  const onClickHandler = () => {
    triggerGTag(GTagEvents.open_category, {
      openedFrom: 'show-all',
      categoryName: categoryOpened,
    });
  };

  return (
    <StyledGameListHeader>
      <div className="text">
        <h1 className="font-bold">{header}</h1>
        {!isEmpty(subheader) && <p>{subheader}</p>}
      </div>
      {link ? (
        <Link to={link} onClick={onClickHandler} className="category-link">
          {intl.formatMessage({ id: 'games.showAll' })}&nbsp;
          {isEmpty(games) && gamesLoading ? (
            <Spinner dark height={14} width={14} borderwidth={3} />
          ) : (
            <span className="count">({count})</span>
          )}
        </Link>
      ) : (
        <span className="count">
          {isEmpty(games) && gamesLoading ? (
            <Spinner dark height={14} width={14} borderwidth={3} />
          ) : (
            count
          )}
          &nbsp;{intl.formatMessage({ id: 'header.games' })}
        </span>
      )}
    </StyledGameListHeader>
  );
}

export default GameListHeader;
