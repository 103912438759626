import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { ResponsibleGamingTab } from '../index';
import PageHeader from '../../../components/Layout/PageHeader';

const StyledResponsibleGamingPage = styled.div`
  min-height: 100vh;
  position: relative;
`;

function ResponsibleGamingPage() {
  const intl = useIntl();

  return (
    <StyledResponsibleGamingPage>
      <Helmet>
        <title>LuckyDays - {intl.formatMessage({ id: 'responsibleGaming.header' })}</title>
      </Helmet>
      <PageHeader>
        <h1>{intl.formatMessage({ id: 'responsibleGaming.header' })}</h1>
      </PageHeader>
      <section>
        <ResponsibleGamingTab />
      </section>
    </StyledResponsibleGamingPage>
  );
}

export default ResponsibleGamingPage;
