import React from 'react';

import { CDN_BASE, CDN_IMAGES_COMMON_PATH } from '../../../constants/constants';

export function LineChatIcon() {
  return (
    <img
      src={`${CDN_BASE}${CDN_IMAGES_COMMON_PATH}/line-app.png`}
      alt="LINE Chat icon"
      width={43}
      height={43}
    />
  );
}
