import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { envIsCanada } from '../constants/constants';
import { openModal } from '../store/actions';
import { selectIsAuthenticated } from '../store/selectors/authSelectors';
import { selectIsPPConsented, selectIsTCConsented } from '../store/selectors/consentsSelectors';
import { selectTagsLoaded, selectUserMigrated } from '../store/selectors/userSelectors';

export function useConsents() {
  const isMigratedUser = useSelector(selectUserMigrated);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const tagsLoaded = useSelector(selectTagsLoaded);
  const isPPConsented = useSelector(selectIsPPConsented);
  const isTCConsented = useSelector(selectIsTCConsented);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!tagsLoaded) {
      return;
    }

    // If build mode is canada and the user is migrated show the migrated consents modal
    if (envIsCanada() && isMigratedUser && (isPPConsented === false || isTCConsented === false)) {
      dispatch(openModal('migratedUserConsents'));
      return;
    }

    // Open the consent modal if the user have pending consents when logging in
    if (isAuthenticated && (isPPConsented === false || isTCConsented === false)) {
      dispatch(openModal('consent'));
    }
  }, [isAuthenticated, isMigratedUser, tagsLoaded, isPPConsented, isTCConsented]);
}
