import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';

import HelpDrawer from './HelpDrawer';
import { selectIsDrawerOpen } from '@/store/selectors/drawerSelectors';
import * as actions from '../../store/actions/index';
import Backdrop from '../../features/cashier/Backdrop';
import { MenuDrawer } from '@/features/drawers/menu';

const StyledDrawerContainer = styled.div`
  position: relative;
  z-index: 110;

  .drawer {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: calc(85%);
    max-width: 620px;
    background: ${props => props.theme.colors.offWhite};
    box-shadow: ${props => props.theme.shadows.largeBox};
    z-index: 2;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    &.cashier-drawer {
      width: 100%;
      @media (min-width: ${props => props.theme.breakpoints.medium}) {
        width: calc(85%);
      }
    }

    .pageHeader {
      padding: 64px 40px;
    }
  }

  &.drawer-wrapper-enter {
    .backdrop {
      opacity: 0;
    }

    .drawer {
      transform: translateX(100%);
    }
  }

  &.drawer-wrapper-enter-active {
    .backdrop {
      opacity: 1;
      transition: opacity 250ms;
    }

    .drawer {
      transform: translateX(0);
      transition: transform 250ms;
    }
  }

  &.drawer-wrapper-exit {
    .backdrop {
      opacity: 1;
    }

    .drawer {
      transform: translateX(0);
    }
  }

  &.drawer-wrapper-exit-active {
    .backdrop {
      opacity: 0;
      transition: opacity 250ms;
    }

    .drawer {
      transform: translateX(100%);
      transition: transform 250ms;
    }
  }
`;

const DRAWERS = {
  help: HelpDrawer,
  menu: MenuDrawer,
};

const DELAY = 250;

function DrawerContainer() {
  const [isOpen, setIsOpen] = useState(false);
  const drawerName = useSelector(state => state.drawer.drawerName);
  const drawerProps = useSelector(state => state.drawer.drawerProps);
  const drawerIsOpen = useSelector(selectIsDrawerOpen);
  const dispatch = useDispatch();

  useEffect(() => {
    setIsOpen(drawerIsOpen);
    if (drawerIsOpen) {
      document.body.classList.add('drawer-open');
    }
  }, [drawerIsOpen]);

  function closeDrawer() {
    setIsOpen(false);
    document.body.classList.remove('drawer-open');
    setTimeout(() => {
      dispatch(actions.closeDrawer());
    }, DELAY);
  }

  if (!drawerName) {
    return null;
  }

  const Drawer = DRAWERS[drawerName];

  return (
    <CSSTransition in={isOpen} appear timeout={DELAY} unmountOnExit classNames="drawer-wrapper">
      <StyledDrawerContainer>
        <Backdrop onBackdropClick={closeDrawer} />
        <Drawer {...drawerProps} closeDrawer={closeDrawer} />
      </StyledDrawerContainer>
    </CSSTransition>
  );
}

export default DrawerContainer;
