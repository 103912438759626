import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectLocale } from '../../../store/selectors/commonSelectors';
import { selectAuthToken } from '../../../store/selectors/authSelectors';
import { useSimpleRequest } from '../../../hooks/useSimpleRequest';
import { ApiResponse } from '../../../models/api-response.model';
import {
  PaginatedTable,
  paginatedTableTransactionsMapper,
  WalletTransactionsModel,
} from '@lucky7ventures/lucky-components';
import { getDateFilterOptions, mapDateFilterItems } from '../../../utils/dateFilter';
import Spinner from '../../../components/UI/Spinner';
import { DateFilter } from '../../../components/DateFilter';
import { StyledPaginatedTable } from '../../../components/StyledPaginatedTable';

const PaymentsHistory = (): JSX.Element | null => {
  const locale = useSelector(selectLocale);
  const token = useSelector(selectAuthToken);
  const intl = useIntl();
  const dateFilterOptions = getDateFilterOptions(locale);
  const dateFilterItems = mapDateFilterItems(dateFilterOptions);

  const noDataMsg = intl.formatMessage({
    id: 'history.noTransactions',
  });
  const loadingMsg = <Spinner width={20} height={20} dark />;
  const headerCells = ['history.type', 'history.amount', 'history.method', 'history.date'].map(id =>
    intl.formatMessage({ id }),
  );
  const { loading, data, request } = useSimpleRequest<
    ApiResponse<WalletTransactionsModel[]>,
    { token: string; from: string; to: string }
  >();

  const selectDateRange = (id: number) => {
    const { from, to } = dateFilterOptions[id].dateRange;
    request('/api/user/wallet/transactions', { token: token!, from, to });
  };

  // This effect will trigger the initial request for the Last 30 days of history
  useEffect(() => {
    const { from, to } = dateFilterOptions[1].dateRange;
    request('/api/user/wallet/transactions', { token: token!, from, to });
  }, [token]);

  if (!locale) {
    return null;
  }

  return (
    <>
      <DateFilter items={dateFilterItems} selectDateRange={selectDateRange} />
      <StyledPaginatedTable>
        <PaginatedTable<WalletTransactionsModel>
          noDataMsg={noDataMsg}
          dataRows={data?.data || []}
          headerCells={headerCells}
          isLoading={loading}
          loadingMsg={loadingMsg}
          rowsMapper={paginatedTableTransactionsMapper(locale, {
            transactionType: {
              deposit: intl.formatMessage({ id: 'history.deposit' }),
              withdrawal: intl.formatMessage({ id: 'history.withdrawal' }),
            },
          })}
        />
      </StyledPaginatedTable>
    </>
  );
};

export default PaymentsHistory;
