import { useState } from 'react';
import { axiosInstance as axios } from '../shared/axiosInstance';
import { closeModal } from '@/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useAbsoluteNavigate } from './useAbsoluteNavigate';
import { RootState } from '@/models/root-state.model';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';

export function useAddTimeout() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const token = useSelector((state: RootState) => state.auth.token);
  const absoluteNavigate = useAbsoluteNavigate();
  const dispatch = useDispatch();

  function addTimeout(data: { timeoutCategoryID?: number; endDate: string | null }) {
    axios
      .post('/api/responsiblegaming/timeout/addtimeout', {
        timeoutCategoryID: data.timeoutCategoryID,
        endDate: data.endDate,
        token,
      })
      .then(response => {
        setLoading(false);
        if (response.data.error) {
          setError(response.data.error[0].Message);
        }
        if (response.data.success) {
          triggerGTag(GTagEvents.user_took_a_timeout);
          absoluteNavigate('/logout');
          dispatch(closeModal());
        }
      })
      .catch(error => {
        setError(error.message);
        setLoading(false);
      });
  }
  return { addTimeout, loading, error };
}
