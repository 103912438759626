import { axiosInstance as axios } from '../../shared/axiosInstance';
import {
  all,
  AllEffect,
  call,
  CallEffect,
  ForkEffect,
  put,
  PutEffect,
  retry,
  select,
  takeEvery,
} from 'redux-saga/effects';
import {
  KycActionType,
  kycGetDocuments,
  kycGetDocumentsError,
  kycGetDocumentsSuccess,
  runVerifyFinished,
} from '@/store/actions/kycActions';
import { SagaIterator } from 'redux-saga';
import { selectAuthToken } from '@/store/selectors/authSelectors';
import { isAxiosResponse } from '@/utils/type-guards';
import { logException } from '@/utils/logger';
import bffApiService from '@/shared/bffApiService';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';
import { bffApiErrorHandler } from '@/shared/errorHandler';
import { AxiosError, AxiosResponse } from 'axios';
import { logout } from '../actions';
import { setShowUserIsPepOrHioLogout } from '@/store/actions/modal';
import { BffErrorCode, BffResponse, BffVerifyUserResponse } from '@lucky7ventures/bff-types';

export function* getKycSaga(): SagaIterator {
  const token = yield select(selectAuthToken);

  if (!token) {
    return;
  }

  try {
    // This will retry the KYC request after 3 seconds, and it will repeat it 3 times
    const response = yield retry(3, 3000, axios.post, '/api/user/v2/kyc', { token });

    if (!isAxiosResponse(response)) {
      yield put(kycGetDocumentsError());
      return;
    }

    yield put(kycGetDocumentsSuccess(response.data));
  } catch (error) {
    logException(new Error('KYC Retries Failed'), { error: error });
    yield put(kycGetDocumentsError());
  }
}

export function* addKycRequirementsSaga(): SagaIterator {
  const token = yield select(selectAuthToken);

  if (!token) {
    return;
  }

  try {
    yield call(axios.post, '/api/user/v2/addkycrequirements', { token });
    yield put(kycGetDocuments());
  } catch (error) {
    yield put(kycGetDocumentsError());
  }
}

export function* runVerifySaga(): Generator<
  CallEffect<AxiosResponse<BffResponse<BffVerifyUserResponse>>> | PutEffect,
  void,
  AxiosResponse<BffResponse<BffVerifyUserResponse>>
> {
  triggerGTag(GTagEvents.user_verify_start);
  try {
    const verifyRes = yield call(bffApiService.verifyUser);
    if (verifyRes.data.data && verifyRes.data.data.isApproved) {
      triggerGTag(GTagEvents.user_verify_approved);
    } else {
      triggerGTag(GTagEvents.user_verify_rejected);
    }
    // after verify is done, we need to fetch the kyc documents again
    yield put(kycGetDocuments());
    yield put(runVerifyFinished());
  } catch (error) {
    const bffError = bffApiErrorHandler(error as AxiosError);
    if (bffError.code === BffErrorCode.GIG_USER_VERIFY_PEPHIO) {
      // if the user is marked as PEP we want to log out the user and present the PEP modal
      triggerGTag(GTagEvents.user_verify_pep);
      yield put(logout());
      yield put(setShowUserIsPepOrHioLogout(true));
    } else {
      triggerGTag(GTagEvents.user_verify_error, { error: `bff-error-${bffError.code}` });
    }
    yield put(runVerifyFinished());
  }
}

export function* watchKyc(): Generator<AllEffect<ForkEffect<never>>, void> {
  yield all([takeEvery(KycActionType.GetDocuments, getKycSaga)]);
  yield all([takeEvery(KycActionType.AddKycRequirements, addKycRequirementsSaga)]);
  yield all([takeEvery(KycActionType.RunVerify, runVerifySaga)]);
}
