import React from 'react';
import { useSelector } from 'react-redux';
import { loginsToTableRowData } from '../utils/mappers';
import { DBTokenIP, PaginatedTable } from '@lucky7ventures/lucky-components';
import { useIntl } from 'react-intl';
import Spinner from './UI/Spinner';
import { StyledPaginatedTable } from './StyledPaginatedTable';
import {
  selectLoginHistory,
  selectLoginHistoryLoading,
} from '../store/selectors/loginHistorySelectors';

export function LoginHistory(): JSX.Element {
  const loginHistory = useSelector(selectLoginHistory);
  const loading = useSelector(selectLoginHistoryLoading);
  const intl = useIntl();
  const noDataMsg = intl.formatMessage({ id: 'loginHistory.noDataMsg' });
  const headerCells = [
    intl.formatMessage({ id: 'history.loggedIn' }),
    intl.formatMessage({ id: 'history.loggedOut' }),
  ];
  const loadingMsg = <Spinner width={20} height={20} dark />;
  return (
    <StyledPaginatedTable id="e2e_loginHistory">
      <PaginatedTable<DBTokenIP>
        noDataMsg={noDataMsg}
        dataRows={loginHistory}
        headerCells={headerCells}
        isLoading={loading}
        loadingMsg={loadingMsg}
        rowsMapper={loginsToTableRowData}
      />
    </StyledPaginatedTable>
  );
}
