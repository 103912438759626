import { useSelector } from 'react-redux';
import { selectUserId } from '../store/selectors/userSelectors';
import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { selectLocale } from '../store/selectors/commonSelectors';

export function useSentry() {
  const userId = useSelector(selectUserId);
  const locale = useSelector(selectLocale);

  useEffect(() => {
    if (userId) {
      Sentry.setUser({ id: `${userId}` });
    } else {
      Sentry.setUser(null);
    }
  }, [userId]);

  useEffect(() => {
    Sentry.setTag('locale', locale);
  }, [locale]);
}
