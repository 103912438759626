import {
  GamesActions,
  GamesActionsTypes,
  GetGamesSuccessAction,
  GetLastPlayedGamesSuccessAction,
} from '../actions/gamesActions';
import { defineState } from 'redux-localstore';
import {
  BffGamesCategory,
  BffGamesJackpotsResponse,
  BffGamesResponse,
} from '@lucky7ventures/bff-types';

export interface GamesStateModel {
  games: BffGamesResponse['games'];
  categories: Partial<Record<BffGamesCategory, number[]>>;
  providers: Record<string, { name: string; games: number[] }>;
  loading: boolean;
  error: boolean;
  success: boolean;
  lastPlayedIds: number[];
  jackpots: BffGamesJackpotsResponse;
}

const defaultState: GamesStateModel = {
  games: {},
  categories: {},
  providers: {},
  loading: false,
  error: false,
  success: false,
  lastPlayedIds: [],
  jackpots: {},
};

const initialState = defineState(defaultState)('games');

const getGames = (state: GamesStateModel): GamesStateModel => ({
  ...state,
  loading: true,
  error: false,
});

const getGamesSuccess = (
  state: GamesStateModel,
  action: GetGamesSuccessAction,
): GamesStateModel => ({
  ...state,
  games: action.games,
  providers: action.providers,
  categories: action.categories,
  loading: false,
  error: false,
  success: true,
});

const getGamesError = (state: GamesStateModel) => ({
  ...state,
  loading: false,
  error: true,
  success: false,
});

const getLastPlayedGamesSuccess = (
  state: GamesStateModel,
  action: GetLastPlayedGamesSuccessAction,
): GamesStateModel => ({
  ...state,
  lastPlayedIds: action.games.map(game => game.GameId),
});

const clearLastPlayedGames = (state: GamesStateModel): GamesStateModel => ({
  ...state,
  lastPlayedIds: [],
});

export const gamesReducer = (state = initialState, action: GamesActions): GamesStateModel => {
  switch (action.type) {
    case GamesActionsTypes.Get:
      return getGames(state);
    case GamesActionsTypes.GetSuccess:
      return getGamesSuccess(state, action);
    case GamesActionsTypes.GetError:
      return getGamesError(state);
    case GamesActionsTypes.GetLastPlayedGamesSuccess:
      return getLastPlayedGamesSuccess(state, action);
    case GamesActionsTypes.ClearLastPlayedGames:
      return clearLastPlayedGames(state);
    case GamesActionsTypes.GetGamesJackpotsSuccess:
      return {
        ...state,
        jackpots: action.data,
      };
    default:
      return state;
  }
};
