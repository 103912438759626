import React, { useEffect, useState } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthToken } from '../../../../store/selectors/authSelectors';
import { useAbsoluteNavigate } from '../../../../hooks/useAbsoluteNavigate';
import { useSimpleRequest } from '../../../../hooks/useSimpleRequest';
import { BUILD_MODE } from '../../../../constants/constants';
import { closeModal, openModal, updateModalProps } from '../../../../store/actions';
import { TranslatedRichText } from '../../../../components/TranslatedRichText';
import { ResponsibleGamingOptions } from '../../../../components/ResponsibleGamingOptions';
import { RadioButton } from '../../../../components/RadioButton';

const SelfExclusion = (): JSX.Element => {
  const [selectedSelfExclusion, setSelectedSelfExclusion] = useState<{
    id: string;
    text: string;
    gigId: number;
  } | null>();
  const intl = useIntl();
  const token = useSelector(selectAuthToken);
  const dispatch = useDispatch();
  const absoluteNavigate = useAbsoluteNavigate();

  const {
    loading: addSelfExclusionLoading,
    error: addSelfExclusionError,
    request: addSelfExclusionRequest,
  } = useSimpleRequest<any, { token: string; durationId: number }>();

  const availableSelfExclusionDurations = (): { id: string; text: string; gigId: number }[] => {
    const allOptions: Record<number, { id: string; text: string }> = {
      3: {
        id: '1week',
        text: `1 ${intl.formatMessage({ id: 'misc.week' })}`,
      },
      9: {
        id: '2weeks',
        text: `2 ${intl.formatMessage({ id: 'misc.weeks' })}`,
      },
      4: {
        id: '1month',
        text: `1 ${intl.formatMessage({ id: 'misc.month' })}`,
      },
      5: {
        id: '6months',
        text: `6 ${intl.formatMessage({ id: 'misc.months' })}`,
      },
      6: {
        id: '1year',
        text: `1 ${intl.formatMessage({ id: 'misc.year' })}`,
      },
      19: {
        id: '5years',
        text: `5 ${intl.formatMessage({ id: 'misc.years' })}`,
      },
    };

    const reduceOptions = (options: number[]) => {
      return options.reduce((a: { id: string; text: string; gigId: number }[], i) => {
        const option = allOptions[i];
        a.push({
          ...option,
          gigId: i,
        });
        return a;
      }, []);
    };

    if (BUILD_MODE === 'canada') {
      // for canada we want 6 months, 1 year, 5 years
      return reduceOptions([5, 6, 19]);
    }

    if (BUILD_MODE === 'sweden') {
      // for sweden we want 1 week, 2 weeks, 1 month, 6 months
      return reduceOptions([3, 9, 4, 5]);
    }

    return [];
  };

  useEffect(() => {
    dispatch(
      updateModalProps({
        error: addSelfExclusionError,
        loading: addSelfExclusionLoading,
      }),
    );
  }, [addSelfExclusionError, addSelfExclusionLoading]);

  useEffect(() => {
    setSelectedSelfExclusion(availableSelfExclusionDurations()[0]);
  }, []);

  function submitHandler() {
    if (!selectedSelfExclusion || !token) {
      return;
    }

    const selfExclusionFinished = () => {
      absoluteNavigate('/logout');
      dispatch(closeModal());
    };

    dispatch(
      openModal('confirm', {
        header: intl.formatMessage({ id: 'responsibleGaming.selfExclusion.confirm.header' }),
        text: `${intl.formatMessage({
          id: 'responsibleGaming.selfExclusion.confirm.text',
        })} ${selectedSelfExclusion.text}.`,
        buttonText: intl.formatMessage({ id: 'responsibleGaming.selfExclusion.submitButton' }),
        onSubmit: () =>
          addSelfExclusionRequest(
            '/api/responsiblegaming/selfexclusion/add',
            {
              token,
              durationId: selectedSelfExclusion.gigId,
            },
            selfExclusionFinished,
          ),
      }),
    );
  }

  return (
    <div>
      <h2 className="mb-6">{intl.formatMessage({ id: 'playerLimits.selfExclusion' })}</h2>
      <div className="space-y-4">
        <TranslatedRichText id="responsibleGaming.takeABreak.content" tagName="div" />
      </div>
      <div className="form">
        <ResponsibleGamingOptions options={availableSelfExclusionDurations().length}>
          {availableSelfExclusionDurations().map(duration => (
            <RadioButton htmlFor={duration.id} key={duration.id}>
              {duration.text}
              <input
                type="radio"
                id={duration.id}
                value={duration.id}
                checked={duration.id === selectedSelfExclusion?.id}
                onChange={() => setSelectedSelfExclusion(duration)}
              />
              <span className="checkmark" />
            </RadioButton>
          ))}
        </ResponsibleGamingOptions>
        <div className="button-wrapper">
          <button
            type="button"
            className="mx-2 mt-8 mb-4 rounded-full bg-cyan-500 py-4 px-6 font-bold leading-[22px] text-white shadow-md hover:bg-cyan-600 hover:shadow-lg"
            onClick={() => submitHandler()}
          >
            {intl.formatMessage({ id: 'responsibleGaming.selfExclusion.submitButton' })}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SelfExclusion;
