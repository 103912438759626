import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { isRegulatedLocale } from '../shared/utility';
import { openDrawer, openModal } from '../store/actions';
import { selectLocale } from '../store/selectors/commonSelectors';
import { selectIsAuthenticated } from '../store/selectors/authSelectors';
import { useRegistration } from './useRegistration';
import { isFastReg } from '../utils/utils';

export function useSearchParams(): void {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const modal = params.get('modal');
  const drawer = params.get('drawer');
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const locale = useSelector(selectLocale);
  const { openRegistration } = useRegistration();

  useEffect(() => {
    if (drawer === 'search') {
      dispatch(openDrawer('search'));
    }
  }, [drawer]);

  useEffect(() => {
    if (modal === 'login' && !isAuthenticated) {
      dispatch(openModal('login'));
    }

    if (modal === 'forgot' && !isAuthenticated) {
      dispatch(openModal('forgotPassword'));
    }

    if (locale && modal === 'register' && !isAuthenticated) {
      if (isFastReg(locale)) {
        openRegistration();
      } else if (isRegulatedLocale(locale)) {
        dispatch(openModal('paynplay'));
      }
    }

    if (locale && modal === 'logged-out' && !isAuthenticated) {
      if (isRegulatedLocale(locale)) {
        openModal('paynplay', { loggedOut: true });
      } else {
        openModal('login', { loggedOut: true });
      }
    }
  }, [locale, modal]);
}
