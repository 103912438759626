import { LimitType } from '@lucky7ventures/lucky-components';
import { formatCurrency } from '../../../../utils/currencyUtils';
import { IntlShape } from 'react-intl';

export function formatTableValue(
  amount: number,
  type: number,
  locale: string,
  currency: string,
  intl: IntlShape,
) {
  switch (type) {
    case LimitType.Deposit: {
      return formatCurrency(amount, locale, currency, 0);
    }
    case LimitType.Session: {
      if (amount % 60 === 0) {
        const number = amount / 60;
        return (
          number.toString() +
          ' ' +
          intl.formatMessage({ id: number > 1 ? 'misc.hours' : 'misc.hour' })
        );
      }
      return Math.ceil(amount).toString() + ' ' + intl.formatMessage({ id: 'misc.minutes' });
    }
    default:
      return amount.toString();
  }
}
