import React, { Suspense, lazy } from 'react';

import { useSelector } from 'react-redux';

import VerifyInProgress from '@/pages/settings/verify/VerifyInProgress';
import { LoadingSpinner } from '@/components/shared';
import { selectKycVerifyInProgress } from '@/store/selectors/kycSelectors';
import { envIsMexico } from '@/constants/constants';

const VerifySectionsMexico = lazy(() => import('./kyc-sections/VerifySectionsMexico'));
const VerifySections = lazy(() => import('./kyc-sections/VerifySections'));

function Verify(): JSX.Element {
  const verificationInProgress = useSelector(selectKycVerifyInProgress);

  // This should happen only for CANADA build while the automatic GBG verification is in progress
  if (verificationInProgress) {
    return <VerifyInProgress />;
  }

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <div className="mx-auto max-w-[720px] bg-white md:bg-transparent px-4 md:px-8 py-16">
        {envIsMexico() && <VerifySectionsMexico />}
        {!envIsMexico() && <VerifySections />}
      </div>
    </Suspense>
  );
}

export default Verify;
