import React from 'react';
import { Navigate } from 'react-router-dom';
import { useLocalizedTo } from '../hooks/useLocalizedTo';

export function AbsoluteNavigate({
  to,
  replace = false,
}: {
  to: string;
  replace?: boolean;
}): JSX.Element | null {
  const localizedTo = useLocalizedTo(to);

  if (!localizedTo) {
    return null;
  }

  return <Navigate to={localizedTo} replace={replace} />;
}
