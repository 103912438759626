import { all, AllEffect, call, ForkEffect, put, StrictEffect, takeEvery } from 'redux-saga/effects';
import * as actions from '../actions';
import { getUserTags, loginSuccess } from '../actions';
import { axiosInstance as axios } from '../../shared/axiosInstance';
import {
  AutoLoginActionType,
  autoLoginUpdate,
  RefreshUserAction,
} from '../actions/autoLoginActions';
import { AutoLoginStep } from '../../models/enum/auto-login-step.enum';
import { AxiosResponse } from 'axios';
import { ApiResponse } from '../../models/api-response.model';
import { Jackpot } from '@lucky7ventures/lucky-components';
import { isAxiosResponse } from '../../utils/type-guards';
import { getLastDeposit } from '../actions/lastDepositActions';
import { getLimits } from '../actions/limitsActions';
import { BUILD_MODE, IS_SWEDEN } from '../../constants/constants';
import { getLoginHistory } from '../actions/loginHistoryActions';
import { getUserPlayStatsAndLimits } from '../actions/userPlayStatsAndLimitsActions';
import { getConsents } from '../actions/consentsActions';
import { GTagEvents, triggerGTag } from '../../lib/gTagManager';
import CookiesManager from '../../shared/cookies-manager';

export function* refreshUserSaga(
  action: RefreshUserAction,
): Generator<StrictEffect, void, unknown> {
  const token = action.token;
  yield put(getLastDeposit(token));
  yield put(actions.getUser(token));
  yield put(actions.getAvailableBonuses());
  yield put(getConsents());
  yield put(actions.getUserBonuses());
  yield put(actions.getUserDepositCount());
  yield put(actions.getUserWallet());
  yield put(getLimits());
  yield put(getUserTags(token));
  if (IS_SWEDEN) {
    yield put(getLoginHistory(token));
    yield put(getUserPlayStatsAndLimits(token, 12));
  }
  if (BUILD_MODE === 'canada') {
    yield put(getLoginHistory(token));
  }
}

export function* autoLoginSaga(): Generator<
  StrictEffect,
  void,
  AxiosResponse<ApiResponse<Jackpot[]>> | number
> {
  const token = CookiesManager.getTokenCookie();

  if (!token) {
    yield put(autoLoginUpdate(AutoLoginStep.Skipped));
    return;
  }

  try {
    const response = yield call(axios.post, '/api/auth/isloggedin', { token });

    if (!isAxiosResponse(response)) {
      yield put(autoLoginUpdate(AutoLoginStep.Error));
      return;
    }

    if (!response.data.data) {
      yield put(autoLoginUpdate(AutoLoginStep.Error));
      return;
    }
    yield put(loginSuccess(token));
    yield put(autoLoginUpdate(AutoLoginStep.Success));
    triggerGTag(GTagEvents.login_auto);
  } catch (error) {
    yield put(autoLoginUpdate(AutoLoginStep.Error));
  }
}

// TODO: rename this to watchAuth once we finish the auth redux refactor to TS
export function* watchAutoLogin(): Generator<AllEffect<ForkEffect<never>>, void> {
  yield all([
    takeEvery(AutoLoginActionType.AutoLogin, autoLoginSaga),
    takeEvery(AutoLoginActionType.RefreshUser, refreshUserSaga),
  ]);
}
