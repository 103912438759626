import React, { Suspense } from 'react';
import { envIsCanada, envIsMexico } from '../../../constants/constants';
import ResponsibleGamingStaticMx from './static-content/ResponsibleGamingStaticMx';

const ResponsibleGamingStaticComponent = React.lazy(
  () => import('./static-content/ResponsibleGamingStatic'),
);
const ResponsibleGamingStaticOntarioComponent = React.lazy(
  () => import('./static-content/ResponsibleGamingStaticOntario'),
);
const ResponsibleGamingStaticOntarioFrenchComponent = React.lazy(
  () => import('./static-content/ResponsibleGamingStaticOntarioFrench'),
);

const ResponsibleGamingIntro = ({ locale }: { locale: string }): JSX.Element => {
  return (
    <div className="[&_ul]:p-0 [&_li]:relative [&_li]:mb-3 [&_li]:pl-8 [&_li]:before:absolute [&_li]:before:left-0 [&_li]:before:top-1.5 [&_li]:before:h-3 [&_li]:before:w-3 [&_li]:before:rounded-full [&_li]:before:bg-cyan-800 [&_a]:font-bold [&_a]:text-cyan-800">
      <Suspense fallback={<div></div>}>
        {envIsCanada() && locale !== 'ca-fr' && <ResponsibleGamingStaticOntarioComponent />}
        {envIsCanada() && locale === 'ca-fr' && <ResponsibleGamingStaticOntarioFrenchComponent />}
        {envIsMexico() && locale === 'mx' && <ResponsibleGamingStaticMx />}
        {!envIsCanada() && <ResponsibleGamingStaticComponent locale={locale} />}
      </Suspense>
    </div>
  );
};

export default ResponsibleGamingIntro;
