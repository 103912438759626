import 'normalize.css';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import ScrollToTop from './components/Common/ScrollToTop';
import { AppRoutes } from './components/AppRoutes';
import './polyfills';
import store from './store/store';
import theme from './styles/theme';
import './index.css';
import { DataLoader } from './components/DataLoader';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { initializeNetworkAnalytics } from './utils/network.utils';

if (!window.Cypress) {
  initializeNetworkAnalytics();
}

if (!window.Cypress && process.env.REACT_APP_SENTRY_ENV) {
  // Init Sentry.io
  Sentry.init({
    dsn: 'https://bed6a4e8c69f43b2853beb6fe6853b54@o1297139.ingest.sentry.io/6525097',
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_APP_SENTRY_ENV,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
    beforeSend(event, hint) {
      // filter out all errors by response status
      const error = hint.originalException;
      if (error && error.response && error.response.status) {
        const excludedStatuses = [400, 429];
        if (excludedStatuses.indexOf(error.response.status) !== -1) {
          return null;
        }
      }

      return event;
    },
    beforeBreadcrumb(breadcrumb) {
      if (breadcrumb.category === 'xhr') {
        const url = breadcrumb.data.url;
        // remove isloggedin from breadcrumbs
        if (url === '/api/auth/isloggedin') {
          return null;
        }
        // remove mixpanel
        if (url.contains('mixpanel.com')) {
          return null;
        }
        // remove google analytics
        if (url.contains('google-analytics.com')) {
          return null;
        }
        // remove snapchat trackers
        if (url.contains('snapchat.com')) {
          return null;
        }
        // remove doubleclick.net
        if (url.contains('doubleclick.net')) {
          return null;
        }
        // remove clarity.ms
        if (url.contains('clarity.ms')) {
          return null;
        }
      }

      return breadcrumb;
    },
  });
}

const app = (
  <CookiesProvider>
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop>
          <ThemeProvider theme={theme}>
            <HelmetProvider>
              <DataLoader>
                <AppRoutes />
              </DataLoader>
            </HelmetProvider>
          </ThemeProvider>
        </ScrollToTop>
      </BrowserRouter>
    </Provider>
  </CookiesProvider>
);

ReactDOM.render(app, document.getElementById('root'));
