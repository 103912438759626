import React, { useEffect, useRef } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { openDrawer } from '@/store/actions';
import { useAbsoluteNavigate } from '@/hooks/useAbsoluteNavigate';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';
import { Button } from '@/components/buttons/Button';

const RegistrationCustomerSupport = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const absoluteNavigate = useAbsoluteNavigate();
  const containerRef = useRef<HTMLDivElement | null>(null);

  const openHelp = () => {
    triggerGTag(GTagEvents.ont_reg_error_help_click);
    dispatch(openDrawer('help'));
  };

  const returnToHome = () => {
    triggerGTag(GTagEvents.ont_reg_error_home_click);
    absoluteNavigate('/');
  };

  useEffect(() => {
    if (containerRef && containerRef.current) {
      window.scrollTo({ top: containerRef.current.offsetTop - 84 });
    }
  }, []);

  return (
    <div ref={containerRef} className="p-8 text-white">
      <div>
        <FormattedMessage
          id="ontario.registration.error.account_blocked"
          values={{
            b: str => <span className="font-bold">{str}</span>,
            p: str => <p className="mt-4">{str}</p>,
          }}
        />
      </div>
      <div className="mt-8">
        <Button
          text={intl.formatMessage({ id: 'help.contactButton' })}
          type="button"
          btnType="primary"
          onClickHandler={openHelp}
        />
        <div className="mt-4">
          <Button
            text={'Return to homepage'}
            type="button"
            btnType="secondary"
            onClickHandler={returnToHome}
          />
        </div>
      </div>
    </div>
  );
};

export default RegistrationCustomerSupport;
